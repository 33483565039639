/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #3dad02;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3dad02;
  --secondary: #6c757d;
  --success: #3dad02;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --white: #fff;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #3dad02;
  text-decoration: none;
  background-color: transparent;
  transition: color 0.25s, background-color 0.25s;
}

a:hover {
  color: #226101;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col-25, .col-26, .col-27, .col-28, .col-29, .col-30, .col-31, .col-32, .col-33, .col-34, .col-35, .col-36, .col-37, .col-38, .col-39, .col-40, .col-41, .col-42, .col-43, .col-44, .col-45, .col-46, .col-47, .col-48, .col-49, .col-50, .col-51, .col-52, .col-53, .col-54, .col-55, .col-56, .col-57, .col-58, .col-59, .col-60, .col-61, .col-62, .col-63, .col-64, .col-65, .col-66, .col-67, .col-68, .col-69, .col-70, .col-71, .col-72, .col-73, .col-74, .col-75, .col-76, .col-77, .col-78, .col-79, .col-80, .col-81, .col-82, .col-83, .col-84, .col-85, .col-86, .col-87, .col-88, .col-89, .col-90, .col-91, .col-92, .col-93, .col-94, .col-95, .col-96, .col-97, .col-98, .col-99, .col-100, .col-101, .col-102, .col-103, .col-104, .col-105, .col-106, .col-107, .col-108, .col-109, .col-110, .col-111, .col-112, .col-113, .col-114, .col-115, .col-116, .col-117, .col-118, .col-119, .col-120, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm-25, .col-sm-26, .col-sm-27, .col-sm-28, .col-sm-29, .col-sm-30, .col-sm-31, .col-sm-32, .col-sm-33, .col-sm-34, .col-sm-35, .col-sm-36, .col-sm-37, .col-sm-38, .col-sm-39, .col-sm-40, .col-sm-41, .col-sm-42, .col-sm-43, .col-sm-44, .col-sm-45, .col-sm-46, .col-sm-47, .col-sm-48, .col-sm-49, .col-sm-50, .col-sm-51, .col-sm-52, .col-sm-53, .col-sm-54, .col-sm-55, .col-sm-56, .col-sm-57, .col-sm-58, .col-sm-59, .col-sm-60, .col-sm-61, .col-sm-62, .col-sm-63, .col-sm-64, .col-sm-65, .col-sm-66, .col-sm-67, .col-sm-68, .col-sm-69, .col-sm-70, .col-sm-71, .col-sm-72, .col-sm-73, .col-sm-74, .col-sm-75, .col-sm-76, .col-sm-77, .col-sm-78, .col-sm-79, .col-sm-80, .col-sm-81, .col-sm-82, .col-sm-83, .col-sm-84, .col-sm-85, .col-sm-86, .col-sm-87, .col-sm-88, .col-sm-89, .col-sm-90, .col-sm-91, .col-sm-92, .col-sm-93, .col-sm-94, .col-sm-95, .col-sm-96, .col-sm-97, .col-sm-98, .col-sm-99, .col-sm-100, .col-sm-101, .col-sm-102, .col-sm-103, .col-sm-104, .col-sm-105, .col-sm-106, .col-sm-107, .col-sm-108, .col-sm-109, .col-sm-110, .col-sm-111, .col-sm-112, .col-sm-113, .col-sm-114, .col-sm-115, .col-sm-116, .col-sm-117, .col-sm-118, .col-sm-119, .col-sm-120, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md-25, .col-md-26, .col-md-27, .col-md-28, .col-md-29, .col-md-30, .col-md-31, .col-md-32, .col-md-33, .col-md-34, .col-md-35, .col-md-36, .col-md-37, .col-md-38, .col-md-39, .col-md-40, .col-md-41, .col-md-42, .col-md-43, .col-md-44, .col-md-45, .col-md-46, .col-md-47, .col-md-48, .col-md-49, .col-md-50, .col-md-51, .col-md-52, .col-md-53, .col-md-54, .col-md-55, .col-md-56, .col-md-57, .col-md-58, .col-md-59, .col-md-60, .col-md-61, .col-md-62, .col-md-63, .col-md-64, .col-md-65, .col-md-66, .col-md-67, .col-md-68, .col-md-69, .col-md-70, .col-md-71, .col-md-72, .col-md-73, .col-md-74, .col-md-75, .col-md-76, .col-md-77, .col-md-78, .col-md-79, .col-md-80, .col-md-81, .col-md-82, .col-md-83, .col-md-84, .col-md-85, .col-md-86, .col-md-87, .col-md-88, .col-md-89, .col-md-90, .col-md-91, .col-md-92, .col-md-93, .col-md-94, .col-md-95, .col-md-96, .col-md-97, .col-md-98, .col-md-99, .col-md-100, .col-md-101, .col-md-102, .col-md-103, .col-md-104, .col-md-105, .col-md-106, .col-md-107, .col-md-108, .col-md-109, .col-md-110, .col-md-111, .col-md-112, .col-md-113, .col-md-114, .col-md-115, .col-md-116, .col-md-117, .col-md-118, .col-md-119, .col-md-120, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg-25, .col-lg-26, .col-lg-27, .col-lg-28, .col-lg-29, .col-lg-30, .col-lg-31, .col-lg-32, .col-lg-33, .col-lg-34, .col-lg-35, .col-lg-36, .col-lg-37, .col-lg-38, .col-lg-39, .col-lg-40, .col-lg-41, .col-lg-42, .col-lg-43, .col-lg-44, .col-lg-45, .col-lg-46, .col-lg-47, .col-lg-48, .col-lg-49, .col-lg-50, .col-lg-51, .col-lg-52, .col-lg-53, .col-lg-54, .col-lg-55, .col-lg-56, .col-lg-57, .col-lg-58, .col-lg-59, .col-lg-60, .col-lg-61, .col-lg-62, .col-lg-63, .col-lg-64, .col-lg-65, .col-lg-66, .col-lg-67, .col-lg-68, .col-lg-69, .col-lg-70, .col-lg-71, .col-lg-72, .col-lg-73, .col-lg-74, .col-lg-75, .col-lg-76, .col-lg-77, .col-lg-78, .col-lg-79, .col-lg-80, .col-lg-81, .col-lg-82, .col-lg-83, .col-lg-84, .col-lg-85, .col-lg-86, .col-lg-87, .col-lg-88, .col-lg-89, .col-lg-90, .col-lg-91, .col-lg-92, .col-lg-93, .col-lg-94, .col-lg-95, .col-lg-96, .col-lg-97, .col-lg-98, .col-lg-99, .col-lg-100, .col-lg-101, .col-lg-102, .col-lg-103, .col-lg-104, .col-lg-105, .col-lg-106, .col-lg-107, .col-lg-108, .col-lg-109, .col-lg-110, .col-lg-111, .col-lg-112, .col-lg-113, .col-lg-114, .col-lg-115, .col-lg-116, .col-lg-117, .col-lg-118, .col-lg-119, .col-lg-120, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl-25, .col-xl-26, .col-xl-27, .col-xl-28, .col-xl-29, .col-xl-30, .col-xl-31, .col-xl-32, .col-xl-33, .col-xl-34, .col-xl-35, .col-xl-36, .col-xl-37, .col-xl-38, .col-xl-39, .col-xl-40, .col-xl-41, .col-xl-42, .col-xl-43, .col-xl-44, .col-xl-45, .col-xl-46, .col-xl-47, .col-xl-48, .col-xl-49, .col-xl-50, .col-xl-51, .col-xl-52, .col-xl-53, .col-xl-54, .col-xl-55, .col-xl-56, .col-xl-57, .col-xl-58, .col-xl-59, .col-xl-60, .col-xl-61, .col-xl-62, .col-xl-63, .col-xl-64, .col-xl-65, .col-xl-66, .col-xl-67, .col-xl-68, .col-xl-69, .col-xl-70, .col-xl-71, .col-xl-72, .col-xl-73, .col-xl-74, .col-xl-75, .col-xl-76, .col-xl-77, .col-xl-78, .col-xl-79, .col-xl-80, .col-xl-81, .col-xl-82, .col-xl-83, .col-xl-84, .col-xl-85, .col-xl-86, .col-xl-87, .col-xl-88, .col-xl-89, .col-xl-90, .col-xl-91, .col-xl-92, .col-xl-93, .col-xl-94, .col-xl-95, .col-xl-96, .col-xl-97, .col-xl-98, .col-xl-99, .col-xl-100, .col-xl-101, .col-xl-102, .col-xl-103, .col-xl-104, .col-xl-105, .col-xl-106, .col-xl-107, .col-xl-108, .col-xl-109, .col-xl-110, .col-xl-111, .col-xl-112, .col-xl-113, .col-xl-114, .col-xl-115, .col-xl-116, .col-xl-117, .col-xl-118, .col-xl-119, .col-xl-120, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl-25, .col-xxl-26, .col-xxl-27, .col-xxl-28, .col-xxl-29, .col-xxl-30, .col-xxl-31, .col-xxl-32, .col-xxl-33, .col-xxl-34, .col-xxl-35, .col-xxl-36, .col-xxl-37, .col-xxl-38, .col-xxl-39, .col-xxl-40, .col-xxl-41, .col-xxl-42, .col-xxl-43, .col-xxl-44, .col-xxl-45, .col-xxl-46, .col-xxl-47, .col-xxl-48, .col-xxl-49, .col-xxl-50, .col-xxl-51, .col-xxl-52, .col-xxl-53, .col-xxl-54, .col-xxl-55, .col-xxl-56, .col-xxl-57, .col-xxl-58, .col-xxl-59, .col-xxl-60, .col-xxl-61, .col-xxl-62, .col-xxl-63, .col-xxl-64, .col-xxl-65, .col-xxl-66, .col-xxl-67, .col-xxl-68, .col-xxl-69, .col-xxl-70, .col-xxl-71, .col-xxl-72, .col-xxl-73, .col-xxl-74, .col-xxl-75, .col-xxl-76, .col-xxl-77, .col-xxl-78, .col-xxl-79, .col-xxl-80, .col-xxl-81, .col-xxl-82, .col-xxl-83, .col-xxl-84, .col-xxl-85, .col-xxl-86, .col-xxl-87, .col-xxl-88, .col-xxl-89, .col-xxl-90, .col-xxl-91, .col-xxl-92, .col-xxl-93, .col-xxl-94, .col-xxl-95, .col-xxl-96, .col-xxl-97, .col-xxl-98, .col-xxl-99, .col-xxl-100, .col-xxl-101, .col-xxl-102, .col-xxl-103, .col-xxl-104, .col-xxl-105, .col-xxl-106, .col-xxl-107, .col-xxl-108, .col-xxl-109, .col-xxl-110, .col-xxl-111, .col-xxl-112, .col-xxl-113, .col-xxl-114, .col-xxl-115, .col-xxl-116, .col-xxl-117, .col-xxl-118, .col-xxl-119, .col-xxl-120, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.row-cols-7 > * {
  flex: 0 0 14.285714%;
  max-width: 14.285714%;
}

.row-cols-8 > * {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.row-cols-9 > * {
  flex: 0 0 11.111111%;
  max-width: 11.111111%;
}

.row-cols-10 > * {
  flex: 0 0 10%;
  max-width: 10%;
}

.row-cols-11 > * {
  flex: 0 0 9.090909%;
  max-width: 9.090909%;
}

.row-cols-12 > * {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 0.833333%;
  max-width: 0.833333%;
}

.col-2 {
  flex: 0 0 1.666667%;
  max-width: 1.666667%;
}

.col-3 {
  flex: 0 0 2.5%;
  max-width: 2.5%;
}

.col-4 {
  flex: 0 0 3.333333%;
  max-width: 3.333333%;
}

.col-5 {
  flex: 0 0 4.166667%;
  max-width: 4.166667%;
}

.col-6 {
  flex: 0 0 5%;
  max-width: 5%;
}

.col-7 {
  flex: 0 0 5.833333%;
  max-width: 5.833333%;
}

.col-8 {
  flex: 0 0 6.666667%;
  max-width: 6.666667%;
}

.col-9 {
  flex: 0 0 7.5%;
  max-width: 7.5%;
}

.col-10 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-11 {
  flex: 0 0 9.166667%;
  max-width: 9.166667%;
}

.col-12 {
  flex: 0 0 10%;
  max-width: 10%;
}

.col-13 {
  flex: 0 0 10.833333%;
  max-width: 10.833333%;
}

.col-14 {
  flex: 0 0 11.666667%;
  max-width: 11.666667%;
}

.col-15 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-16 {
  flex: 0 0 13.333333%;
  max-width: 13.333333%;
}

.col-17 {
  flex: 0 0 14.166667%;
  max-width: 14.166667%;
}

.col-18 {
  flex: 0 0 15%;
  max-width: 15%;
}

.col-19 {
  flex: 0 0 15.833333%;
  max-width: 15.833333%;
}

.col-20 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-21 {
  flex: 0 0 17.5%;
  max-width: 17.5%;
}

.col-22 {
  flex: 0 0 18.333333%;
  max-width: 18.333333%;
}

.col-23 {
  flex: 0 0 19.166667%;
  max-width: 19.166667%;
}

.col-24 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-25 {
  flex: 0 0 20.833333%;
  max-width: 20.833333%;
}

.col-26 {
  flex: 0 0 21.666667%;
  max-width: 21.666667%;
}

.col-27 {
  flex: 0 0 22.5%;
  max-width: 22.5%;
}

.col-28 {
  flex: 0 0 23.333333%;
  max-width: 23.333333%;
}

.col-29 {
  flex: 0 0 24.166667%;
  max-width: 24.166667%;
}

.col-30 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-31 {
  flex: 0 0 25.833333%;
  max-width: 25.833333%;
}

.col-32 {
  flex: 0 0 26.666667%;
  max-width: 26.666667%;
}

.col-33 {
  flex: 0 0 27.5%;
  max-width: 27.5%;
}

.col-34 {
  flex: 0 0 28.333333%;
  max-width: 28.333333%;
}

.col-35 {
  flex: 0 0 29.166667%;
  max-width: 29.166667%;
}

.col-36 {
  flex: 0 0 30%;
  max-width: 30%;
}

.col-37 {
  flex: 0 0 30.833333%;
  max-width: 30.833333%;
}

.col-38 {
  flex: 0 0 31.666667%;
  max-width: 31.666667%;
}

.col-39 {
  flex: 0 0 32.5%;
  max-width: 32.5%;
}

.col-40 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-41 {
  flex: 0 0 34.166667%;
  max-width: 34.166667%;
}

.col-42 {
  flex: 0 0 35%;
  max-width: 35%;
}

.col-43 {
  flex: 0 0 35.833333%;
  max-width: 35.833333%;
}

.col-44 {
  flex: 0 0 36.666667%;
  max-width: 36.666667%;
}

.col-45 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.col-46 {
  flex: 0 0 38.333333%;
  max-width: 38.333333%;
}

.col-47 {
  flex: 0 0 39.166667%;
  max-width: 39.166667%;
}

.col-48 {
  flex: 0 0 40%;
  max-width: 40%;
}

.col-49 {
  flex: 0 0 40.833333%;
  max-width: 40.833333%;
}

.col-50 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-51 {
  flex: 0 0 42.5%;
  max-width: 42.5%;
}

.col-52 {
  flex: 0 0 43.333333%;
  max-width: 43.333333%;
}

.col-53 {
  flex: 0 0 44.166667%;
  max-width: 44.166667%;
}

.col-54 {
  flex: 0 0 45%;
  max-width: 45%;
}

.col-55 {
  flex: 0 0 45.833333%;
  max-width: 45.833333%;
}

.col-56 {
  flex: 0 0 46.666667%;
  max-width: 46.666667%;
}

.col-57 {
  flex: 0 0 47.5%;
  max-width: 47.5%;
}

.col-58 {
  flex: 0 0 48.333333%;
  max-width: 48.333333%;
}

.col-59 {
  flex: 0 0 49.166667%;
  max-width: 49.166667%;
}

.col-60 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-61 {
  flex: 0 0 50.833333%;
  max-width: 50.833333%;
}

.col-62 {
  flex: 0 0 51.666667%;
  max-width: 51.666667%;
}

.col-63 {
  flex: 0 0 52.5%;
  max-width: 52.5%;
}

.col-64 {
  flex: 0 0 53.333333%;
  max-width: 53.333333%;
}

.col-65 {
  flex: 0 0 54.166667%;
  max-width: 54.166667%;
}

.col-66 {
  flex: 0 0 55%;
  max-width: 55%;
}

.col-67 {
  flex: 0 0 55.833333%;
  max-width: 55.833333%;
}

.col-68 {
  flex: 0 0 56.666667%;
  max-width: 56.666667%;
}

.col-69 {
  flex: 0 0 57.5%;
  max-width: 57.5%;
}

.col-70 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-71 {
  flex: 0 0 59.166667%;
  max-width: 59.166667%;
}

.col-72 {
  flex: 0 0 60%;
  max-width: 60%;
}

.col-73 {
  flex: 0 0 60.833333%;
  max-width: 60.833333%;
}

.col-74 {
  flex: 0 0 61.666667%;
  max-width: 61.666667%;
}

.col-75 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.col-76 {
  flex: 0 0 63.333333%;
  max-width: 63.333333%;
}

.col-77 {
  flex: 0 0 64.166667%;
  max-width: 64.166667%;
}

.col-78 {
  flex: 0 0 65%;
  max-width: 65%;
}

.col-79 {
  flex: 0 0 65.833333%;
  max-width: 65.833333%;
}

.col-80 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-81 {
  flex: 0 0 67.5%;
  max-width: 67.5%;
}

.col-82 {
  flex: 0 0 68.333333%;
  max-width: 68.333333%;
}

.col-83 {
  flex: 0 0 69.166667%;
  max-width: 69.166667%;
}

.col-84 {
  flex: 0 0 70%;
  max-width: 70%;
}

.col-85 {
  flex: 0 0 70.833333%;
  max-width: 70.833333%;
}

.col-86 {
  flex: 0 0 71.666667%;
  max-width: 71.666667%;
}

.col-87 {
  flex: 0 0 72.5%;
  max-width: 72.5%;
}

.col-88 {
  flex: 0 0 73.333333%;
  max-width: 73.333333%;
}

.col-89 {
  flex: 0 0 74.166667%;
  max-width: 74.166667%;
}

.col-90 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-91 {
  flex: 0 0 75.833333%;
  max-width: 75.833333%;
}

.col-92 {
  flex: 0 0 76.666667%;
  max-width: 76.666667%;
}

.col-93 {
  flex: 0 0 77.5%;
  max-width: 77.5%;
}

.col-94 {
  flex: 0 0 78.333333%;
  max-width: 78.333333%;
}

.col-95 {
  flex: 0 0 79.166667%;
  max-width: 79.166667%;
}

.col-96 {
  flex: 0 0 80%;
  max-width: 80%;
}

.col-97 {
  flex: 0 0 80.833333%;
  max-width: 80.833333%;
}

.col-98 {
  flex: 0 0 81.666667%;
  max-width: 81.666667%;
}

.col-99 {
  flex: 0 0 82.5%;
  max-width: 82.5%;
}

.col-100 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-101 {
  flex: 0 0 84.166667%;
  max-width: 84.166667%;
}

.col-102 {
  flex: 0 0 85%;
  max-width: 85%;
}

.col-103 {
  flex: 0 0 85.833333%;
  max-width: 85.833333%;
}

.col-104 {
  flex: 0 0 86.666667%;
  max-width: 86.666667%;
}

.col-105 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.col-106 {
  flex: 0 0 88.333333%;
  max-width: 88.333333%;
}

.col-107 {
  flex: 0 0 89.166667%;
  max-width: 89.166667%;
}

.col-108 {
  flex: 0 0 90%;
  max-width: 90%;
}

.col-109 {
  flex: 0 0 90.833333%;
  max-width: 90.833333%;
}

.col-110 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-111 {
  flex: 0 0 92.5%;
  max-width: 92.5%;
}

.col-112 {
  flex: 0 0 93.333333%;
  max-width: 93.333333%;
}

.col-113 {
  flex: 0 0 94.166667%;
  max-width: 94.166667%;
}

.col-114 {
  flex: 0 0 95%;
  max-width: 95%;
}

.col-115 {
  flex: 0 0 95.833333%;
  max-width: 95.833333%;
}

.col-116 {
  flex: 0 0 96.666667%;
  max-width: 96.666667%;
}

.col-117 {
  flex: 0 0 97.5%;
  max-width: 97.5%;
}

.col-118 {
  flex: 0 0 98.333333%;
  max-width: 98.333333%;
}

.col-119 {
  flex: 0 0 99.166667%;
  max-width: 99.166667%;
}

.col-120 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 121;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-13 {
  order: 13;
}

.order-14 {
  order: 14;
}

.order-15 {
  order: 15;
}

.order-16 {
  order: 16;
}

.order-17 {
  order: 17;
}

.order-18 {
  order: 18;
}

.order-19 {
  order: 19;
}

.order-20 {
  order: 20;
}

.order-21 {
  order: 21;
}

.order-22 {
  order: 22;
}

.order-23 {
  order: 23;
}

.order-24 {
  order: 24;
}

.order-25 {
  order: 25;
}

.order-26 {
  order: 26;
}

.order-27 {
  order: 27;
}

.order-28 {
  order: 28;
}

.order-29 {
  order: 29;
}

.order-30 {
  order: 30;
}

.order-31 {
  order: 31;
}

.order-32 {
  order: 32;
}

.order-33 {
  order: 33;
}

.order-34 {
  order: 34;
}

.order-35 {
  order: 35;
}

.order-36 {
  order: 36;
}

.order-37 {
  order: 37;
}

.order-38 {
  order: 38;
}

.order-39 {
  order: 39;
}

.order-40 {
  order: 40;
}

.order-41 {
  order: 41;
}

.order-42 {
  order: 42;
}

.order-43 {
  order: 43;
}

.order-44 {
  order: 44;
}

.order-45 {
  order: 45;
}

.order-46 {
  order: 46;
}

.order-47 {
  order: 47;
}

.order-48 {
  order: 48;
}

.order-49 {
  order: 49;
}

.order-50 {
  order: 50;
}

.order-51 {
  order: 51;
}

.order-52 {
  order: 52;
}

.order-53 {
  order: 53;
}

.order-54 {
  order: 54;
}

.order-55 {
  order: 55;
}

.order-56 {
  order: 56;
}

.order-57 {
  order: 57;
}

.order-58 {
  order: 58;
}

.order-59 {
  order: 59;
}

.order-60 {
  order: 60;
}

.order-61 {
  order: 61;
}

.order-62 {
  order: 62;
}

.order-63 {
  order: 63;
}

.order-64 {
  order: 64;
}

.order-65 {
  order: 65;
}

.order-66 {
  order: 66;
}

.order-67 {
  order: 67;
}

.order-68 {
  order: 68;
}

.order-69 {
  order: 69;
}

.order-70 {
  order: 70;
}

.order-71 {
  order: 71;
}

.order-72 {
  order: 72;
}

.order-73 {
  order: 73;
}

.order-74 {
  order: 74;
}

.order-75 {
  order: 75;
}

.order-76 {
  order: 76;
}

.order-77 {
  order: 77;
}

.order-78 {
  order: 78;
}

.order-79 {
  order: 79;
}

.order-80 {
  order: 80;
}

.order-81 {
  order: 81;
}

.order-82 {
  order: 82;
}

.order-83 {
  order: 83;
}

.order-84 {
  order: 84;
}

.order-85 {
  order: 85;
}

.order-86 {
  order: 86;
}

.order-87 {
  order: 87;
}

.order-88 {
  order: 88;
}

.order-89 {
  order: 89;
}

.order-90 {
  order: 90;
}

.order-91 {
  order: 91;
}

.order-92 {
  order: 92;
}

.order-93 {
  order: 93;
}

.order-94 {
  order: 94;
}

.order-95 {
  order: 95;
}

.order-96 {
  order: 96;
}

.order-97 {
  order: 97;
}

.order-98 {
  order: 98;
}

.order-99 {
  order: 99;
}

.order-100 {
  order: 100;
}

.order-101 {
  order: 101;
}

.order-102 {
  order: 102;
}

.order-103 {
  order: 103;
}

.order-104 {
  order: 104;
}

.order-105 {
  order: 105;
}

.order-106 {
  order: 106;
}

.order-107 {
  order: 107;
}

.order-108 {
  order: 108;
}

.order-109 {
  order: 109;
}

.order-110 {
  order: 110;
}

.order-111 {
  order: 111;
}

.order-112 {
  order: 112;
}

.order-113 {
  order: 113;
}

.order-114 {
  order: 114;
}

.order-115 {
  order: 115;
}

.order-116 {
  order: 116;
}

.order-117 {
  order: 117;
}

.order-118 {
  order: 118;
}

.order-119 {
  order: 119;
}

.order-120 {
  order: 120;
}

.offset-1 {
  margin-left: 0.833333%;
}

.offset-2 {
  margin-left: 1.666667%;
}

.offset-3 {
  margin-left: 2.5%;
}

.offset-4 {
  margin-left: 3.333333%;
}

.offset-5 {
  margin-left: 4.166667%;
}

.offset-6 {
  margin-left: 5%;
}

.offset-7 {
  margin-left: 5.833333%;
}

.offset-8 {
  margin-left: 6.666667%;
}

.offset-9 {
  margin-left: 7.5%;
}

.offset-10 {
  margin-left: 8.333333%;
}

.offset-11 {
  margin-left: 9.166667%;
}

.offset-12 {
  margin-left: 10%;
}

.offset-13 {
  margin-left: 10.833333%;
}

.offset-14 {
  margin-left: 11.666667%;
}

.offset-15 {
  margin-left: 12.5%;
}

.offset-16 {
  margin-left: 13.333333%;
}

.offset-17 {
  margin-left: 14.166667%;
}

.offset-18 {
  margin-left: 15%;
}

.offset-19 {
  margin-left: 15.833333%;
}

.offset-20 {
  margin-left: 16.666667%;
}

.offset-21 {
  margin-left: 17.5%;
}

.offset-22 {
  margin-left: 18.333333%;
}

.offset-23 {
  margin-left: 19.166667%;
}

.offset-24 {
  margin-left: 20%;
}

.offset-25 {
  margin-left: 20.833333%;
}

.offset-26 {
  margin-left: 21.666667%;
}

.offset-27 {
  margin-left: 22.5%;
}

.offset-28 {
  margin-left: 23.333333%;
}

.offset-29 {
  margin-left: 24.166667%;
}

.offset-30 {
  margin-left: 25%;
}

.offset-31 {
  margin-left: 25.833333%;
}

.offset-32 {
  margin-left: 26.666667%;
}

.offset-33 {
  margin-left: 27.5%;
}

.offset-34 {
  margin-left: 28.333333%;
}

.offset-35 {
  margin-left: 29.166667%;
}

.offset-36 {
  margin-left: 30%;
}

.offset-37 {
  margin-left: 30.833333%;
}

.offset-38 {
  margin-left: 31.666667%;
}

.offset-39 {
  margin-left: 32.5%;
}

.offset-40 {
  margin-left: 33.333333%;
}

.offset-41 {
  margin-left: 34.166667%;
}

.offset-42 {
  margin-left: 35%;
}

.offset-43 {
  margin-left: 35.833333%;
}

.offset-44 {
  margin-left: 36.666667%;
}

.offset-45 {
  margin-left: 37.5%;
}

.offset-46 {
  margin-left: 38.333333%;
}

.offset-47 {
  margin-left: 39.166667%;
}

.offset-48 {
  margin-left: 40%;
}

.offset-49 {
  margin-left: 40.833333%;
}

.offset-50 {
  margin-left: 41.666667%;
}

.offset-51 {
  margin-left: 42.5%;
}

.offset-52 {
  margin-left: 43.333333%;
}

.offset-53 {
  margin-left: 44.166667%;
}

.offset-54 {
  margin-left: 45%;
}

.offset-55 {
  margin-left: 45.833333%;
}

.offset-56 {
  margin-left: 46.666667%;
}

.offset-57 {
  margin-left: 47.5%;
}

.offset-58 {
  margin-left: 48.333333%;
}

.offset-59 {
  margin-left: 49.166667%;
}

.offset-60 {
  margin-left: 50%;
}

.offset-61 {
  margin-left: 50.833333%;
}

.offset-62 {
  margin-left: 51.666667%;
}

.offset-63 {
  margin-left: 52.5%;
}

.offset-64 {
  margin-left: 53.333333%;
}

.offset-65 {
  margin-left: 54.166667%;
}

.offset-66 {
  margin-left: 55%;
}

.offset-67 {
  margin-left: 55.833333%;
}

.offset-68 {
  margin-left: 56.666667%;
}

.offset-69 {
  margin-left: 57.5%;
}

.offset-70 {
  margin-left: 58.333333%;
}

.offset-71 {
  margin-left: 59.166667%;
}

.offset-72 {
  margin-left: 60%;
}

.offset-73 {
  margin-left: 60.833333%;
}

.offset-74 {
  margin-left: 61.666667%;
}

.offset-75 {
  margin-left: 62.5%;
}

.offset-76 {
  margin-left: 63.333333%;
}

.offset-77 {
  margin-left: 64.166667%;
}

.offset-78 {
  margin-left: 65%;
}

.offset-79 {
  margin-left: 65.833333%;
}

.offset-80 {
  margin-left: 66.666667%;
}

.offset-81 {
  margin-left: 67.5%;
}

.offset-82 {
  margin-left: 68.333333%;
}

.offset-83 {
  margin-left: 69.166667%;
}

.offset-84 {
  margin-left: 70%;
}

.offset-85 {
  margin-left: 70.833333%;
}

.offset-86 {
  margin-left: 71.666667%;
}

.offset-87 {
  margin-left: 72.5%;
}

.offset-88 {
  margin-left: 73.333333%;
}

.offset-89 {
  margin-left: 74.166667%;
}

.offset-90 {
  margin-left: 75%;
}

.offset-91 {
  margin-left: 75.833333%;
}

.offset-92 {
  margin-left: 76.666667%;
}

.offset-93 {
  margin-left: 77.5%;
}

.offset-94 {
  margin-left: 78.333333%;
}

.offset-95 {
  margin-left: 79.166667%;
}

.offset-96 {
  margin-left: 80%;
}

.offset-97 {
  margin-left: 80.833333%;
}

.offset-98 {
  margin-left: 81.666667%;
}

.offset-99 {
  margin-left: 82.5%;
}

.offset-100 {
  margin-left: 83.333333%;
}

.offset-101 {
  margin-left: 84.166667%;
}

.offset-102 {
  margin-left: 85%;
}

.offset-103 {
  margin-left: 85.833333%;
}

.offset-104 {
  margin-left: 86.666667%;
}

.offset-105 {
  margin-left: 87.5%;
}

.offset-106 {
  margin-left: 88.333333%;
}

.offset-107 {
  margin-left: 89.166667%;
}

.offset-108 {
  margin-left: 90%;
}

.offset-109 {
  margin-left: 90.833333%;
}

.offset-110 {
  margin-left: 91.666667%;
}

.offset-111 {
  margin-left: 92.5%;
}

.offset-112 {
  margin-left: 93.333333%;
}

.offset-113 {
  margin-left: 94.166667%;
}

.offset-114 {
  margin-left: 95%;
}

.offset-115 {
  margin-left: 95.833333%;
}

.offset-116 {
  margin-left: 96.666667%;
}

.offset-117 {
  margin-left: 97.5%;
}

.offset-118 {
  margin-left: 98.333333%;
}

.offset-119 {
  margin-left: 99.166667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .row-cols-sm-7 > * {
    flex: 0 0 14.285714%;
    max-width: 14.285714%;
  }
  .row-cols-sm-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .row-cols-sm-9 > * {
    flex: 0 0 11.111111%;
    max-width: 11.111111%;
  }
  .row-cols-sm-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .row-cols-sm-11 > * {
    flex: 0 0 9.090909%;
    max-width: 9.090909%;
  }
  .row-cols-sm-12 > * {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 0.833333%;
    max-width: 0.833333%;
  }
  .col-sm-2 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-sm-3 {
    flex: 0 0 2.5%;
    max-width: 2.5%;
  }
  .col-sm-4 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-sm-5 {
    flex: 0 0 4.166667%;
    max-width: 4.166667%;
  }
  .col-sm-6 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-sm-7 {
    flex: 0 0 5.833333%;
    max-width: 5.833333%;
  }
  .col-sm-8 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-sm-9 {
    flex: 0 0 7.5%;
    max-width: 7.5%;
  }
  .col-sm-10 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-11 {
    flex: 0 0 9.166667%;
    max-width: 9.166667%;
  }
  .col-sm-12 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-sm-13 {
    flex: 0 0 10.833333%;
    max-width: 10.833333%;
  }
  .col-sm-14 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-sm-15 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-sm-16 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-sm-17 {
    flex: 0 0 14.166667%;
    max-width: 14.166667%;
  }
  .col-sm-18 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-sm-19 {
    flex: 0 0 15.833333%;
    max-width: 15.833333%;
  }
  .col-sm-20 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-21 {
    flex: 0 0 17.5%;
    max-width: 17.5%;
  }
  .col-sm-22 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-sm-23 {
    flex: 0 0 19.166667%;
    max-width: 19.166667%;
  }
  .col-sm-24 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-sm-25 {
    flex: 0 0 20.833333%;
    max-width: 20.833333%;
  }
  .col-sm-26 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-sm-27 {
    flex: 0 0 22.5%;
    max-width: 22.5%;
  }
  .col-sm-28 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-sm-29 {
    flex: 0 0 24.166667%;
    max-width: 24.166667%;
  }
  .col-sm-30 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-31 {
    flex: 0 0 25.833333%;
    max-width: 25.833333%;
  }
  .col-sm-32 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-sm-33 {
    flex: 0 0 27.5%;
    max-width: 27.5%;
  }
  .col-sm-34 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-sm-35 {
    flex: 0 0 29.166667%;
    max-width: 29.166667%;
  }
  .col-sm-36 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-sm-37 {
    flex: 0 0 30.833333%;
    max-width: 30.833333%;
  }
  .col-sm-38 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-sm-39 {
    flex: 0 0 32.5%;
    max-width: 32.5%;
  }
  .col-sm-40 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-41 {
    flex: 0 0 34.166667%;
    max-width: 34.166667%;
  }
  .col-sm-42 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-sm-43 {
    flex: 0 0 35.833333%;
    max-width: 35.833333%;
  }
  .col-sm-44 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-sm-45 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-sm-46 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-sm-47 {
    flex: 0 0 39.166667%;
    max-width: 39.166667%;
  }
  .col-sm-48 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-sm-49 {
    flex: 0 0 40.833333%;
    max-width: 40.833333%;
  }
  .col-sm-50 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-51 {
    flex: 0 0 42.5%;
    max-width: 42.5%;
  }
  .col-sm-52 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-sm-53 {
    flex: 0 0 44.166667%;
    max-width: 44.166667%;
  }
  .col-sm-54 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-sm-55 {
    flex: 0 0 45.833333%;
    max-width: 45.833333%;
  }
  .col-sm-56 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-sm-57 {
    flex: 0 0 47.5%;
    max-width: 47.5%;
  }
  .col-sm-58 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-sm-59 {
    flex: 0 0 49.166667%;
    max-width: 49.166667%;
  }
  .col-sm-60 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-61 {
    flex: 0 0 50.833333%;
    max-width: 50.833333%;
  }
  .col-sm-62 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-sm-63 {
    flex: 0 0 52.5%;
    max-width: 52.5%;
  }
  .col-sm-64 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-sm-65 {
    flex: 0 0 54.166667%;
    max-width: 54.166667%;
  }
  .col-sm-66 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-sm-67 {
    flex: 0 0 55.833333%;
    max-width: 55.833333%;
  }
  .col-sm-68 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-sm-69 {
    flex: 0 0 57.5%;
    max-width: 57.5%;
  }
  .col-sm-70 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-71 {
    flex: 0 0 59.166667%;
    max-width: 59.166667%;
  }
  .col-sm-72 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-sm-73 {
    flex: 0 0 60.833333%;
    max-width: 60.833333%;
  }
  .col-sm-74 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-sm-75 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-sm-76 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-sm-77 {
    flex: 0 0 64.166667%;
    max-width: 64.166667%;
  }
  .col-sm-78 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-sm-79 {
    flex: 0 0 65.833333%;
    max-width: 65.833333%;
  }
  .col-sm-80 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-81 {
    flex: 0 0 67.5%;
    max-width: 67.5%;
  }
  .col-sm-82 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-sm-83 {
    flex: 0 0 69.166667%;
    max-width: 69.166667%;
  }
  .col-sm-84 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-sm-85 {
    flex: 0 0 70.833333%;
    max-width: 70.833333%;
  }
  .col-sm-86 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-sm-87 {
    flex: 0 0 72.5%;
    max-width: 72.5%;
  }
  .col-sm-88 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-sm-89 {
    flex: 0 0 74.166667%;
    max-width: 74.166667%;
  }
  .col-sm-90 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-91 {
    flex: 0 0 75.833333%;
    max-width: 75.833333%;
  }
  .col-sm-92 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-sm-93 {
    flex: 0 0 77.5%;
    max-width: 77.5%;
  }
  .col-sm-94 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-sm-95 {
    flex: 0 0 79.166667%;
    max-width: 79.166667%;
  }
  .col-sm-96 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-sm-97 {
    flex: 0 0 80.833333%;
    max-width: 80.833333%;
  }
  .col-sm-98 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-sm-99 {
    flex: 0 0 82.5%;
    max-width: 82.5%;
  }
  .col-sm-100 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-101 {
    flex: 0 0 84.166667%;
    max-width: 84.166667%;
  }
  .col-sm-102 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-sm-103 {
    flex: 0 0 85.833333%;
    max-width: 85.833333%;
  }
  .col-sm-104 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-sm-105 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-sm-106 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-sm-107 {
    flex: 0 0 89.166667%;
    max-width: 89.166667%;
  }
  .col-sm-108 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-sm-109 {
    flex: 0 0 90.833333%;
    max-width: 90.833333%;
  }
  .col-sm-110 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-111 {
    flex: 0 0 92.5%;
    max-width: 92.5%;
  }
  .col-sm-112 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-sm-113 {
    flex: 0 0 94.166667%;
    max-width: 94.166667%;
  }
  .col-sm-114 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-sm-115 {
    flex: 0 0 95.833333%;
    max-width: 95.833333%;
  }
  .col-sm-116 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-sm-117 {
    flex: 0 0 97.5%;
    max-width: 97.5%;
  }
  .col-sm-118 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-sm-119 {
    flex: 0 0 99.166667%;
    max-width: 99.166667%;
  }
  .col-sm-120 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 121;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .order-sm-13 {
    order: 13;
  }
  .order-sm-14 {
    order: 14;
  }
  .order-sm-15 {
    order: 15;
  }
  .order-sm-16 {
    order: 16;
  }
  .order-sm-17 {
    order: 17;
  }
  .order-sm-18 {
    order: 18;
  }
  .order-sm-19 {
    order: 19;
  }
  .order-sm-20 {
    order: 20;
  }
  .order-sm-21 {
    order: 21;
  }
  .order-sm-22 {
    order: 22;
  }
  .order-sm-23 {
    order: 23;
  }
  .order-sm-24 {
    order: 24;
  }
  .order-sm-25 {
    order: 25;
  }
  .order-sm-26 {
    order: 26;
  }
  .order-sm-27 {
    order: 27;
  }
  .order-sm-28 {
    order: 28;
  }
  .order-sm-29 {
    order: 29;
  }
  .order-sm-30 {
    order: 30;
  }
  .order-sm-31 {
    order: 31;
  }
  .order-sm-32 {
    order: 32;
  }
  .order-sm-33 {
    order: 33;
  }
  .order-sm-34 {
    order: 34;
  }
  .order-sm-35 {
    order: 35;
  }
  .order-sm-36 {
    order: 36;
  }
  .order-sm-37 {
    order: 37;
  }
  .order-sm-38 {
    order: 38;
  }
  .order-sm-39 {
    order: 39;
  }
  .order-sm-40 {
    order: 40;
  }
  .order-sm-41 {
    order: 41;
  }
  .order-sm-42 {
    order: 42;
  }
  .order-sm-43 {
    order: 43;
  }
  .order-sm-44 {
    order: 44;
  }
  .order-sm-45 {
    order: 45;
  }
  .order-sm-46 {
    order: 46;
  }
  .order-sm-47 {
    order: 47;
  }
  .order-sm-48 {
    order: 48;
  }
  .order-sm-49 {
    order: 49;
  }
  .order-sm-50 {
    order: 50;
  }
  .order-sm-51 {
    order: 51;
  }
  .order-sm-52 {
    order: 52;
  }
  .order-sm-53 {
    order: 53;
  }
  .order-sm-54 {
    order: 54;
  }
  .order-sm-55 {
    order: 55;
  }
  .order-sm-56 {
    order: 56;
  }
  .order-sm-57 {
    order: 57;
  }
  .order-sm-58 {
    order: 58;
  }
  .order-sm-59 {
    order: 59;
  }
  .order-sm-60 {
    order: 60;
  }
  .order-sm-61 {
    order: 61;
  }
  .order-sm-62 {
    order: 62;
  }
  .order-sm-63 {
    order: 63;
  }
  .order-sm-64 {
    order: 64;
  }
  .order-sm-65 {
    order: 65;
  }
  .order-sm-66 {
    order: 66;
  }
  .order-sm-67 {
    order: 67;
  }
  .order-sm-68 {
    order: 68;
  }
  .order-sm-69 {
    order: 69;
  }
  .order-sm-70 {
    order: 70;
  }
  .order-sm-71 {
    order: 71;
  }
  .order-sm-72 {
    order: 72;
  }
  .order-sm-73 {
    order: 73;
  }
  .order-sm-74 {
    order: 74;
  }
  .order-sm-75 {
    order: 75;
  }
  .order-sm-76 {
    order: 76;
  }
  .order-sm-77 {
    order: 77;
  }
  .order-sm-78 {
    order: 78;
  }
  .order-sm-79 {
    order: 79;
  }
  .order-sm-80 {
    order: 80;
  }
  .order-sm-81 {
    order: 81;
  }
  .order-sm-82 {
    order: 82;
  }
  .order-sm-83 {
    order: 83;
  }
  .order-sm-84 {
    order: 84;
  }
  .order-sm-85 {
    order: 85;
  }
  .order-sm-86 {
    order: 86;
  }
  .order-sm-87 {
    order: 87;
  }
  .order-sm-88 {
    order: 88;
  }
  .order-sm-89 {
    order: 89;
  }
  .order-sm-90 {
    order: 90;
  }
  .order-sm-91 {
    order: 91;
  }
  .order-sm-92 {
    order: 92;
  }
  .order-sm-93 {
    order: 93;
  }
  .order-sm-94 {
    order: 94;
  }
  .order-sm-95 {
    order: 95;
  }
  .order-sm-96 {
    order: 96;
  }
  .order-sm-97 {
    order: 97;
  }
  .order-sm-98 {
    order: 98;
  }
  .order-sm-99 {
    order: 99;
  }
  .order-sm-100 {
    order: 100;
  }
  .order-sm-101 {
    order: 101;
  }
  .order-sm-102 {
    order: 102;
  }
  .order-sm-103 {
    order: 103;
  }
  .order-sm-104 {
    order: 104;
  }
  .order-sm-105 {
    order: 105;
  }
  .order-sm-106 {
    order: 106;
  }
  .order-sm-107 {
    order: 107;
  }
  .order-sm-108 {
    order: 108;
  }
  .order-sm-109 {
    order: 109;
  }
  .order-sm-110 {
    order: 110;
  }
  .order-sm-111 {
    order: 111;
  }
  .order-sm-112 {
    order: 112;
  }
  .order-sm-113 {
    order: 113;
  }
  .order-sm-114 {
    order: 114;
  }
  .order-sm-115 {
    order: 115;
  }
  .order-sm-116 {
    order: 116;
  }
  .order-sm-117 {
    order: 117;
  }
  .order-sm-118 {
    order: 118;
  }
  .order-sm-119 {
    order: 119;
  }
  .order-sm-120 {
    order: 120;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 0.833333%;
  }
  .offset-sm-2 {
    margin-left: 1.666667%;
  }
  .offset-sm-3 {
    margin-left: 2.5%;
  }
  .offset-sm-4 {
    margin-left: 3.333333%;
  }
  .offset-sm-5 {
    margin-left: 4.166667%;
  }
  .offset-sm-6 {
    margin-left: 5%;
  }
  .offset-sm-7 {
    margin-left: 5.833333%;
  }
  .offset-sm-8 {
    margin-left: 6.666667%;
  }
  .offset-sm-9 {
    margin-left: 7.5%;
  }
  .offset-sm-10 {
    margin-left: 8.333333%;
  }
  .offset-sm-11 {
    margin-left: 9.166667%;
  }
  .offset-sm-12 {
    margin-left: 10%;
  }
  .offset-sm-13 {
    margin-left: 10.833333%;
  }
  .offset-sm-14 {
    margin-left: 11.666667%;
  }
  .offset-sm-15 {
    margin-left: 12.5%;
  }
  .offset-sm-16 {
    margin-left: 13.333333%;
  }
  .offset-sm-17 {
    margin-left: 14.166667%;
  }
  .offset-sm-18 {
    margin-left: 15%;
  }
  .offset-sm-19 {
    margin-left: 15.833333%;
  }
  .offset-sm-20 {
    margin-left: 16.666667%;
  }
  .offset-sm-21 {
    margin-left: 17.5%;
  }
  .offset-sm-22 {
    margin-left: 18.333333%;
  }
  .offset-sm-23 {
    margin-left: 19.166667%;
  }
  .offset-sm-24 {
    margin-left: 20%;
  }
  .offset-sm-25 {
    margin-left: 20.833333%;
  }
  .offset-sm-26 {
    margin-left: 21.666667%;
  }
  .offset-sm-27 {
    margin-left: 22.5%;
  }
  .offset-sm-28 {
    margin-left: 23.333333%;
  }
  .offset-sm-29 {
    margin-left: 24.166667%;
  }
  .offset-sm-30 {
    margin-left: 25%;
  }
  .offset-sm-31 {
    margin-left: 25.833333%;
  }
  .offset-sm-32 {
    margin-left: 26.666667%;
  }
  .offset-sm-33 {
    margin-left: 27.5%;
  }
  .offset-sm-34 {
    margin-left: 28.333333%;
  }
  .offset-sm-35 {
    margin-left: 29.166667%;
  }
  .offset-sm-36 {
    margin-left: 30%;
  }
  .offset-sm-37 {
    margin-left: 30.833333%;
  }
  .offset-sm-38 {
    margin-left: 31.666667%;
  }
  .offset-sm-39 {
    margin-left: 32.5%;
  }
  .offset-sm-40 {
    margin-left: 33.333333%;
  }
  .offset-sm-41 {
    margin-left: 34.166667%;
  }
  .offset-sm-42 {
    margin-left: 35%;
  }
  .offset-sm-43 {
    margin-left: 35.833333%;
  }
  .offset-sm-44 {
    margin-left: 36.666667%;
  }
  .offset-sm-45 {
    margin-left: 37.5%;
  }
  .offset-sm-46 {
    margin-left: 38.333333%;
  }
  .offset-sm-47 {
    margin-left: 39.166667%;
  }
  .offset-sm-48 {
    margin-left: 40%;
  }
  .offset-sm-49 {
    margin-left: 40.833333%;
  }
  .offset-sm-50 {
    margin-left: 41.666667%;
  }
  .offset-sm-51 {
    margin-left: 42.5%;
  }
  .offset-sm-52 {
    margin-left: 43.333333%;
  }
  .offset-sm-53 {
    margin-left: 44.166667%;
  }
  .offset-sm-54 {
    margin-left: 45%;
  }
  .offset-sm-55 {
    margin-left: 45.833333%;
  }
  .offset-sm-56 {
    margin-left: 46.666667%;
  }
  .offset-sm-57 {
    margin-left: 47.5%;
  }
  .offset-sm-58 {
    margin-left: 48.333333%;
  }
  .offset-sm-59 {
    margin-left: 49.166667%;
  }
  .offset-sm-60 {
    margin-left: 50%;
  }
  .offset-sm-61 {
    margin-left: 50.833333%;
  }
  .offset-sm-62 {
    margin-left: 51.666667%;
  }
  .offset-sm-63 {
    margin-left: 52.5%;
  }
  .offset-sm-64 {
    margin-left: 53.333333%;
  }
  .offset-sm-65 {
    margin-left: 54.166667%;
  }
  .offset-sm-66 {
    margin-left: 55%;
  }
  .offset-sm-67 {
    margin-left: 55.833333%;
  }
  .offset-sm-68 {
    margin-left: 56.666667%;
  }
  .offset-sm-69 {
    margin-left: 57.5%;
  }
  .offset-sm-70 {
    margin-left: 58.333333%;
  }
  .offset-sm-71 {
    margin-left: 59.166667%;
  }
  .offset-sm-72 {
    margin-left: 60%;
  }
  .offset-sm-73 {
    margin-left: 60.833333%;
  }
  .offset-sm-74 {
    margin-left: 61.666667%;
  }
  .offset-sm-75 {
    margin-left: 62.5%;
  }
  .offset-sm-76 {
    margin-left: 63.333333%;
  }
  .offset-sm-77 {
    margin-left: 64.166667%;
  }
  .offset-sm-78 {
    margin-left: 65%;
  }
  .offset-sm-79 {
    margin-left: 65.833333%;
  }
  .offset-sm-80 {
    margin-left: 66.666667%;
  }
  .offset-sm-81 {
    margin-left: 67.5%;
  }
  .offset-sm-82 {
    margin-left: 68.333333%;
  }
  .offset-sm-83 {
    margin-left: 69.166667%;
  }
  .offset-sm-84 {
    margin-left: 70%;
  }
  .offset-sm-85 {
    margin-left: 70.833333%;
  }
  .offset-sm-86 {
    margin-left: 71.666667%;
  }
  .offset-sm-87 {
    margin-left: 72.5%;
  }
  .offset-sm-88 {
    margin-left: 73.333333%;
  }
  .offset-sm-89 {
    margin-left: 74.166667%;
  }
  .offset-sm-90 {
    margin-left: 75%;
  }
  .offset-sm-91 {
    margin-left: 75.833333%;
  }
  .offset-sm-92 {
    margin-left: 76.666667%;
  }
  .offset-sm-93 {
    margin-left: 77.5%;
  }
  .offset-sm-94 {
    margin-left: 78.333333%;
  }
  .offset-sm-95 {
    margin-left: 79.166667%;
  }
  .offset-sm-96 {
    margin-left: 80%;
  }
  .offset-sm-97 {
    margin-left: 80.833333%;
  }
  .offset-sm-98 {
    margin-left: 81.666667%;
  }
  .offset-sm-99 {
    margin-left: 82.5%;
  }
  .offset-sm-100 {
    margin-left: 83.333333%;
  }
  .offset-sm-101 {
    margin-left: 84.166667%;
  }
  .offset-sm-102 {
    margin-left: 85%;
  }
  .offset-sm-103 {
    margin-left: 85.833333%;
  }
  .offset-sm-104 {
    margin-left: 86.666667%;
  }
  .offset-sm-105 {
    margin-left: 87.5%;
  }
  .offset-sm-106 {
    margin-left: 88.333333%;
  }
  .offset-sm-107 {
    margin-left: 89.166667%;
  }
  .offset-sm-108 {
    margin-left: 90%;
  }
  .offset-sm-109 {
    margin-left: 90.833333%;
  }
  .offset-sm-110 {
    margin-left: 91.666667%;
  }
  .offset-sm-111 {
    margin-left: 92.5%;
  }
  .offset-sm-112 {
    margin-left: 93.333333%;
  }
  .offset-sm-113 {
    margin-left: 94.166667%;
  }
  .offset-sm-114 {
    margin-left: 95%;
  }
  .offset-sm-115 {
    margin-left: 95.833333%;
  }
  .offset-sm-116 {
    margin-left: 96.666667%;
  }
  .offset-sm-117 {
    margin-left: 97.5%;
  }
  .offset-sm-118 {
    margin-left: 98.333333%;
  }
  .offset-sm-119 {
    margin-left: 99.166667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .row-cols-md-7 > * {
    flex: 0 0 14.285714%;
    max-width: 14.285714%;
  }
  .row-cols-md-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .row-cols-md-9 > * {
    flex: 0 0 11.111111%;
    max-width: 11.111111%;
  }
  .row-cols-md-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .row-cols-md-11 > * {
    flex: 0 0 9.090909%;
    max-width: 9.090909%;
  }
  .row-cols-md-12 > * {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 0.833333%;
    max-width: 0.833333%;
  }
  .col-md-2 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-md-3 {
    flex: 0 0 2.5%;
    max-width: 2.5%;
  }
  .col-md-4 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-md-5 {
    flex: 0 0 4.166667%;
    max-width: 4.166667%;
  }
  .col-md-6 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-md-7 {
    flex: 0 0 5.833333%;
    max-width: 5.833333%;
  }
  .col-md-8 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-md-9 {
    flex: 0 0 7.5%;
    max-width: 7.5%;
  }
  .col-md-10 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-11 {
    flex: 0 0 9.166667%;
    max-width: 9.166667%;
  }
  .col-md-12 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-md-13 {
    flex: 0 0 10.833333%;
    max-width: 10.833333%;
  }
  .col-md-14 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-md-15 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-md-16 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-md-17 {
    flex: 0 0 14.166667%;
    max-width: 14.166667%;
  }
  .col-md-18 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-md-19 {
    flex: 0 0 15.833333%;
    max-width: 15.833333%;
  }
  .col-md-20 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-21 {
    flex: 0 0 17.5%;
    max-width: 17.5%;
  }
  .col-md-22 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-md-23 {
    flex: 0 0 19.166667%;
    max-width: 19.166667%;
  }
  .col-md-24 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-md-25 {
    flex: 0 0 20.833333%;
    max-width: 20.833333%;
  }
  .col-md-26 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-md-27 {
    flex: 0 0 22.5%;
    max-width: 22.5%;
  }
  .col-md-28 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-md-29 {
    flex: 0 0 24.166667%;
    max-width: 24.166667%;
  }
  .col-md-30 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-31 {
    flex: 0 0 25.833333%;
    max-width: 25.833333%;
  }
  .col-md-32 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-md-33 {
    flex: 0 0 27.5%;
    max-width: 27.5%;
  }
  .col-md-34 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-md-35 {
    flex: 0 0 29.166667%;
    max-width: 29.166667%;
  }
  .col-md-36 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-md-37 {
    flex: 0 0 30.833333%;
    max-width: 30.833333%;
  }
  .col-md-38 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-md-39 {
    flex: 0 0 32.5%;
    max-width: 32.5%;
  }
  .col-md-40 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-41 {
    flex: 0 0 34.166667%;
    max-width: 34.166667%;
  }
  .col-md-42 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-md-43 {
    flex: 0 0 35.833333%;
    max-width: 35.833333%;
  }
  .col-md-44 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-md-45 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-md-46 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-md-47 {
    flex: 0 0 39.166667%;
    max-width: 39.166667%;
  }
  .col-md-48 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-md-49 {
    flex: 0 0 40.833333%;
    max-width: 40.833333%;
  }
  .col-md-50 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-51 {
    flex: 0 0 42.5%;
    max-width: 42.5%;
  }
  .col-md-52 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-md-53 {
    flex: 0 0 44.166667%;
    max-width: 44.166667%;
  }
  .col-md-54 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-md-55 {
    flex: 0 0 45.833333%;
    max-width: 45.833333%;
  }
  .col-md-56 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-md-57 {
    flex: 0 0 47.5%;
    max-width: 47.5%;
  }
  .col-md-58 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-md-59 {
    flex: 0 0 49.166667%;
    max-width: 49.166667%;
  }
  .col-md-60 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-61 {
    flex: 0 0 50.833333%;
    max-width: 50.833333%;
  }
  .col-md-62 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-md-63 {
    flex: 0 0 52.5%;
    max-width: 52.5%;
  }
  .col-md-64 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-md-65 {
    flex: 0 0 54.166667%;
    max-width: 54.166667%;
  }
  .col-md-66 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-md-67 {
    flex: 0 0 55.833333%;
    max-width: 55.833333%;
  }
  .col-md-68 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-md-69 {
    flex: 0 0 57.5%;
    max-width: 57.5%;
  }
  .col-md-70 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-71 {
    flex: 0 0 59.166667%;
    max-width: 59.166667%;
  }
  .col-md-72 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-md-73 {
    flex: 0 0 60.833333%;
    max-width: 60.833333%;
  }
  .col-md-74 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-md-75 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-md-76 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-md-77 {
    flex: 0 0 64.166667%;
    max-width: 64.166667%;
  }
  .col-md-78 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-md-79 {
    flex: 0 0 65.833333%;
    max-width: 65.833333%;
  }
  .col-md-80 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-81 {
    flex: 0 0 67.5%;
    max-width: 67.5%;
  }
  .col-md-82 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-md-83 {
    flex: 0 0 69.166667%;
    max-width: 69.166667%;
  }
  .col-md-84 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-md-85 {
    flex: 0 0 70.833333%;
    max-width: 70.833333%;
  }
  .col-md-86 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-md-87 {
    flex: 0 0 72.5%;
    max-width: 72.5%;
  }
  .col-md-88 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-md-89 {
    flex: 0 0 74.166667%;
    max-width: 74.166667%;
  }
  .col-md-90 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-91 {
    flex: 0 0 75.833333%;
    max-width: 75.833333%;
  }
  .col-md-92 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-md-93 {
    flex: 0 0 77.5%;
    max-width: 77.5%;
  }
  .col-md-94 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-md-95 {
    flex: 0 0 79.166667%;
    max-width: 79.166667%;
  }
  .col-md-96 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-md-97 {
    flex: 0 0 80.833333%;
    max-width: 80.833333%;
  }
  .col-md-98 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-md-99 {
    flex: 0 0 82.5%;
    max-width: 82.5%;
  }
  .col-md-100 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-101 {
    flex: 0 0 84.166667%;
    max-width: 84.166667%;
  }
  .col-md-102 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-md-103 {
    flex: 0 0 85.833333%;
    max-width: 85.833333%;
  }
  .col-md-104 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-md-105 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-md-106 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-md-107 {
    flex: 0 0 89.166667%;
    max-width: 89.166667%;
  }
  .col-md-108 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-md-109 {
    flex: 0 0 90.833333%;
    max-width: 90.833333%;
  }
  .col-md-110 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-111 {
    flex: 0 0 92.5%;
    max-width: 92.5%;
  }
  .col-md-112 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-md-113 {
    flex: 0 0 94.166667%;
    max-width: 94.166667%;
  }
  .col-md-114 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-md-115 {
    flex: 0 0 95.833333%;
    max-width: 95.833333%;
  }
  .col-md-116 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-md-117 {
    flex: 0 0 97.5%;
    max-width: 97.5%;
  }
  .col-md-118 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-md-119 {
    flex: 0 0 99.166667%;
    max-width: 99.166667%;
  }
  .col-md-120 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 121;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .order-md-13 {
    order: 13;
  }
  .order-md-14 {
    order: 14;
  }
  .order-md-15 {
    order: 15;
  }
  .order-md-16 {
    order: 16;
  }
  .order-md-17 {
    order: 17;
  }
  .order-md-18 {
    order: 18;
  }
  .order-md-19 {
    order: 19;
  }
  .order-md-20 {
    order: 20;
  }
  .order-md-21 {
    order: 21;
  }
  .order-md-22 {
    order: 22;
  }
  .order-md-23 {
    order: 23;
  }
  .order-md-24 {
    order: 24;
  }
  .order-md-25 {
    order: 25;
  }
  .order-md-26 {
    order: 26;
  }
  .order-md-27 {
    order: 27;
  }
  .order-md-28 {
    order: 28;
  }
  .order-md-29 {
    order: 29;
  }
  .order-md-30 {
    order: 30;
  }
  .order-md-31 {
    order: 31;
  }
  .order-md-32 {
    order: 32;
  }
  .order-md-33 {
    order: 33;
  }
  .order-md-34 {
    order: 34;
  }
  .order-md-35 {
    order: 35;
  }
  .order-md-36 {
    order: 36;
  }
  .order-md-37 {
    order: 37;
  }
  .order-md-38 {
    order: 38;
  }
  .order-md-39 {
    order: 39;
  }
  .order-md-40 {
    order: 40;
  }
  .order-md-41 {
    order: 41;
  }
  .order-md-42 {
    order: 42;
  }
  .order-md-43 {
    order: 43;
  }
  .order-md-44 {
    order: 44;
  }
  .order-md-45 {
    order: 45;
  }
  .order-md-46 {
    order: 46;
  }
  .order-md-47 {
    order: 47;
  }
  .order-md-48 {
    order: 48;
  }
  .order-md-49 {
    order: 49;
  }
  .order-md-50 {
    order: 50;
  }
  .order-md-51 {
    order: 51;
  }
  .order-md-52 {
    order: 52;
  }
  .order-md-53 {
    order: 53;
  }
  .order-md-54 {
    order: 54;
  }
  .order-md-55 {
    order: 55;
  }
  .order-md-56 {
    order: 56;
  }
  .order-md-57 {
    order: 57;
  }
  .order-md-58 {
    order: 58;
  }
  .order-md-59 {
    order: 59;
  }
  .order-md-60 {
    order: 60;
  }
  .order-md-61 {
    order: 61;
  }
  .order-md-62 {
    order: 62;
  }
  .order-md-63 {
    order: 63;
  }
  .order-md-64 {
    order: 64;
  }
  .order-md-65 {
    order: 65;
  }
  .order-md-66 {
    order: 66;
  }
  .order-md-67 {
    order: 67;
  }
  .order-md-68 {
    order: 68;
  }
  .order-md-69 {
    order: 69;
  }
  .order-md-70 {
    order: 70;
  }
  .order-md-71 {
    order: 71;
  }
  .order-md-72 {
    order: 72;
  }
  .order-md-73 {
    order: 73;
  }
  .order-md-74 {
    order: 74;
  }
  .order-md-75 {
    order: 75;
  }
  .order-md-76 {
    order: 76;
  }
  .order-md-77 {
    order: 77;
  }
  .order-md-78 {
    order: 78;
  }
  .order-md-79 {
    order: 79;
  }
  .order-md-80 {
    order: 80;
  }
  .order-md-81 {
    order: 81;
  }
  .order-md-82 {
    order: 82;
  }
  .order-md-83 {
    order: 83;
  }
  .order-md-84 {
    order: 84;
  }
  .order-md-85 {
    order: 85;
  }
  .order-md-86 {
    order: 86;
  }
  .order-md-87 {
    order: 87;
  }
  .order-md-88 {
    order: 88;
  }
  .order-md-89 {
    order: 89;
  }
  .order-md-90 {
    order: 90;
  }
  .order-md-91 {
    order: 91;
  }
  .order-md-92 {
    order: 92;
  }
  .order-md-93 {
    order: 93;
  }
  .order-md-94 {
    order: 94;
  }
  .order-md-95 {
    order: 95;
  }
  .order-md-96 {
    order: 96;
  }
  .order-md-97 {
    order: 97;
  }
  .order-md-98 {
    order: 98;
  }
  .order-md-99 {
    order: 99;
  }
  .order-md-100 {
    order: 100;
  }
  .order-md-101 {
    order: 101;
  }
  .order-md-102 {
    order: 102;
  }
  .order-md-103 {
    order: 103;
  }
  .order-md-104 {
    order: 104;
  }
  .order-md-105 {
    order: 105;
  }
  .order-md-106 {
    order: 106;
  }
  .order-md-107 {
    order: 107;
  }
  .order-md-108 {
    order: 108;
  }
  .order-md-109 {
    order: 109;
  }
  .order-md-110 {
    order: 110;
  }
  .order-md-111 {
    order: 111;
  }
  .order-md-112 {
    order: 112;
  }
  .order-md-113 {
    order: 113;
  }
  .order-md-114 {
    order: 114;
  }
  .order-md-115 {
    order: 115;
  }
  .order-md-116 {
    order: 116;
  }
  .order-md-117 {
    order: 117;
  }
  .order-md-118 {
    order: 118;
  }
  .order-md-119 {
    order: 119;
  }
  .order-md-120 {
    order: 120;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 0.833333%;
  }
  .offset-md-2 {
    margin-left: 1.666667%;
  }
  .offset-md-3 {
    margin-left: 2.5%;
  }
  .offset-md-4 {
    margin-left: 3.333333%;
  }
  .offset-md-5 {
    margin-left: 4.166667%;
  }
  .offset-md-6 {
    margin-left: 5%;
  }
  .offset-md-7 {
    margin-left: 5.833333%;
  }
  .offset-md-8 {
    margin-left: 6.666667%;
  }
  .offset-md-9 {
    margin-left: 7.5%;
  }
  .offset-md-10 {
    margin-left: 8.333333%;
  }
  .offset-md-11 {
    margin-left: 9.166667%;
  }
  .offset-md-12 {
    margin-left: 10%;
  }
  .offset-md-13 {
    margin-left: 10.833333%;
  }
  .offset-md-14 {
    margin-left: 11.666667%;
  }
  .offset-md-15 {
    margin-left: 12.5%;
  }
  .offset-md-16 {
    margin-left: 13.333333%;
  }
  .offset-md-17 {
    margin-left: 14.166667%;
  }
  .offset-md-18 {
    margin-left: 15%;
  }
  .offset-md-19 {
    margin-left: 15.833333%;
  }
  .offset-md-20 {
    margin-left: 16.666667%;
  }
  .offset-md-21 {
    margin-left: 17.5%;
  }
  .offset-md-22 {
    margin-left: 18.333333%;
  }
  .offset-md-23 {
    margin-left: 19.166667%;
  }
  .offset-md-24 {
    margin-left: 20%;
  }
  .offset-md-25 {
    margin-left: 20.833333%;
  }
  .offset-md-26 {
    margin-left: 21.666667%;
  }
  .offset-md-27 {
    margin-left: 22.5%;
  }
  .offset-md-28 {
    margin-left: 23.333333%;
  }
  .offset-md-29 {
    margin-left: 24.166667%;
  }
  .offset-md-30 {
    margin-left: 25%;
  }
  .offset-md-31 {
    margin-left: 25.833333%;
  }
  .offset-md-32 {
    margin-left: 26.666667%;
  }
  .offset-md-33 {
    margin-left: 27.5%;
  }
  .offset-md-34 {
    margin-left: 28.333333%;
  }
  .offset-md-35 {
    margin-left: 29.166667%;
  }
  .offset-md-36 {
    margin-left: 30%;
  }
  .offset-md-37 {
    margin-left: 30.833333%;
  }
  .offset-md-38 {
    margin-left: 31.666667%;
  }
  .offset-md-39 {
    margin-left: 32.5%;
  }
  .offset-md-40 {
    margin-left: 33.333333%;
  }
  .offset-md-41 {
    margin-left: 34.166667%;
  }
  .offset-md-42 {
    margin-left: 35%;
  }
  .offset-md-43 {
    margin-left: 35.833333%;
  }
  .offset-md-44 {
    margin-left: 36.666667%;
  }
  .offset-md-45 {
    margin-left: 37.5%;
  }
  .offset-md-46 {
    margin-left: 38.333333%;
  }
  .offset-md-47 {
    margin-left: 39.166667%;
  }
  .offset-md-48 {
    margin-left: 40%;
  }
  .offset-md-49 {
    margin-left: 40.833333%;
  }
  .offset-md-50 {
    margin-left: 41.666667%;
  }
  .offset-md-51 {
    margin-left: 42.5%;
  }
  .offset-md-52 {
    margin-left: 43.333333%;
  }
  .offset-md-53 {
    margin-left: 44.166667%;
  }
  .offset-md-54 {
    margin-left: 45%;
  }
  .offset-md-55 {
    margin-left: 45.833333%;
  }
  .offset-md-56 {
    margin-left: 46.666667%;
  }
  .offset-md-57 {
    margin-left: 47.5%;
  }
  .offset-md-58 {
    margin-left: 48.333333%;
  }
  .offset-md-59 {
    margin-left: 49.166667%;
  }
  .offset-md-60 {
    margin-left: 50%;
  }
  .offset-md-61 {
    margin-left: 50.833333%;
  }
  .offset-md-62 {
    margin-left: 51.666667%;
  }
  .offset-md-63 {
    margin-left: 52.5%;
  }
  .offset-md-64 {
    margin-left: 53.333333%;
  }
  .offset-md-65 {
    margin-left: 54.166667%;
  }
  .offset-md-66 {
    margin-left: 55%;
  }
  .offset-md-67 {
    margin-left: 55.833333%;
  }
  .offset-md-68 {
    margin-left: 56.666667%;
  }
  .offset-md-69 {
    margin-left: 57.5%;
  }
  .offset-md-70 {
    margin-left: 58.333333%;
  }
  .offset-md-71 {
    margin-left: 59.166667%;
  }
  .offset-md-72 {
    margin-left: 60%;
  }
  .offset-md-73 {
    margin-left: 60.833333%;
  }
  .offset-md-74 {
    margin-left: 61.666667%;
  }
  .offset-md-75 {
    margin-left: 62.5%;
  }
  .offset-md-76 {
    margin-left: 63.333333%;
  }
  .offset-md-77 {
    margin-left: 64.166667%;
  }
  .offset-md-78 {
    margin-left: 65%;
  }
  .offset-md-79 {
    margin-left: 65.833333%;
  }
  .offset-md-80 {
    margin-left: 66.666667%;
  }
  .offset-md-81 {
    margin-left: 67.5%;
  }
  .offset-md-82 {
    margin-left: 68.333333%;
  }
  .offset-md-83 {
    margin-left: 69.166667%;
  }
  .offset-md-84 {
    margin-left: 70%;
  }
  .offset-md-85 {
    margin-left: 70.833333%;
  }
  .offset-md-86 {
    margin-left: 71.666667%;
  }
  .offset-md-87 {
    margin-left: 72.5%;
  }
  .offset-md-88 {
    margin-left: 73.333333%;
  }
  .offset-md-89 {
    margin-left: 74.166667%;
  }
  .offset-md-90 {
    margin-left: 75%;
  }
  .offset-md-91 {
    margin-left: 75.833333%;
  }
  .offset-md-92 {
    margin-left: 76.666667%;
  }
  .offset-md-93 {
    margin-left: 77.5%;
  }
  .offset-md-94 {
    margin-left: 78.333333%;
  }
  .offset-md-95 {
    margin-left: 79.166667%;
  }
  .offset-md-96 {
    margin-left: 80%;
  }
  .offset-md-97 {
    margin-left: 80.833333%;
  }
  .offset-md-98 {
    margin-left: 81.666667%;
  }
  .offset-md-99 {
    margin-left: 82.5%;
  }
  .offset-md-100 {
    margin-left: 83.333333%;
  }
  .offset-md-101 {
    margin-left: 84.166667%;
  }
  .offset-md-102 {
    margin-left: 85%;
  }
  .offset-md-103 {
    margin-left: 85.833333%;
  }
  .offset-md-104 {
    margin-left: 86.666667%;
  }
  .offset-md-105 {
    margin-left: 87.5%;
  }
  .offset-md-106 {
    margin-left: 88.333333%;
  }
  .offset-md-107 {
    margin-left: 89.166667%;
  }
  .offset-md-108 {
    margin-left: 90%;
  }
  .offset-md-109 {
    margin-left: 90.833333%;
  }
  .offset-md-110 {
    margin-left: 91.666667%;
  }
  .offset-md-111 {
    margin-left: 92.5%;
  }
  .offset-md-112 {
    margin-left: 93.333333%;
  }
  .offset-md-113 {
    margin-left: 94.166667%;
  }
  .offset-md-114 {
    margin-left: 95%;
  }
  .offset-md-115 {
    margin-left: 95.833333%;
  }
  .offset-md-116 {
    margin-left: 96.666667%;
  }
  .offset-md-117 {
    margin-left: 97.5%;
  }
  .offset-md-118 {
    margin-left: 98.333333%;
  }
  .offset-md-119 {
    margin-left: 99.166667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .row-cols-lg-7 > * {
    flex: 0 0 14.285714%;
    max-width: 14.285714%;
  }
  .row-cols-lg-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .row-cols-lg-9 > * {
    flex: 0 0 11.111111%;
    max-width: 11.111111%;
  }
  .row-cols-lg-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .row-cols-lg-11 > * {
    flex: 0 0 9.090909%;
    max-width: 9.090909%;
  }
  .row-cols-lg-12 > * {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 0.833333%;
    max-width: 0.833333%;
  }
  .col-lg-2 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-lg-3 {
    flex: 0 0 2.5%;
    max-width: 2.5%;
  }
  .col-lg-4 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-lg-5 {
    flex: 0 0 4.166667%;
    max-width: 4.166667%;
  }
  .col-lg-6 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-lg-7 {
    flex: 0 0 5.833333%;
    max-width: 5.833333%;
  }
  .col-lg-8 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-lg-9 {
    flex: 0 0 7.5%;
    max-width: 7.5%;
  }
  .col-lg-10 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-11 {
    flex: 0 0 9.166667%;
    max-width: 9.166667%;
  }
  .col-lg-12 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-lg-13 {
    flex: 0 0 10.833333%;
    max-width: 10.833333%;
  }
  .col-lg-14 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-lg-15 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-lg-16 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-lg-17 {
    flex: 0 0 14.166667%;
    max-width: 14.166667%;
  }
  .col-lg-18 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-lg-19 {
    flex: 0 0 15.833333%;
    max-width: 15.833333%;
  }
  .col-lg-20 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-21 {
    flex: 0 0 17.5%;
    max-width: 17.5%;
  }
  .col-lg-22 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-lg-23 {
    flex: 0 0 19.166667%;
    max-width: 19.166667%;
  }
  .col-lg-24 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-lg-25 {
    flex: 0 0 20.833333%;
    max-width: 20.833333%;
  }
  .col-lg-26 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-lg-27 {
    flex: 0 0 22.5%;
    max-width: 22.5%;
  }
  .col-lg-28 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-lg-29 {
    flex: 0 0 24.166667%;
    max-width: 24.166667%;
  }
  .col-lg-30 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-31 {
    flex: 0 0 25.833333%;
    max-width: 25.833333%;
  }
  .col-lg-32 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-lg-33 {
    flex: 0 0 27.5%;
    max-width: 27.5%;
  }
  .col-lg-34 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-lg-35 {
    flex: 0 0 29.166667%;
    max-width: 29.166667%;
  }
  .col-lg-36 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-lg-37 {
    flex: 0 0 30.833333%;
    max-width: 30.833333%;
  }
  .col-lg-38 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-lg-39 {
    flex: 0 0 32.5%;
    max-width: 32.5%;
  }
  .col-lg-40 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-41 {
    flex: 0 0 34.166667%;
    max-width: 34.166667%;
  }
  .col-lg-42 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-lg-43 {
    flex: 0 0 35.833333%;
    max-width: 35.833333%;
  }
  .col-lg-44 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-lg-45 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-lg-46 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-lg-47 {
    flex: 0 0 39.166667%;
    max-width: 39.166667%;
  }
  .col-lg-48 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-lg-49 {
    flex: 0 0 40.833333%;
    max-width: 40.833333%;
  }
  .col-lg-50 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-51 {
    flex: 0 0 42.5%;
    max-width: 42.5%;
  }
  .col-lg-52 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-lg-53 {
    flex: 0 0 44.166667%;
    max-width: 44.166667%;
  }
  .col-lg-54 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-lg-55 {
    flex: 0 0 45.833333%;
    max-width: 45.833333%;
  }
  .col-lg-56 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-lg-57 {
    flex: 0 0 47.5%;
    max-width: 47.5%;
  }
  .col-lg-58 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-lg-59 {
    flex: 0 0 49.166667%;
    max-width: 49.166667%;
  }
  .col-lg-60 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-61 {
    flex: 0 0 50.833333%;
    max-width: 50.833333%;
  }
  .col-lg-62 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-lg-63 {
    flex: 0 0 52.5%;
    max-width: 52.5%;
  }
  .col-lg-64 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-lg-65 {
    flex: 0 0 54.166667%;
    max-width: 54.166667%;
  }
  .col-lg-66 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-lg-67 {
    flex: 0 0 55.833333%;
    max-width: 55.833333%;
  }
  .col-lg-68 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-lg-69 {
    flex: 0 0 57.5%;
    max-width: 57.5%;
  }
  .col-lg-70 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-71 {
    flex: 0 0 59.166667%;
    max-width: 59.166667%;
  }
  .col-lg-72 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-lg-73 {
    flex: 0 0 60.833333%;
    max-width: 60.833333%;
  }
  .col-lg-74 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-lg-75 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-lg-76 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-lg-77 {
    flex: 0 0 64.166667%;
    max-width: 64.166667%;
  }
  .col-lg-78 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-lg-79 {
    flex: 0 0 65.833333%;
    max-width: 65.833333%;
  }
  .col-lg-80 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-81 {
    flex: 0 0 67.5%;
    max-width: 67.5%;
  }
  .col-lg-82 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-lg-83 {
    flex: 0 0 69.166667%;
    max-width: 69.166667%;
  }
  .col-lg-84 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-lg-85 {
    flex: 0 0 70.833333%;
    max-width: 70.833333%;
  }
  .col-lg-86 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-lg-87 {
    flex: 0 0 72.5%;
    max-width: 72.5%;
  }
  .col-lg-88 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-lg-89 {
    flex: 0 0 74.166667%;
    max-width: 74.166667%;
  }
  .col-lg-90 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-91 {
    flex: 0 0 75.833333%;
    max-width: 75.833333%;
  }
  .col-lg-92 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-lg-93 {
    flex: 0 0 77.5%;
    max-width: 77.5%;
  }
  .col-lg-94 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-lg-95 {
    flex: 0 0 79.166667%;
    max-width: 79.166667%;
  }
  .col-lg-96 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-lg-97 {
    flex: 0 0 80.833333%;
    max-width: 80.833333%;
  }
  .col-lg-98 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-lg-99 {
    flex: 0 0 82.5%;
    max-width: 82.5%;
  }
  .col-lg-100 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-101 {
    flex: 0 0 84.166667%;
    max-width: 84.166667%;
  }
  .col-lg-102 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-lg-103 {
    flex: 0 0 85.833333%;
    max-width: 85.833333%;
  }
  .col-lg-104 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-lg-105 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-lg-106 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-lg-107 {
    flex: 0 0 89.166667%;
    max-width: 89.166667%;
  }
  .col-lg-108 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-lg-109 {
    flex: 0 0 90.833333%;
    max-width: 90.833333%;
  }
  .col-lg-110 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-111 {
    flex: 0 0 92.5%;
    max-width: 92.5%;
  }
  .col-lg-112 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-lg-113 {
    flex: 0 0 94.166667%;
    max-width: 94.166667%;
  }
  .col-lg-114 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-lg-115 {
    flex: 0 0 95.833333%;
    max-width: 95.833333%;
  }
  .col-lg-116 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-lg-117 {
    flex: 0 0 97.5%;
    max-width: 97.5%;
  }
  .col-lg-118 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-lg-119 {
    flex: 0 0 99.166667%;
    max-width: 99.166667%;
  }
  .col-lg-120 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 121;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .order-lg-13 {
    order: 13;
  }
  .order-lg-14 {
    order: 14;
  }
  .order-lg-15 {
    order: 15;
  }
  .order-lg-16 {
    order: 16;
  }
  .order-lg-17 {
    order: 17;
  }
  .order-lg-18 {
    order: 18;
  }
  .order-lg-19 {
    order: 19;
  }
  .order-lg-20 {
    order: 20;
  }
  .order-lg-21 {
    order: 21;
  }
  .order-lg-22 {
    order: 22;
  }
  .order-lg-23 {
    order: 23;
  }
  .order-lg-24 {
    order: 24;
  }
  .order-lg-25 {
    order: 25;
  }
  .order-lg-26 {
    order: 26;
  }
  .order-lg-27 {
    order: 27;
  }
  .order-lg-28 {
    order: 28;
  }
  .order-lg-29 {
    order: 29;
  }
  .order-lg-30 {
    order: 30;
  }
  .order-lg-31 {
    order: 31;
  }
  .order-lg-32 {
    order: 32;
  }
  .order-lg-33 {
    order: 33;
  }
  .order-lg-34 {
    order: 34;
  }
  .order-lg-35 {
    order: 35;
  }
  .order-lg-36 {
    order: 36;
  }
  .order-lg-37 {
    order: 37;
  }
  .order-lg-38 {
    order: 38;
  }
  .order-lg-39 {
    order: 39;
  }
  .order-lg-40 {
    order: 40;
  }
  .order-lg-41 {
    order: 41;
  }
  .order-lg-42 {
    order: 42;
  }
  .order-lg-43 {
    order: 43;
  }
  .order-lg-44 {
    order: 44;
  }
  .order-lg-45 {
    order: 45;
  }
  .order-lg-46 {
    order: 46;
  }
  .order-lg-47 {
    order: 47;
  }
  .order-lg-48 {
    order: 48;
  }
  .order-lg-49 {
    order: 49;
  }
  .order-lg-50 {
    order: 50;
  }
  .order-lg-51 {
    order: 51;
  }
  .order-lg-52 {
    order: 52;
  }
  .order-lg-53 {
    order: 53;
  }
  .order-lg-54 {
    order: 54;
  }
  .order-lg-55 {
    order: 55;
  }
  .order-lg-56 {
    order: 56;
  }
  .order-lg-57 {
    order: 57;
  }
  .order-lg-58 {
    order: 58;
  }
  .order-lg-59 {
    order: 59;
  }
  .order-lg-60 {
    order: 60;
  }
  .order-lg-61 {
    order: 61;
  }
  .order-lg-62 {
    order: 62;
  }
  .order-lg-63 {
    order: 63;
  }
  .order-lg-64 {
    order: 64;
  }
  .order-lg-65 {
    order: 65;
  }
  .order-lg-66 {
    order: 66;
  }
  .order-lg-67 {
    order: 67;
  }
  .order-lg-68 {
    order: 68;
  }
  .order-lg-69 {
    order: 69;
  }
  .order-lg-70 {
    order: 70;
  }
  .order-lg-71 {
    order: 71;
  }
  .order-lg-72 {
    order: 72;
  }
  .order-lg-73 {
    order: 73;
  }
  .order-lg-74 {
    order: 74;
  }
  .order-lg-75 {
    order: 75;
  }
  .order-lg-76 {
    order: 76;
  }
  .order-lg-77 {
    order: 77;
  }
  .order-lg-78 {
    order: 78;
  }
  .order-lg-79 {
    order: 79;
  }
  .order-lg-80 {
    order: 80;
  }
  .order-lg-81 {
    order: 81;
  }
  .order-lg-82 {
    order: 82;
  }
  .order-lg-83 {
    order: 83;
  }
  .order-lg-84 {
    order: 84;
  }
  .order-lg-85 {
    order: 85;
  }
  .order-lg-86 {
    order: 86;
  }
  .order-lg-87 {
    order: 87;
  }
  .order-lg-88 {
    order: 88;
  }
  .order-lg-89 {
    order: 89;
  }
  .order-lg-90 {
    order: 90;
  }
  .order-lg-91 {
    order: 91;
  }
  .order-lg-92 {
    order: 92;
  }
  .order-lg-93 {
    order: 93;
  }
  .order-lg-94 {
    order: 94;
  }
  .order-lg-95 {
    order: 95;
  }
  .order-lg-96 {
    order: 96;
  }
  .order-lg-97 {
    order: 97;
  }
  .order-lg-98 {
    order: 98;
  }
  .order-lg-99 {
    order: 99;
  }
  .order-lg-100 {
    order: 100;
  }
  .order-lg-101 {
    order: 101;
  }
  .order-lg-102 {
    order: 102;
  }
  .order-lg-103 {
    order: 103;
  }
  .order-lg-104 {
    order: 104;
  }
  .order-lg-105 {
    order: 105;
  }
  .order-lg-106 {
    order: 106;
  }
  .order-lg-107 {
    order: 107;
  }
  .order-lg-108 {
    order: 108;
  }
  .order-lg-109 {
    order: 109;
  }
  .order-lg-110 {
    order: 110;
  }
  .order-lg-111 {
    order: 111;
  }
  .order-lg-112 {
    order: 112;
  }
  .order-lg-113 {
    order: 113;
  }
  .order-lg-114 {
    order: 114;
  }
  .order-lg-115 {
    order: 115;
  }
  .order-lg-116 {
    order: 116;
  }
  .order-lg-117 {
    order: 117;
  }
  .order-lg-118 {
    order: 118;
  }
  .order-lg-119 {
    order: 119;
  }
  .order-lg-120 {
    order: 120;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 0.833333%;
  }
  .offset-lg-2 {
    margin-left: 1.666667%;
  }
  .offset-lg-3 {
    margin-left: 2.5%;
  }
  .offset-lg-4 {
    margin-left: 3.333333%;
  }
  .offset-lg-5 {
    margin-left: 4.166667%;
  }
  .offset-lg-6 {
    margin-left: 5%;
  }
  .offset-lg-7 {
    margin-left: 5.833333%;
  }
  .offset-lg-8 {
    margin-left: 6.666667%;
  }
  .offset-lg-9 {
    margin-left: 7.5%;
  }
  .offset-lg-10 {
    margin-left: 8.333333%;
  }
  .offset-lg-11 {
    margin-left: 9.166667%;
  }
  .offset-lg-12 {
    margin-left: 10%;
  }
  .offset-lg-13 {
    margin-left: 10.833333%;
  }
  .offset-lg-14 {
    margin-left: 11.666667%;
  }
  .offset-lg-15 {
    margin-left: 12.5%;
  }
  .offset-lg-16 {
    margin-left: 13.333333%;
  }
  .offset-lg-17 {
    margin-left: 14.166667%;
  }
  .offset-lg-18 {
    margin-left: 15%;
  }
  .offset-lg-19 {
    margin-left: 15.833333%;
  }
  .offset-lg-20 {
    margin-left: 16.666667%;
  }
  .offset-lg-21 {
    margin-left: 17.5%;
  }
  .offset-lg-22 {
    margin-left: 18.333333%;
  }
  .offset-lg-23 {
    margin-left: 19.166667%;
  }
  .offset-lg-24 {
    margin-left: 20%;
  }
  .offset-lg-25 {
    margin-left: 20.833333%;
  }
  .offset-lg-26 {
    margin-left: 21.666667%;
  }
  .offset-lg-27 {
    margin-left: 22.5%;
  }
  .offset-lg-28 {
    margin-left: 23.333333%;
  }
  .offset-lg-29 {
    margin-left: 24.166667%;
  }
  .offset-lg-30 {
    margin-left: 25%;
  }
  .offset-lg-31 {
    margin-left: 25.833333%;
  }
  .offset-lg-32 {
    margin-left: 26.666667%;
  }
  .offset-lg-33 {
    margin-left: 27.5%;
  }
  .offset-lg-34 {
    margin-left: 28.333333%;
  }
  .offset-lg-35 {
    margin-left: 29.166667%;
  }
  .offset-lg-36 {
    margin-left: 30%;
  }
  .offset-lg-37 {
    margin-left: 30.833333%;
  }
  .offset-lg-38 {
    margin-left: 31.666667%;
  }
  .offset-lg-39 {
    margin-left: 32.5%;
  }
  .offset-lg-40 {
    margin-left: 33.333333%;
  }
  .offset-lg-41 {
    margin-left: 34.166667%;
  }
  .offset-lg-42 {
    margin-left: 35%;
  }
  .offset-lg-43 {
    margin-left: 35.833333%;
  }
  .offset-lg-44 {
    margin-left: 36.666667%;
  }
  .offset-lg-45 {
    margin-left: 37.5%;
  }
  .offset-lg-46 {
    margin-left: 38.333333%;
  }
  .offset-lg-47 {
    margin-left: 39.166667%;
  }
  .offset-lg-48 {
    margin-left: 40%;
  }
  .offset-lg-49 {
    margin-left: 40.833333%;
  }
  .offset-lg-50 {
    margin-left: 41.666667%;
  }
  .offset-lg-51 {
    margin-left: 42.5%;
  }
  .offset-lg-52 {
    margin-left: 43.333333%;
  }
  .offset-lg-53 {
    margin-left: 44.166667%;
  }
  .offset-lg-54 {
    margin-left: 45%;
  }
  .offset-lg-55 {
    margin-left: 45.833333%;
  }
  .offset-lg-56 {
    margin-left: 46.666667%;
  }
  .offset-lg-57 {
    margin-left: 47.5%;
  }
  .offset-lg-58 {
    margin-left: 48.333333%;
  }
  .offset-lg-59 {
    margin-left: 49.166667%;
  }
  .offset-lg-60 {
    margin-left: 50%;
  }
  .offset-lg-61 {
    margin-left: 50.833333%;
  }
  .offset-lg-62 {
    margin-left: 51.666667%;
  }
  .offset-lg-63 {
    margin-left: 52.5%;
  }
  .offset-lg-64 {
    margin-left: 53.333333%;
  }
  .offset-lg-65 {
    margin-left: 54.166667%;
  }
  .offset-lg-66 {
    margin-left: 55%;
  }
  .offset-lg-67 {
    margin-left: 55.833333%;
  }
  .offset-lg-68 {
    margin-left: 56.666667%;
  }
  .offset-lg-69 {
    margin-left: 57.5%;
  }
  .offset-lg-70 {
    margin-left: 58.333333%;
  }
  .offset-lg-71 {
    margin-left: 59.166667%;
  }
  .offset-lg-72 {
    margin-left: 60%;
  }
  .offset-lg-73 {
    margin-left: 60.833333%;
  }
  .offset-lg-74 {
    margin-left: 61.666667%;
  }
  .offset-lg-75 {
    margin-left: 62.5%;
  }
  .offset-lg-76 {
    margin-left: 63.333333%;
  }
  .offset-lg-77 {
    margin-left: 64.166667%;
  }
  .offset-lg-78 {
    margin-left: 65%;
  }
  .offset-lg-79 {
    margin-left: 65.833333%;
  }
  .offset-lg-80 {
    margin-left: 66.666667%;
  }
  .offset-lg-81 {
    margin-left: 67.5%;
  }
  .offset-lg-82 {
    margin-left: 68.333333%;
  }
  .offset-lg-83 {
    margin-left: 69.166667%;
  }
  .offset-lg-84 {
    margin-left: 70%;
  }
  .offset-lg-85 {
    margin-left: 70.833333%;
  }
  .offset-lg-86 {
    margin-left: 71.666667%;
  }
  .offset-lg-87 {
    margin-left: 72.5%;
  }
  .offset-lg-88 {
    margin-left: 73.333333%;
  }
  .offset-lg-89 {
    margin-left: 74.166667%;
  }
  .offset-lg-90 {
    margin-left: 75%;
  }
  .offset-lg-91 {
    margin-left: 75.833333%;
  }
  .offset-lg-92 {
    margin-left: 76.666667%;
  }
  .offset-lg-93 {
    margin-left: 77.5%;
  }
  .offset-lg-94 {
    margin-left: 78.333333%;
  }
  .offset-lg-95 {
    margin-left: 79.166667%;
  }
  .offset-lg-96 {
    margin-left: 80%;
  }
  .offset-lg-97 {
    margin-left: 80.833333%;
  }
  .offset-lg-98 {
    margin-left: 81.666667%;
  }
  .offset-lg-99 {
    margin-left: 82.5%;
  }
  .offset-lg-100 {
    margin-left: 83.333333%;
  }
  .offset-lg-101 {
    margin-left: 84.166667%;
  }
  .offset-lg-102 {
    margin-left: 85%;
  }
  .offset-lg-103 {
    margin-left: 85.833333%;
  }
  .offset-lg-104 {
    margin-left: 86.666667%;
  }
  .offset-lg-105 {
    margin-left: 87.5%;
  }
  .offset-lg-106 {
    margin-left: 88.333333%;
  }
  .offset-lg-107 {
    margin-left: 89.166667%;
  }
  .offset-lg-108 {
    margin-left: 90%;
  }
  .offset-lg-109 {
    margin-left: 90.833333%;
  }
  .offset-lg-110 {
    margin-left: 91.666667%;
  }
  .offset-lg-111 {
    margin-left: 92.5%;
  }
  .offset-lg-112 {
    margin-left: 93.333333%;
  }
  .offset-lg-113 {
    margin-left: 94.166667%;
  }
  .offset-lg-114 {
    margin-left: 95%;
  }
  .offset-lg-115 {
    margin-left: 95.833333%;
  }
  .offset-lg-116 {
    margin-left: 96.666667%;
  }
  .offset-lg-117 {
    margin-left: 97.5%;
  }
  .offset-lg-118 {
    margin-left: 98.333333%;
  }
  .offset-lg-119 {
    margin-left: 99.166667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .row-cols-xl-7 > * {
    flex: 0 0 14.285714%;
    max-width: 14.285714%;
  }
  .row-cols-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .row-cols-xl-9 > * {
    flex: 0 0 11.111111%;
    max-width: 11.111111%;
  }
  .row-cols-xl-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .row-cols-xl-11 > * {
    flex: 0 0 9.090909%;
    max-width: 9.090909%;
  }
  .row-cols-xl-12 > * {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 0.833333%;
    max-width: 0.833333%;
  }
  .col-xl-2 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-xl-3 {
    flex: 0 0 2.5%;
    max-width: 2.5%;
  }
  .col-xl-4 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-xl-5 {
    flex: 0 0 4.166667%;
    max-width: 4.166667%;
  }
  .col-xl-6 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-xl-7 {
    flex: 0 0 5.833333%;
    max-width: 5.833333%;
  }
  .col-xl-8 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-xl-9 {
    flex: 0 0 7.5%;
    max-width: 7.5%;
  }
  .col-xl-10 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-11 {
    flex: 0 0 9.166667%;
    max-width: 9.166667%;
  }
  .col-xl-12 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-xl-13 {
    flex: 0 0 10.833333%;
    max-width: 10.833333%;
  }
  .col-xl-14 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-xl-15 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-xl-16 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-xl-17 {
    flex: 0 0 14.166667%;
    max-width: 14.166667%;
  }
  .col-xl-18 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-xl-19 {
    flex: 0 0 15.833333%;
    max-width: 15.833333%;
  }
  .col-xl-20 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-21 {
    flex: 0 0 17.5%;
    max-width: 17.5%;
  }
  .col-xl-22 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-xl-23 {
    flex: 0 0 19.166667%;
    max-width: 19.166667%;
  }
  .col-xl-24 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xl-25 {
    flex: 0 0 20.833333%;
    max-width: 20.833333%;
  }
  .col-xl-26 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-xl-27 {
    flex: 0 0 22.5%;
    max-width: 22.5%;
  }
  .col-xl-28 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-xl-29 {
    flex: 0 0 24.166667%;
    max-width: 24.166667%;
  }
  .col-xl-30 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-31 {
    flex: 0 0 25.833333%;
    max-width: 25.833333%;
  }
  .col-xl-32 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-xl-33 {
    flex: 0 0 27.5%;
    max-width: 27.5%;
  }
  .col-xl-34 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-xl-35 {
    flex: 0 0 29.166667%;
    max-width: 29.166667%;
  }
  .col-xl-36 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-xl-37 {
    flex: 0 0 30.833333%;
    max-width: 30.833333%;
  }
  .col-xl-38 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-xl-39 {
    flex: 0 0 32.5%;
    max-width: 32.5%;
  }
  .col-xl-40 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-41 {
    flex: 0 0 34.166667%;
    max-width: 34.166667%;
  }
  .col-xl-42 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-xl-43 {
    flex: 0 0 35.833333%;
    max-width: 35.833333%;
  }
  .col-xl-44 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-xl-45 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-xl-46 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-xl-47 {
    flex: 0 0 39.166667%;
    max-width: 39.166667%;
  }
  .col-xl-48 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-xl-49 {
    flex: 0 0 40.833333%;
    max-width: 40.833333%;
  }
  .col-xl-50 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-51 {
    flex: 0 0 42.5%;
    max-width: 42.5%;
  }
  .col-xl-52 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-xl-53 {
    flex: 0 0 44.166667%;
    max-width: 44.166667%;
  }
  .col-xl-54 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-xl-55 {
    flex: 0 0 45.833333%;
    max-width: 45.833333%;
  }
  .col-xl-56 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-xl-57 {
    flex: 0 0 47.5%;
    max-width: 47.5%;
  }
  .col-xl-58 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-xl-59 {
    flex: 0 0 49.166667%;
    max-width: 49.166667%;
  }
  .col-xl-60 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-61 {
    flex: 0 0 50.833333%;
    max-width: 50.833333%;
  }
  .col-xl-62 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-xl-63 {
    flex: 0 0 52.5%;
    max-width: 52.5%;
  }
  .col-xl-64 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-xl-65 {
    flex: 0 0 54.166667%;
    max-width: 54.166667%;
  }
  .col-xl-66 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-xl-67 {
    flex: 0 0 55.833333%;
    max-width: 55.833333%;
  }
  .col-xl-68 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-xl-69 {
    flex: 0 0 57.5%;
    max-width: 57.5%;
  }
  .col-xl-70 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-71 {
    flex: 0 0 59.166667%;
    max-width: 59.166667%;
  }
  .col-xl-72 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-xl-73 {
    flex: 0 0 60.833333%;
    max-width: 60.833333%;
  }
  .col-xl-74 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-xl-75 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-xl-76 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-xl-77 {
    flex: 0 0 64.166667%;
    max-width: 64.166667%;
  }
  .col-xl-78 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-xl-79 {
    flex: 0 0 65.833333%;
    max-width: 65.833333%;
  }
  .col-xl-80 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-81 {
    flex: 0 0 67.5%;
    max-width: 67.5%;
  }
  .col-xl-82 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-xl-83 {
    flex: 0 0 69.166667%;
    max-width: 69.166667%;
  }
  .col-xl-84 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-xl-85 {
    flex: 0 0 70.833333%;
    max-width: 70.833333%;
  }
  .col-xl-86 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-xl-87 {
    flex: 0 0 72.5%;
    max-width: 72.5%;
  }
  .col-xl-88 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-xl-89 {
    flex: 0 0 74.166667%;
    max-width: 74.166667%;
  }
  .col-xl-90 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-91 {
    flex: 0 0 75.833333%;
    max-width: 75.833333%;
  }
  .col-xl-92 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-xl-93 {
    flex: 0 0 77.5%;
    max-width: 77.5%;
  }
  .col-xl-94 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-xl-95 {
    flex: 0 0 79.166667%;
    max-width: 79.166667%;
  }
  .col-xl-96 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-xl-97 {
    flex: 0 0 80.833333%;
    max-width: 80.833333%;
  }
  .col-xl-98 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-xl-99 {
    flex: 0 0 82.5%;
    max-width: 82.5%;
  }
  .col-xl-100 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-101 {
    flex: 0 0 84.166667%;
    max-width: 84.166667%;
  }
  .col-xl-102 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-xl-103 {
    flex: 0 0 85.833333%;
    max-width: 85.833333%;
  }
  .col-xl-104 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-xl-105 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-xl-106 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-xl-107 {
    flex: 0 0 89.166667%;
    max-width: 89.166667%;
  }
  .col-xl-108 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-xl-109 {
    flex: 0 0 90.833333%;
    max-width: 90.833333%;
  }
  .col-xl-110 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-111 {
    flex: 0 0 92.5%;
    max-width: 92.5%;
  }
  .col-xl-112 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-xl-113 {
    flex: 0 0 94.166667%;
    max-width: 94.166667%;
  }
  .col-xl-114 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-xl-115 {
    flex: 0 0 95.833333%;
    max-width: 95.833333%;
  }
  .col-xl-116 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-xl-117 {
    flex: 0 0 97.5%;
    max-width: 97.5%;
  }
  .col-xl-118 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-xl-119 {
    flex: 0 0 99.166667%;
    max-width: 99.166667%;
  }
  .col-xl-120 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 121;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .order-xl-13 {
    order: 13;
  }
  .order-xl-14 {
    order: 14;
  }
  .order-xl-15 {
    order: 15;
  }
  .order-xl-16 {
    order: 16;
  }
  .order-xl-17 {
    order: 17;
  }
  .order-xl-18 {
    order: 18;
  }
  .order-xl-19 {
    order: 19;
  }
  .order-xl-20 {
    order: 20;
  }
  .order-xl-21 {
    order: 21;
  }
  .order-xl-22 {
    order: 22;
  }
  .order-xl-23 {
    order: 23;
  }
  .order-xl-24 {
    order: 24;
  }
  .order-xl-25 {
    order: 25;
  }
  .order-xl-26 {
    order: 26;
  }
  .order-xl-27 {
    order: 27;
  }
  .order-xl-28 {
    order: 28;
  }
  .order-xl-29 {
    order: 29;
  }
  .order-xl-30 {
    order: 30;
  }
  .order-xl-31 {
    order: 31;
  }
  .order-xl-32 {
    order: 32;
  }
  .order-xl-33 {
    order: 33;
  }
  .order-xl-34 {
    order: 34;
  }
  .order-xl-35 {
    order: 35;
  }
  .order-xl-36 {
    order: 36;
  }
  .order-xl-37 {
    order: 37;
  }
  .order-xl-38 {
    order: 38;
  }
  .order-xl-39 {
    order: 39;
  }
  .order-xl-40 {
    order: 40;
  }
  .order-xl-41 {
    order: 41;
  }
  .order-xl-42 {
    order: 42;
  }
  .order-xl-43 {
    order: 43;
  }
  .order-xl-44 {
    order: 44;
  }
  .order-xl-45 {
    order: 45;
  }
  .order-xl-46 {
    order: 46;
  }
  .order-xl-47 {
    order: 47;
  }
  .order-xl-48 {
    order: 48;
  }
  .order-xl-49 {
    order: 49;
  }
  .order-xl-50 {
    order: 50;
  }
  .order-xl-51 {
    order: 51;
  }
  .order-xl-52 {
    order: 52;
  }
  .order-xl-53 {
    order: 53;
  }
  .order-xl-54 {
    order: 54;
  }
  .order-xl-55 {
    order: 55;
  }
  .order-xl-56 {
    order: 56;
  }
  .order-xl-57 {
    order: 57;
  }
  .order-xl-58 {
    order: 58;
  }
  .order-xl-59 {
    order: 59;
  }
  .order-xl-60 {
    order: 60;
  }
  .order-xl-61 {
    order: 61;
  }
  .order-xl-62 {
    order: 62;
  }
  .order-xl-63 {
    order: 63;
  }
  .order-xl-64 {
    order: 64;
  }
  .order-xl-65 {
    order: 65;
  }
  .order-xl-66 {
    order: 66;
  }
  .order-xl-67 {
    order: 67;
  }
  .order-xl-68 {
    order: 68;
  }
  .order-xl-69 {
    order: 69;
  }
  .order-xl-70 {
    order: 70;
  }
  .order-xl-71 {
    order: 71;
  }
  .order-xl-72 {
    order: 72;
  }
  .order-xl-73 {
    order: 73;
  }
  .order-xl-74 {
    order: 74;
  }
  .order-xl-75 {
    order: 75;
  }
  .order-xl-76 {
    order: 76;
  }
  .order-xl-77 {
    order: 77;
  }
  .order-xl-78 {
    order: 78;
  }
  .order-xl-79 {
    order: 79;
  }
  .order-xl-80 {
    order: 80;
  }
  .order-xl-81 {
    order: 81;
  }
  .order-xl-82 {
    order: 82;
  }
  .order-xl-83 {
    order: 83;
  }
  .order-xl-84 {
    order: 84;
  }
  .order-xl-85 {
    order: 85;
  }
  .order-xl-86 {
    order: 86;
  }
  .order-xl-87 {
    order: 87;
  }
  .order-xl-88 {
    order: 88;
  }
  .order-xl-89 {
    order: 89;
  }
  .order-xl-90 {
    order: 90;
  }
  .order-xl-91 {
    order: 91;
  }
  .order-xl-92 {
    order: 92;
  }
  .order-xl-93 {
    order: 93;
  }
  .order-xl-94 {
    order: 94;
  }
  .order-xl-95 {
    order: 95;
  }
  .order-xl-96 {
    order: 96;
  }
  .order-xl-97 {
    order: 97;
  }
  .order-xl-98 {
    order: 98;
  }
  .order-xl-99 {
    order: 99;
  }
  .order-xl-100 {
    order: 100;
  }
  .order-xl-101 {
    order: 101;
  }
  .order-xl-102 {
    order: 102;
  }
  .order-xl-103 {
    order: 103;
  }
  .order-xl-104 {
    order: 104;
  }
  .order-xl-105 {
    order: 105;
  }
  .order-xl-106 {
    order: 106;
  }
  .order-xl-107 {
    order: 107;
  }
  .order-xl-108 {
    order: 108;
  }
  .order-xl-109 {
    order: 109;
  }
  .order-xl-110 {
    order: 110;
  }
  .order-xl-111 {
    order: 111;
  }
  .order-xl-112 {
    order: 112;
  }
  .order-xl-113 {
    order: 113;
  }
  .order-xl-114 {
    order: 114;
  }
  .order-xl-115 {
    order: 115;
  }
  .order-xl-116 {
    order: 116;
  }
  .order-xl-117 {
    order: 117;
  }
  .order-xl-118 {
    order: 118;
  }
  .order-xl-119 {
    order: 119;
  }
  .order-xl-120 {
    order: 120;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 0.833333%;
  }
  .offset-xl-2 {
    margin-left: 1.666667%;
  }
  .offset-xl-3 {
    margin-left: 2.5%;
  }
  .offset-xl-4 {
    margin-left: 3.333333%;
  }
  .offset-xl-5 {
    margin-left: 4.166667%;
  }
  .offset-xl-6 {
    margin-left: 5%;
  }
  .offset-xl-7 {
    margin-left: 5.833333%;
  }
  .offset-xl-8 {
    margin-left: 6.666667%;
  }
  .offset-xl-9 {
    margin-left: 7.5%;
  }
  .offset-xl-10 {
    margin-left: 8.333333%;
  }
  .offset-xl-11 {
    margin-left: 9.166667%;
  }
  .offset-xl-12 {
    margin-left: 10%;
  }
  .offset-xl-13 {
    margin-left: 10.833333%;
  }
  .offset-xl-14 {
    margin-left: 11.666667%;
  }
  .offset-xl-15 {
    margin-left: 12.5%;
  }
  .offset-xl-16 {
    margin-left: 13.333333%;
  }
  .offset-xl-17 {
    margin-left: 14.166667%;
  }
  .offset-xl-18 {
    margin-left: 15%;
  }
  .offset-xl-19 {
    margin-left: 15.833333%;
  }
  .offset-xl-20 {
    margin-left: 16.666667%;
  }
  .offset-xl-21 {
    margin-left: 17.5%;
  }
  .offset-xl-22 {
    margin-left: 18.333333%;
  }
  .offset-xl-23 {
    margin-left: 19.166667%;
  }
  .offset-xl-24 {
    margin-left: 20%;
  }
  .offset-xl-25 {
    margin-left: 20.833333%;
  }
  .offset-xl-26 {
    margin-left: 21.666667%;
  }
  .offset-xl-27 {
    margin-left: 22.5%;
  }
  .offset-xl-28 {
    margin-left: 23.333333%;
  }
  .offset-xl-29 {
    margin-left: 24.166667%;
  }
  .offset-xl-30 {
    margin-left: 25%;
  }
  .offset-xl-31 {
    margin-left: 25.833333%;
  }
  .offset-xl-32 {
    margin-left: 26.666667%;
  }
  .offset-xl-33 {
    margin-left: 27.5%;
  }
  .offset-xl-34 {
    margin-left: 28.333333%;
  }
  .offset-xl-35 {
    margin-left: 29.166667%;
  }
  .offset-xl-36 {
    margin-left: 30%;
  }
  .offset-xl-37 {
    margin-left: 30.833333%;
  }
  .offset-xl-38 {
    margin-left: 31.666667%;
  }
  .offset-xl-39 {
    margin-left: 32.5%;
  }
  .offset-xl-40 {
    margin-left: 33.333333%;
  }
  .offset-xl-41 {
    margin-left: 34.166667%;
  }
  .offset-xl-42 {
    margin-left: 35%;
  }
  .offset-xl-43 {
    margin-left: 35.833333%;
  }
  .offset-xl-44 {
    margin-left: 36.666667%;
  }
  .offset-xl-45 {
    margin-left: 37.5%;
  }
  .offset-xl-46 {
    margin-left: 38.333333%;
  }
  .offset-xl-47 {
    margin-left: 39.166667%;
  }
  .offset-xl-48 {
    margin-left: 40%;
  }
  .offset-xl-49 {
    margin-left: 40.833333%;
  }
  .offset-xl-50 {
    margin-left: 41.666667%;
  }
  .offset-xl-51 {
    margin-left: 42.5%;
  }
  .offset-xl-52 {
    margin-left: 43.333333%;
  }
  .offset-xl-53 {
    margin-left: 44.166667%;
  }
  .offset-xl-54 {
    margin-left: 45%;
  }
  .offset-xl-55 {
    margin-left: 45.833333%;
  }
  .offset-xl-56 {
    margin-left: 46.666667%;
  }
  .offset-xl-57 {
    margin-left: 47.5%;
  }
  .offset-xl-58 {
    margin-left: 48.333333%;
  }
  .offset-xl-59 {
    margin-left: 49.166667%;
  }
  .offset-xl-60 {
    margin-left: 50%;
  }
  .offset-xl-61 {
    margin-left: 50.833333%;
  }
  .offset-xl-62 {
    margin-left: 51.666667%;
  }
  .offset-xl-63 {
    margin-left: 52.5%;
  }
  .offset-xl-64 {
    margin-left: 53.333333%;
  }
  .offset-xl-65 {
    margin-left: 54.166667%;
  }
  .offset-xl-66 {
    margin-left: 55%;
  }
  .offset-xl-67 {
    margin-left: 55.833333%;
  }
  .offset-xl-68 {
    margin-left: 56.666667%;
  }
  .offset-xl-69 {
    margin-left: 57.5%;
  }
  .offset-xl-70 {
    margin-left: 58.333333%;
  }
  .offset-xl-71 {
    margin-left: 59.166667%;
  }
  .offset-xl-72 {
    margin-left: 60%;
  }
  .offset-xl-73 {
    margin-left: 60.833333%;
  }
  .offset-xl-74 {
    margin-left: 61.666667%;
  }
  .offset-xl-75 {
    margin-left: 62.5%;
  }
  .offset-xl-76 {
    margin-left: 63.333333%;
  }
  .offset-xl-77 {
    margin-left: 64.166667%;
  }
  .offset-xl-78 {
    margin-left: 65%;
  }
  .offset-xl-79 {
    margin-left: 65.833333%;
  }
  .offset-xl-80 {
    margin-left: 66.666667%;
  }
  .offset-xl-81 {
    margin-left: 67.5%;
  }
  .offset-xl-82 {
    margin-left: 68.333333%;
  }
  .offset-xl-83 {
    margin-left: 69.166667%;
  }
  .offset-xl-84 {
    margin-left: 70%;
  }
  .offset-xl-85 {
    margin-left: 70.833333%;
  }
  .offset-xl-86 {
    margin-left: 71.666667%;
  }
  .offset-xl-87 {
    margin-left: 72.5%;
  }
  .offset-xl-88 {
    margin-left: 73.333333%;
  }
  .offset-xl-89 {
    margin-left: 74.166667%;
  }
  .offset-xl-90 {
    margin-left: 75%;
  }
  .offset-xl-91 {
    margin-left: 75.833333%;
  }
  .offset-xl-92 {
    margin-left: 76.666667%;
  }
  .offset-xl-93 {
    margin-left: 77.5%;
  }
  .offset-xl-94 {
    margin-left: 78.333333%;
  }
  .offset-xl-95 {
    margin-left: 79.166667%;
  }
  .offset-xl-96 {
    margin-left: 80%;
  }
  .offset-xl-97 {
    margin-left: 80.833333%;
  }
  .offset-xl-98 {
    margin-left: 81.666667%;
  }
  .offset-xl-99 {
    margin-left: 82.5%;
  }
  .offset-xl-100 {
    margin-left: 83.333333%;
  }
  .offset-xl-101 {
    margin-left: 84.166667%;
  }
  .offset-xl-102 {
    margin-left: 85%;
  }
  .offset-xl-103 {
    margin-left: 85.833333%;
  }
  .offset-xl-104 {
    margin-left: 86.666667%;
  }
  .offset-xl-105 {
    margin-left: 87.5%;
  }
  .offset-xl-106 {
    margin-left: 88.333333%;
  }
  .offset-xl-107 {
    margin-left: 89.166667%;
  }
  .offset-xl-108 {
    margin-left: 90%;
  }
  .offset-xl-109 {
    margin-left: 90.833333%;
  }
  .offset-xl-110 {
    margin-left: 91.666667%;
  }
  .offset-xl-111 {
    margin-left: 92.5%;
  }
  .offset-xl-112 {
    margin-left: 93.333333%;
  }
  .offset-xl-113 {
    margin-left: 94.166667%;
  }
  .offset-xl-114 {
    margin-left: 95%;
  }
  .offset-xl-115 {
    margin-left: 95.833333%;
  }
  .offset-xl-116 {
    margin-left: 96.666667%;
  }
  .offset-xl-117 {
    margin-left: 97.5%;
  }
  .offset-xl-118 {
    margin-left: 98.333333%;
  }
  .offset-xl-119 {
    margin-left: 99.166667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .row-cols-xxl-7 > * {
    flex: 0 0 14.285714%;
    max-width: 14.285714%;
  }
  .row-cols-xxl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .row-cols-xxl-9 > * {
    flex: 0 0 11.111111%;
    max-width: 11.111111%;
  }
  .row-cols-xxl-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .row-cols-xxl-11 > * {
    flex: 0 0 9.090909%;
    max-width: 9.090909%;
  }
  .row-cols-xxl-12 > * {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 0.833333%;
    max-width: 0.833333%;
  }
  .col-xxl-2 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 2.5%;
    max-width: 2.5%;
  }
  .col-xxl-4 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 4.166667%;
    max-width: 4.166667%;
  }
  .col-xxl-6 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-xxl-7 {
    flex: 0 0 5.833333%;
    max-width: 5.833333%;
  }
  .col-xxl-8 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 7.5%;
    max-width: 7.5%;
  }
  .col-xxl-10 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 9.166667%;
    max-width: 9.166667%;
  }
  .col-xxl-12 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-xxl-13 {
    flex: 0 0 10.833333%;
    max-width: 10.833333%;
  }
  .col-xxl-14 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-xxl-15 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-xxl-16 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-xxl-17 {
    flex: 0 0 14.166667%;
    max-width: 14.166667%;
  }
  .col-xxl-18 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-xxl-19 {
    flex: 0 0 15.833333%;
    max-width: 15.833333%;
  }
  .col-xxl-20 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-21 {
    flex: 0 0 17.5%;
    max-width: 17.5%;
  }
  .col-xxl-22 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-xxl-23 {
    flex: 0 0 19.166667%;
    max-width: 19.166667%;
  }
  .col-xxl-24 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xxl-25 {
    flex: 0 0 20.833333%;
    max-width: 20.833333%;
  }
  .col-xxl-26 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-xxl-27 {
    flex: 0 0 22.5%;
    max-width: 22.5%;
  }
  .col-xxl-28 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-xxl-29 {
    flex: 0 0 24.166667%;
    max-width: 24.166667%;
  }
  .col-xxl-30 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-31 {
    flex: 0 0 25.833333%;
    max-width: 25.833333%;
  }
  .col-xxl-32 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-xxl-33 {
    flex: 0 0 27.5%;
    max-width: 27.5%;
  }
  .col-xxl-34 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-xxl-35 {
    flex: 0 0 29.166667%;
    max-width: 29.166667%;
  }
  .col-xxl-36 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-xxl-37 {
    flex: 0 0 30.833333%;
    max-width: 30.833333%;
  }
  .col-xxl-38 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-xxl-39 {
    flex: 0 0 32.5%;
    max-width: 32.5%;
  }
  .col-xxl-40 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-41 {
    flex: 0 0 34.166667%;
    max-width: 34.166667%;
  }
  .col-xxl-42 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-xxl-43 {
    flex: 0 0 35.833333%;
    max-width: 35.833333%;
  }
  .col-xxl-44 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-xxl-45 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-xxl-46 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-xxl-47 {
    flex: 0 0 39.166667%;
    max-width: 39.166667%;
  }
  .col-xxl-48 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-xxl-49 {
    flex: 0 0 40.833333%;
    max-width: 40.833333%;
  }
  .col-xxl-50 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-51 {
    flex: 0 0 42.5%;
    max-width: 42.5%;
  }
  .col-xxl-52 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-xxl-53 {
    flex: 0 0 44.166667%;
    max-width: 44.166667%;
  }
  .col-xxl-54 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-xxl-55 {
    flex: 0 0 45.833333%;
    max-width: 45.833333%;
  }
  .col-xxl-56 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-xxl-57 {
    flex: 0 0 47.5%;
    max-width: 47.5%;
  }
  .col-xxl-58 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-xxl-59 {
    flex: 0 0 49.166667%;
    max-width: 49.166667%;
  }
  .col-xxl-60 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-61 {
    flex: 0 0 50.833333%;
    max-width: 50.833333%;
  }
  .col-xxl-62 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-xxl-63 {
    flex: 0 0 52.5%;
    max-width: 52.5%;
  }
  .col-xxl-64 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-xxl-65 {
    flex: 0 0 54.166667%;
    max-width: 54.166667%;
  }
  .col-xxl-66 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-xxl-67 {
    flex: 0 0 55.833333%;
    max-width: 55.833333%;
  }
  .col-xxl-68 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-xxl-69 {
    flex: 0 0 57.5%;
    max-width: 57.5%;
  }
  .col-xxl-70 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-71 {
    flex: 0 0 59.166667%;
    max-width: 59.166667%;
  }
  .col-xxl-72 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-xxl-73 {
    flex: 0 0 60.833333%;
    max-width: 60.833333%;
  }
  .col-xxl-74 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-xxl-75 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-xxl-76 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-xxl-77 {
    flex: 0 0 64.166667%;
    max-width: 64.166667%;
  }
  .col-xxl-78 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-xxl-79 {
    flex: 0 0 65.833333%;
    max-width: 65.833333%;
  }
  .col-xxl-80 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-81 {
    flex: 0 0 67.5%;
    max-width: 67.5%;
  }
  .col-xxl-82 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-xxl-83 {
    flex: 0 0 69.166667%;
    max-width: 69.166667%;
  }
  .col-xxl-84 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-xxl-85 {
    flex: 0 0 70.833333%;
    max-width: 70.833333%;
  }
  .col-xxl-86 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-xxl-87 {
    flex: 0 0 72.5%;
    max-width: 72.5%;
  }
  .col-xxl-88 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-xxl-89 {
    flex: 0 0 74.166667%;
    max-width: 74.166667%;
  }
  .col-xxl-90 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-91 {
    flex: 0 0 75.833333%;
    max-width: 75.833333%;
  }
  .col-xxl-92 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-xxl-93 {
    flex: 0 0 77.5%;
    max-width: 77.5%;
  }
  .col-xxl-94 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-xxl-95 {
    flex: 0 0 79.166667%;
    max-width: 79.166667%;
  }
  .col-xxl-96 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-xxl-97 {
    flex: 0 0 80.833333%;
    max-width: 80.833333%;
  }
  .col-xxl-98 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-xxl-99 {
    flex: 0 0 82.5%;
    max-width: 82.5%;
  }
  .col-xxl-100 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-101 {
    flex: 0 0 84.166667%;
    max-width: 84.166667%;
  }
  .col-xxl-102 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-xxl-103 {
    flex: 0 0 85.833333%;
    max-width: 85.833333%;
  }
  .col-xxl-104 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-xxl-105 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-xxl-106 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-xxl-107 {
    flex: 0 0 89.166667%;
    max-width: 89.166667%;
  }
  .col-xxl-108 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-xxl-109 {
    flex: 0 0 90.833333%;
    max-width: 90.833333%;
  }
  .col-xxl-110 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-111 {
    flex: 0 0 92.5%;
    max-width: 92.5%;
  }
  .col-xxl-112 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-xxl-113 {
    flex: 0 0 94.166667%;
    max-width: 94.166667%;
  }
  .col-xxl-114 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-xxl-115 {
    flex: 0 0 95.833333%;
    max-width: 95.833333%;
  }
  .col-xxl-116 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-xxl-117 {
    flex: 0 0 97.5%;
    max-width: 97.5%;
  }
  .col-xxl-118 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-xxl-119 {
    flex: 0 0 99.166667%;
    max-width: 99.166667%;
  }
  .col-xxl-120 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 121;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .order-xxl-13 {
    order: 13;
  }
  .order-xxl-14 {
    order: 14;
  }
  .order-xxl-15 {
    order: 15;
  }
  .order-xxl-16 {
    order: 16;
  }
  .order-xxl-17 {
    order: 17;
  }
  .order-xxl-18 {
    order: 18;
  }
  .order-xxl-19 {
    order: 19;
  }
  .order-xxl-20 {
    order: 20;
  }
  .order-xxl-21 {
    order: 21;
  }
  .order-xxl-22 {
    order: 22;
  }
  .order-xxl-23 {
    order: 23;
  }
  .order-xxl-24 {
    order: 24;
  }
  .order-xxl-25 {
    order: 25;
  }
  .order-xxl-26 {
    order: 26;
  }
  .order-xxl-27 {
    order: 27;
  }
  .order-xxl-28 {
    order: 28;
  }
  .order-xxl-29 {
    order: 29;
  }
  .order-xxl-30 {
    order: 30;
  }
  .order-xxl-31 {
    order: 31;
  }
  .order-xxl-32 {
    order: 32;
  }
  .order-xxl-33 {
    order: 33;
  }
  .order-xxl-34 {
    order: 34;
  }
  .order-xxl-35 {
    order: 35;
  }
  .order-xxl-36 {
    order: 36;
  }
  .order-xxl-37 {
    order: 37;
  }
  .order-xxl-38 {
    order: 38;
  }
  .order-xxl-39 {
    order: 39;
  }
  .order-xxl-40 {
    order: 40;
  }
  .order-xxl-41 {
    order: 41;
  }
  .order-xxl-42 {
    order: 42;
  }
  .order-xxl-43 {
    order: 43;
  }
  .order-xxl-44 {
    order: 44;
  }
  .order-xxl-45 {
    order: 45;
  }
  .order-xxl-46 {
    order: 46;
  }
  .order-xxl-47 {
    order: 47;
  }
  .order-xxl-48 {
    order: 48;
  }
  .order-xxl-49 {
    order: 49;
  }
  .order-xxl-50 {
    order: 50;
  }
  .order-xxl-51 {
    order: 51;
  }
  .order-xxl-52 {
    order: 52;
  }
  .order-xxl-53 {
    order: 53;
  }
  .order-xxl-54 {
    order: 54;
  }
  .order-xxl-55 {
    order: 55;
  }
  .order-xxl-56 {
    order: 56;
  }
  .order-xxl-57 {
    order: 57;
  }
  .order-xxl-58 {
    order: 58;
  }
  .order-xxl-59 {
    order: 59;
  }
  .order-xxl-60 {
    order: 60;
  }
  .order-xxl-61 {
    order: 61;
  }
  .order-xxl-62 {
    order: 62;
  }
  .order-xxl-63 {
    order: 63;
  }
  .order-xxl-64 {
    order: 64;
  }
  .order-xxl-65 {
    order: 65;
  }
  .order-xxl-66 {
    order: 66;
  }
  .order-xxl-67 {
    order: 67;
  }
  .order-xxl-68 {
    order: 68;
  }
  .order-xxl-69 {
    order: 69;
  }
  .order-xxl-70 {
    order: 70;
  }
  .order-xxl-71 {
    order: 71;
  }
  .order-xxl-72 {
    order: 72;
  }
  .order-xxl-73 {
    order: 73;
  }
  .order-xxl-74 {
    order: 74;
  }
  .order-xxl-75 {
    order: 75;
  }
  .order-xxl-76 {
    order: 76;
  }
  .order-xxl-77 {
    order: 77;
  }
  .order-xxl-78 {
    order: 78;
  }
  .order-xxl-79 {
    order: 79;
  }
  .order-xxl-80 {
    order: 80;
  }
  .order-xxl-81 {
    order: 81;
  }
  .order-xxl-82 {
    order: 82;
  }
  .order-xxl-83 {
    order: 83;
  }
  .order-xxl-84 {
    order: 84;
  }
  .order-xxl-85 {
    order: 85;
  }
  .order-xxl-86 {
    order: 86;
  }
  .order-xxl-87 {
    order: 87;
  }
  .order-xxl-88 {
    order: 88;
  }
  .order-xxl-89 {
    order: 89;
  }
  .order-xxl-90 {
    order: 90;
  }
  .order-xxl-91 {
    order: 91;
  }
  .order-xxl-92 {
    order: 92;
  }
  .order-xxl-93 {
    order: 93;
  }
  .order-xxl-94 {
    order: 94;
  }
  .order-xxl-95 {
    order: 95;
  }
  .order-xxl-96 {
    order: 96;
  }
  .order-xxl-97 {
    order: 97;
  }
  .order-xxl-98 {
    order: 98;
  }
  .order-xxl-99 {
    order: 99;
  }
  .order-xxl-100 {
    order: 100;
  }
  .order-xxl-101 {
    order: 101;
  }
  .order-xxl-102 {
    order: 102;
  }
  .order-xxl-103 {
    order: 103;
  }
  .order-xxl-104 {
    order: 104;
  }
  .order-xxl-105 {
    order: 105;
  }
  .order-xxl-106 {
    order: 106;
  }
  .order-xxl-107 {
    order: 107;
  }
  .order-xxl-108 {
    order: 108;
  }
  .order-xxl-109 {
    order: 109;
  }
  .order-xxl-110 {
    order: 110;
  }
  .order-xxl-111 {
    order: 111;
  }
  .order-xxl-112 {
    order: 112;
  }
  .order-xxl-113 {
    order: 113;
  }
  .order-xxl-114 {
    order: 114;
  }
  .order-xxl-115 {
    order: 115;
  }
  .order-xxl-116 {
    order: 116;
  }
  .order-xxl-117 {
    order: 117;
  }
  .order-xxl-118 {
    order: 118;
  }
  .order-xxl-119 {
    order: 119;
  }
  .order-xxl-120 {
    order: 120;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 0.833333%;
  }
  .offset-xxl-2 {
    margin-left: 1.666667%;
  }
  .offset-xxl-3 {
    margin-left: 2.5%;
  }
  .offset-xxl-4 {
    margin-left: 3.333333%;
  }
  .offset-xxl-5 {
    margin-left: 4.166667%;
  }
  .offset-xxl-6 {
    margin-left: 5%;
  }
  .offset-xxl-7 {
    margin-left: 5.833333%;
  }
  .offset-xxl-8 {
    margin-left: 6.666667%;
  }
  .offset-xxl-9 {
    margin-left: 7.5%;
  }
  .offset-xxl-10 {
    margin-left: 8.333333%;
  }
  .offset-xxl-11 {
    margin-left: 9.166667%;
  }
  .offset-xxl-12 {
    margin-left: 10%;
  }
  .offset-xxl-13 {
    margin-left: 10.833333%;
  }
  .offset-xxl-14 {
    margin-left: 11.666667%;
  }
  .offset-xxl-15 {
    margin-left: 12.5%;
  }
  .offset-xxl-16 {
    margin-left: 13.333333%;
  }
  .offset-xxl-17 {
    margin-left: 14.166667%;
  }
  .offset-xxl-18 {
    margin-left: 15%;
  }
  .offset-xxl-19 {
    margin-left: 15.833333%;
  }
  .offset-xxl-20 {
    margin-left: 16.666667%;
  }
  .offset-xxl-21 {
    margin-left: 17.5%;
  }
  .offset-xxl-22 {
    margin-left: 18.333333%;
  }
  .offset-xxl-23 {
    margin-left: 19.166667%;
  }
  .offset-xxl-24 {
    margin-left: 20%;
  }
  .offset-xxl-25 {
    margin-left: 20.833333%;
  }
  .offset-xxl-26 {
    margin-left: 21.666667%;
  }
  .offset-xxl-27 {
    margin-left: 22.5%;
  }
  .offset-xxl-28 {
    margin-left: 23.333333%;
  }
  .offset-xxl-29 {
    margin-left: 24.166667%;
  }
  .offset-xxl-30 {
    margin-left: 25%;
  }
  .offset-xxl-31 {
    margin-left: 25.833333%;
  }
  .offset-xxl-32 {
    margin-left: 26.666667%;
  }
  .offset-xxl-33 {
    margin-left: 27.5%;
  }
  .offset-xxl-34 {
    margin-left: 28.333333%;
  }
  .offset-xxl-35 {
    margin-left: 29.166667%;
  }
  .offset-xxl-36 {
    margin-left: 30%;
  }
  .offset-xxl-37 {
    margin-left: 30.833333%;
  }
  .offset-xxl-38 {
    margin-left: 31.666667%;
  }
  .offset-xxl-39 {
    margin-left: 32.5%;
  }
  .offset-xxl-40 {
    margin-left: 33.333333%;
  }
  .offset-xxl-41 {
    margin-left: 34.166667%;
  }
  .offset-xxl-42 {
    margin-left: 35%;
  }
  .offset-xxl-43 {
    margin-left: 35.833333%;
  }
  .offset-xxl-44 {
    margin-left: 36.666667%;
  }
  .offset-xxl-45 {
    margin-left: 37.5%;
  }
  .offset-xxl-46 {
    margin-left: 38.333333%;
  }
  .offset-xxl-47 {
    margin-left: 39.166667%;
  }
  .offset-xxl-48 {
    margin-left: 40%;
  }
  .offset-xxl-49 {
    margin-left: 40.833333%;
  }
  .offset-xxl-50 {
    margin-left: 41.666667%;
  }
  .offset-xxl-51 {
    margin-left: 42.5%;
  }
  .offset-xxl-52 {
    margin-left: 43.333333%;
  }
  .offset-xxl-53 {
    margin-left: 44.166667%;
  }
  .offset-xxl-54 {
    margin-left: 45%;
  }
  .offset-xxl-55 {
    margin-left: 45.833333%;
  }
  .offset-xxl-56 {
    margin-left: 46.666667%;
  }
  .offset-xxl-57 {
    margin-left: 47.5%;
  }
  .offset-xxl-58 {
    margin-left: 48.333333%;
  }
  .offset-xxl-59 {
    margin-left: 49.166667%;
  }
  .offset-xxl-60 {
    margin-left: 50%;
  }
  .offset-xxl-61 {
    margin-left: 50.833333%;
  }
  .offset-xxl-62 {
    margin-left: 51.666667%;
  }
  .offset-xxl-63 {
    margin-left: 52.5%;
  }
  .offset-xxl-64 {
    margin-left: 53.333333%;
  }
  .offset-xxl-65 {
    margin-left: 54.166667%;
  }
  .offset-xxl-66 {
    margin-left: 55%;
  }
  .offset-xxl-67 {
    margin-left: 55.833333%;
  }
  .offset-xxl-68 {
    margin-left: 56.666667%;
  }
  .offset-xxl-69 {
    margin-left: 57.5%;
  }
  .offset-xxl-70 {
    margin-left: 58.333333%;
  }
  .offset-xxl-71 {
    margin-left: 59.166667%;
  }
  .offset-xxl-72 {
    margin-left: 60%;
  }
  .offset-xxl-73 {
    margin-left: 60.833333%;
  }
  .offset-xxl-74 {
    margin-left: 61.666667%;
  }
  .offset-xxl-75 {
    margin-left: 62.5%;
  }
  .offset-xxl-76 {
    margin-left: 63.333333%;
  }
  .offset-xxl-77 {
    margin-left: 64.166667%;
  }
  .offset-xxl-78 {
    margin-left: 65%;
  }
  .offset-xxl-79 {
    margin-left: 65.833333%;
  }
  .offset-xxl-80 {
    margin-left: 66.666667%;
  }
  .offset-xxl-81 {
    margin-left: 67.5%;
  }
  .offset-xxl-82 {
    margin-left: 68.333333%;
  }
  .offset-xxl-83 {
    margin-left: 69.166667%;
  }
  .offset-xxl-84 {
    margin-left: 70%;
  }
  .offset-xxl-85 {
    margin-left: 70.833333%;
  }
  .offset-xxl-86 {
    margin-left: 71.666667%;
  }
  .offset-xxl-87 {
    margin-left: 72.5%;
  }
  .offset-xxl-88 {
    margin-left: 73.333333%;
  }
  .offset-xxl-89 {
    margin-left: 74.166667%;
  }
  .offset-xxl-90 {
    margin-left: 75%;
  }
  .offset-xxl-91 {
    margin-left: 75.833333%;
  }
  .offset-xxl-92 {
    margin-left: 76.666667%;
  }
  .offset-xxl-93 {
    margin-left: 77.5%;
  }
  .offset-xxl-94 {
    margin-left: 78.333333%;
  }
  .offset-xxl-95 {
    margin-left: 79.166667%;
  }
  .offset-xxl-96 {
    margin-left: 80%;
  }
  .offset-xxl-97 {
    margin-left: 80.833333%;
  }
  .offset-xxl-98 {
    margin-left: 81.666667%;
  }
  .offset-xxl-99 {
    margin-left: 82.5%;
  }
  .offset-xxl-100 {
    margin-left: 83.333333%;
  }
  .offset-xxl-101 {
    margin-left: 84.166667%;
  }
  .offset-xxl-102 {
    margin-left: 85%;
  }
  .offset-xxl-103 {
    margin-left: 85.833333%;
  }
  .offset-xxl-104 {
    margin-left: 86.666667%;
  }
  .offset-xxl-105 {
    margin-left: 87.5%;
  }
  .offset-xxl-106 {
    margin-left: 88.333333%;
  }
  .offset-xxl-107 {
    margin-left: 89.166667%;
  }
  .offset-xxl-108 {
    margin-left: 90%;
  }
  .offset-xxl-109 {
    margin-left: 90.833333%;
  }
  .offset-xxl-110 {
    margin-left: 91.666667%;
  }
  .offset-xxl-111 {
    margin-left: 92.5%;
  }
  .offset-xxl-112 {
    margin-left: 93.333333%;
  }
  .offset-xxl-113 {
    margin-left: 94.166667%;
  }
  .offset-xxl-114 {
    margin-left: 95%;
  }
  .offset-xxl-115 {
    margin-left: 95.833333%;
  }
  .offset-xxl-116 {
    margin-left: 96.666667%;
  }
  .offset-xxl-117 {
    margin-left: 97.5%;
  }
  .offset-xxl-118 {
    margin-left: 98.333333%;
  }
  .offset-xxl-119 {
    margin-left: 99.166667%;
  }
}

@media (max-width: 575.98px) {
  .row-responsive-sm {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
  }
}

@media (max-width: 767.98px) {
  .row-responsive-md {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
  }
}

@media (max-width: 991.98px) {
  .row-responsive-lg {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
  }
}

@media (max-width: 1199.98px) {
  .row-responsive-xl {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
  }
}

@media (max-width: 1399.98px) {
  .row-responsive-xxl {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
  }
}

.row-responsive {
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c9e8b8;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9ad47b;
}

.table-hover .table-primary:hover {
  background-color: #bae2a5;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #bae2a5;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c9e8b8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9ad47b;
}

.table-hover .table-success:hover {
  background-color: #bae2a5;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bae2a5;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-gray-100,
.table-gray-100 > th,
.table-gray-100 > td {
  background-color: #fdfdfe;
}

.table-gray-100 th,
.table-gray-100 td,
.table-gray-100 thead th,
.table-gray-100 tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-gray-100:hover {
  background-color: #ececf6;
}

.table-hover .table-gray-100:hover > td,
.table-hover .table-gray-100:hover > th {
  background-color: #ececf6;
}

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #f9fafb;
}

.table-gray-200 th,
.table-gray-200 td,
.table-gray-200 thead th,
.table-gray-200 tbody + tbody {
  border-color: #f4f5f7;
}

.table-hover .table-gray-200:hover {
  background-color: #eaedf1;
}

.table-hover .table-gray-200:hover > td,
.table-hover .table-gray-200:hover > th {
  background-color: #eaedf1;
}

.table-gray-300,
.table-gray-300 > th,
.table-gray-300 > td {
  background-color: #f6f7f8;
}

.table-gray-300 th,
.table-gray-300 td,
.table-gray-300 thead th,
.table-gray-300 tbody + tbody {
  border-color: #eef0f2;
}

.table-hover .table-gray-300:hover {
  background-color: #e8eaed;
}

.table-hover .table-gray-300:hover > td,
.table-hover .table-gray-300:hover > th {
  background-color: #e8eaed;
}

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #f1f3f5;
}

.table-gray-400 th,
.table-gray-400 td,
.table-gray-400 thead th,
.table-gray-400 tbody + tbody {
  border-color: #e6e9ec;
}

.table-hover .table-gray-400:hover {
  background-color: #e2e6ea;
}

.table-hover .table-gray-400:hover > td,
.table-hover .table-gray-400:hover > th {
  background-color: #e2e6ea;
}

.table-gray-500,
.table-gray-500 > th,
.table-gray-500 > td {
  background-color: #e8eaed;
}

.table-gray-500 th,
.table-gray-500 td,
.table-gray-500 thead th,
.table-gray-500 tbody + tbody {
  border-color: #d4d9dd;
}

.table-hover .table-gray-500:hover {
  background-color: #dadde2;
}

.table-hover .table-gray-500:hover > td,
.table-hover .table-gray-500:hover > th {
  background-color: #dadde2;
}

.table-gray-600,
.table-gray-600 > th,
.table-gray-600 > td {
  background-color: #d6d8db;
}

.table-gray-600 th,
.table-gray-600 td,
.table-gray-600 thead th,
.table-gray-600 tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-gray-600:hover {
  background-color: #c8cbcf;
}

.table-hover .table-gray-600:hover > td,
.table-hover .table-gray-600:hover > th {
  background-color: #c8cbcf;
}

.table-gray-700,
.table-gray-700 > th,
.table-gray-700 > td {
  background-color: #ccced0;
}

.table-gray-700 th,
.table-gray-700 td,
.table-gray-700 thead th,
.table-gray-700 tbody + tbody {
  border-color: #a0a4a8;
}

.table-hover .table-gray-700:hover {
  background-color: #bfc1c4;
}

.table-hover .table-gray-700:hover > td,
.table-hover .table-gray-700:hover > th {
  background-color: #bfc1c4;
}

.table-gray-800,
.table-gray-800 > th,
.table-gray-800 > td {
  background-color: #c6c8ca;
}

.table-gray-800 th,
.table-gray-800 td,
.table-gray-800 thead th,
.table-gray-800 tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-gray-800:hover {
  background-color: #b9bbbe;
}

.table-hover .table-gray-800:hover > td,
.table-hover .table-gray-800:hover > th {
  background-color: #b9bbbe;
}

.table-gray-900,
.table-gray-900 > th,
.table-gray-900 > td {
  background-color: #c1c2c3;
}

.table-gray-900 th,
.table-gray-900 td,
.table-gray-900 thead th,
.table-gray-900 tbody + tbody {
  border-color: #8c8e90;
}

.table-hover .table-gray-900:hover {
  background-color: #b4b5b6;
}

.table-hover .table-gray-900:hover > td,
.table-hover .table-gray-900:hover > th {
  background-color: #b4b5b6;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #78fd32;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #3dad02;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(61, 173, 2, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3dad02;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233dad02' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #3dad02;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #3dad02;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233dad02' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #3dad02;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3dad02;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #3dad02;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #3dad02;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #4fdf03;
  background-color: #4fdf03;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3dad02;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #3dad02;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #3dad02;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.btn-primary:hover {
  color: #fff;
  background-color: #308702;
  border-color: #2b7b01;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #308702;
  border-color: #2b7b01;
  box-shadow: 0 0 0 0.2rem rgba(90, 185, 40, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2b7b01;
  border-color: #276e01;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 185, 40, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.btn-success:hover {
  color: #fff;
  background-color: #308702;
  border-color: #2b7b01;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #308702;
  border-color: #2b7b01;
  box-shadow: 0 0 0 0.2rem rgba(90, 185, 40, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2b7b01;
  border-color: #276e01;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 185, 40, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white:focus, .btn-white.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-white.disabled, .btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-gray-100 {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-gray-100:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-gray-100:focus, .btn-gray-100.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-gray-100.disabled, .btn-gray-100:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
.show > .btn-gray-100.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-gray-200 {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-gray-200:hover {
  color: #212529;
  background-color: #d3d9df;
  border-color: #cbd3da;
}

.btn-gray-200:focus, .btn-gray-200.focus {
  color: #212529;
  background-color: #d3d9df;
  border-color: #cbd3da;
  box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5);
}

.btn-gray-200.disabled, .btn-gray-200:disabled {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
.show > .btn-gray-200.dropdown-toggle {
  color: #212529;
  background-color: #cbd3da;
  border-color: #c4ccd4;
}

.btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5);
}

.btn-gray-300 {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-gray-300:hover {
  color: #212529;
  background-color: #c8cfd6;
  border-color: #c1c9d0;
}

.btn-gray-300:focus, .btn-gray-300.focus {
  color: #212529;
  background-color: #c8cfd6;
  border-color: #c1c9d0;
  box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5);
}

.btn-gray-300.disabled, .btn-gray-300:disabled {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
.show > .btn-gray-300.dropdown-toggle {
  color: #212529;
  background-color: #c1c9d0;
  border-color: #bac2cb;
}

.btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5);
}

.btn-gray-400 {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-gray-400:hover {
  color: #212529;
  background-color: #b8c1ca;
  border-color: #b1bbc4;
}

.btn-gray-400:focus, .btn-gray-400.focus {
  color: #212529;
  background-color: #b8c1ca;
  border-color: #b1bbc4;
  box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5);
}

.btn-gray-400.disabled, .btn-gray-400:disabled {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
.show > .btn-gray-400.dropdown-toggle {
  color: #212529;
  background-color: #b1bbc4;
  border-color: #aab4bf;
}

.btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5);
}

.btn-gray-500 {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-gray-500:hover {
  color: #212529;
  background-color: #98a2ac;
  border-color: #919ca6;
}

.btn-gray-500:focus, .btn-gray-500.focus {
  color: #212529;
  background-color: #98a2ac;
  border-color: #919ca6;
  box-shadow: 0 0 0 0.2rem rgba(152, 159, 167, 0.5);
}

.btn-gray-500.disabled, .btn-gray-500:disabled {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
.show > .btn-gray-500.dropdown-toggle {
  color: #212529;
  background-color: #919ca6;
  border-color: #8a95a1;
}

.btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 159, 167, 0.5);
}

.btn-gray-600 {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-gray-600:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-gray-600:focus, .btn-gray-600.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-gray-600.disabled, .btn-gray-600:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
.show > .btn-gray-600.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-gray-700 {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}

.btn-gray-700:hover {
  color: #fff;
  background-color: #383d42;
  border-color: #32373b;
}

.btn-gray-700:focus, .btn-gray-700.focus {
  color: #fff;
  background-color: #383d42;
  border-color: #32373b;
  box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5);
}

.btn-gray-700.disabled, .btn-gray-700:disabled {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}

.btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
.show > .btn-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #32373b;
  border-color: #2c3034;
}

.btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5);
}

.btn-gray-800 {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-gray-800:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-gray-800:focus, .btn-gray-800.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-gray-800.disabled, .btn-gray-800:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
.show > .btn-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-gray-900 {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-gray-900:hover {
  color: #fff;
  background-color: #101214;
  border-color: #0a0c0d;
}

.btn-gray-900:focus, .btn-gray-900.focus {
  color: #fff;
  background-color: #101214;
  border-color: #0a0c0d;
  box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5);
}

.btn-gray-900.disabled, .btn-gray-900:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
.show > .btn-gray-900.dropdown-toggle {
  color: #fff;
  background-color: #0a0c0d;
  border-color: #050506;
}

.btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5);
}

.btn-outline-primary {
  color: #3dad02;
  border-color: #3dad02;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3dad02;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #3dad02;
  border-color: #3dad02;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #3dad02;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-gray-100 {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-gray-100:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-100.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-gray-200 {
  color: #e9ecef;
  border-color: #e9ecef;
}

.btn-outline-gray-200:hover {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
  color: #e9ecef;
  background-color: transparent;
}

.btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-200.dropdown-toggle {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-gray-300 {
  color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-gray-300:hover {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
  color: #dee2e6;
  background-color: transparent;
}

.btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-300.dropdown-toggle {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-gray-400 {
  color: #ced4da;
  border-color: #ced4da;
}

.btn-outline-gray-400:hover {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
  color: #ced4da;
  background-color: transparent;
}

.btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-400.dropdown-toggle {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-gray-500 {
  color: #adb5bd;
  border-color: #adb5bd;
}

.btn-outline-gray-500:hover {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
  color: #adb5bd;
  background-color: transparent;
}

.btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-500.dropdown-toggle {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-outline-gray-600 {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-gray-600:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-600.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-gray-700 {
  color: #495057;
  border-color: #495057;
}

.btn-outline-gray-700:hover {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}

.btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5);
}

.btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
  color: #495057;
  background-color: transparent;
}

.btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
}

.btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5);
}

.btn-outline-gray-800 {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-gray-800:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-gray-900 {
  color: #212529;
  border-color: #212529;
}

.btn-outline-gray-900:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-gray-900:focus, .btn-outline-gray-900.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}

.btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled {
  color: #212529;
  background-color: transparent;
}

.btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-900.dropdown-toggle {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-gray-900:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-900:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3dad02;
  text-decoration: none;
}

.btn-link:hover {
  color: #226101;
  text-decoration: none;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn-round {
  border-radius: 99px;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropdown.show .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropdown.show .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup.show .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropup.show .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright.show .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropright.show .dropdown-toggle::after {
  display: none;
}

.dropright.show .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropright.show .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft.show .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropleft.show .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3dad02;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3dad02;
  background-color: #3dad02;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #78fd32;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #99fd64;
  border-color: #99fd64;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3dad02;
  background-color: #3dad02;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(61, 173, 2, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(61, 173, 2, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(61, 173, 2, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(61, 173, 2, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #78fd32;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #78fd32;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3dad02;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #99fd64;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3dad02;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #99fd64;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3dad02;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #99fd64;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  position: relative;
  color: #495057;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  background-color: #e9ecef;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #3dad02;
  background-color: rgba(40, 167, 69, 0.06);
}

.nav-tabs .nav-link.active:after,
.nav-tabs .nav-item.show .nav-link:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 3px;
  background-color: #3dad02;
  bottom: -1px;
  left: 0;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3dad02;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #000;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #000;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.85);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #000;
  font-weight: bold;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.7);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-text a {
  color: #000;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #000;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
  font-weight: bold;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  margin-top: -0.25rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-deck .card {
  margin-bottom: 8px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -8px;
    margin-left: -8px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 8px;
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.card-group > .card {
  margin-bottom: 8px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.pagination-item-spacer .page-item {
  margin-bottom: 0.5rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3dad02;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #226101;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #3dad02;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #2b7b01;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #3dad02;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #2b7b01;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(61, 173, 2, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-white {
  color: #212529;
  background-color: #fff;
}

a.badge-white:hover, a.badge-white:focus {
  color: #212529;
  background-color: #e6e6e6;
}

a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-gray-100 {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-gray-100:hover, a.badge-gray-100:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-gray-100:focus, a.badge-gray-100.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-gray-200 {
  color: #212529;
  background-color: #e9ecef;
}

a.badge-gray-200:hover, a.badge-gray-200:focus {
  color: #212529;
  background-color: #cbd3da;
}

a.badge-gray-200:focus, a.badge-gray-200.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.badge-gray-300 {
  color: #212529;
  background-color: #dee2e6;
}

a.badge-gray-300:hover, a.badge-gray-300:focus {
  color: #212529;
  background-color: #c1c9d0;
}

a.badge-gray-300:focus, a.badge-gray-300.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.badge-gray-400 {
  color: #212529;
  background-color: #ced4da;
}

a.badge-gray-400:hover, a.badge-gray-400:focus {
  color: #212529;
  background-color: #b1bbc4;
}

a.badge-gray-400:focus, a.badge-gray-400.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.badge-gray-500 {
  color: #212529;
  background-color: #adb5bd;
}

a.badge-gray-500:hover, a.badge-gray-500:focus {
  color: #212529;
  background-color: #919ca6;
}

a.badge-gray-500:focus, a.badge-gray-500.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.badge-gray-600 {
  color: #fff;
  background-color: #6c757d;
}

a.badge-gray-600:hover, a.badge-gray-600:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-gray-600:focus, a.badge-gray-600.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-gray-700 {
  color: #fff;
  background-color: #495057;
}

a.badge-gray-700:hover, a.badge-gray-700:focus {
  color: #fff;
  background-color: #32373b;
}

a.badge-gray-700:focus, a.badge-gray-700.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5);
}

.badge-gray-800 {
  color: #fff;
  background-color: #343a40;
}

a.badge-gray-800:hover, a.badge-gray-800:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-gray-800:focus, a.badge-gray-800.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-gray-900 {
  color: #fff;
  background-color: #212529;
}

a.badge-gray-900:hover, a.badge-gray-900:focus {
  color: #fff;
  background-color: #0a0c0d;
}

a.badge-gray-900:focus, a.badge-gray-900.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #205a01;
  background-color: #d8efcc;
  border-color: #c9e8b8;
}

.alert-primary hr {
  border-top-color: #bae2a5;
}

.alert-primary .alert-link {
  color: #0e2800;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #205a01;
  background-color: #d8efcc;
  border-color: #c9e8b8;
}

.alert-success hr {
  border-top-color: #bae2a5;
}

.alert-success .alert-link {
  color: #0e2800;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-gray-100 {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-gray-100 hr {
  border-top-color: #ececf6;
}

.alert-gray-100 .alert-link {
  color: #686868;
}

.alert-gray-200 {
  color: #797b7c;
  background-color: #fbfbfc;
  border-color: #f9fafb;
}

.alert-gray-200 hr {
  border-top-color: #eaedf1;
}

.alert-gray-200 .alert-link {
  color: #606162;
}

.alert-gray-300 {
  color: #737678;
  background-color: #f8f9fa;
  border-color: #f6f7f8;
}

.alert-gray-300 hr {
  border-top-color: #e8eaed;
}

.alert-gray-300 .alert-link {
  color: #5a5c5e;
}

.alert-gray-400 {
  color: #6b6e71;
  background-color: #f5f6f8;
  border-color: #f1f3f5;
}

.alert-gray-400 hr {
  border-top-color: #e2e6ea;
}

.alert-gray-400 .alert-link {
  color: #525557;
}

.alert-gray-500 {
  color: #5a5e62;
  background-color: #eff0f2;
  border-color: #e8eaed;
}

.alert-gray-500 hr {
  border-top-color: #dadde2;
}

.alert-gray-500 .alert-link {
  color: #424547;
}

.alert-gray-600 {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-gray-600 hr {
  border-top-color: #c8cbcf;
}

.alert-gray-600 .alert-link {
  color: #202326;
}

.alert-gray-700 {
  color: #262a2d;
  background-color: #dbdcdd;
  border-color: #ccced0;
}

.alert-gray-700 hr {
  border-top-color: #bfc1c4;
}

.alert-gray-700 .alert-link {
  color: #0f1011;
}

.alert-gray-800 {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-gray-800 hr {
  border-top-color: #b9bbbe;
}

.alert-gray-800 .alert-link {
  color: #040505;
}

.alert-gray-900 {
  color: #111315;
  background-color: #d3d3d4;
  border-color: #c1c2c3;
}

.alert-gray-900 hr {
  border-top-color: #b4b5b6;
}

.alert-gray-900 .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3dad02;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3dad02;
  border-color: #3dad02;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #205a01;
  background-color: #c9e8b8;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #205a01;
  background-color: #bae2a5;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #205a01;
  border-color: #205a01;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #205a01;
  background-color: #c9e8b8;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #205a01;
  background-color: #bae2a5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #205a01;
  border-color: #205a01;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-gray-100 {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-gray-100.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-gray-200 {
  color: #797b7c;
  background-color: #f9fafb;
}

.list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
  color: #797b7c;
  background-color: #eaedf1;
}

.list-group-item-gray-200.list-group-item-action.active {
  color: #fff;
  background-color: #797b7c;
  border-color: #797b7c;
}

.list-group-item-gray-300 {
  color: #737678;
  background-color: #f6f7f8;
}

.list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
  color: #737678;
  background-color: #e8eaed;
}

.list-group-item-gray-300.list-group-item-action.active {
  color: #fff;
  background-color: #737678;
  border-color: #737678;
}

.list-group-item-gray-400 {
  color: #6b6e71;
  background-color: #f1f3f5;
}

.list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
  color: #6b6e71;
  background-color: #e2e6ea;
}

.list-group-item-gray-400.list-group-item-action.active {
  color: #fff;
  background-color: #6b6e71;
  border-color: #6b6e71;
}

.list-group-item-gray-500 {
  color: #5a5e62;
  background-color: #e8eaed;
}

.list-group-item-gray-500.list-group-item-action:hover, .list-group-item-gray-500.list-group-item-action:focus {
  color: #5a5e62;
  background-color: #dadde2;
}

.list-group-item-gray-500.list-group-item-action.active {
  color: #fff;
  background-color: #5a5e62;
  border-color: #5a5e62;
}

.list-group-item-gray-600 {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-gray-600.list-group-item-action:hover, .list-group-item-gray-600.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-gray-600.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-gray-700 {
  color: #262a2d;
  background-color: #ccced0;
}

.list-group-item-gray-700.list-group-item-action:hover, .list-group-item-gray-700.list-group-item-action:focus {
  color: #262a2d;
  background-color: #bfc1c4;
}

.list-group-item-gray-700.list-group-item-action.active {
  color: #fff;
  background-color: #262a2d;
  border-color: #262a2d;
}

.list-group-item-gray-800 {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-gray-800.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-gray-900 {
  color: #111315;
  background-color: #c1c2c3;
}

.list-group-item-gray-900.list-group-item-action:hover, .list-group-item-gray-900.list-group-item-action:focus {
  color: #111315;
  background-color: #b4b5b6;
}

.list-group-item-gray-900.list-group-item-action.active {
  color: #fff;
  background-color: #111315;
  border-color: #111315;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.25s ease, color 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.75;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
  --carousel-indicator-active-bg: #fff;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 6px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: relative;
  opacity: .5;
  transition: opacity 0.6s, width 0.6s, background-color 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators li:before {
  content: "";
  position: absolute;
  display: block;
  width: 30px;
  height: 6px;
  text-indent: -999px;
  border-radius: 2px;
  background-color: var(--carousel-indicator-active-bg);
  background-clip: padding-box;
  top: 0;
  left: 0;
  transition: opacity 0.6s, width 0.6s, background-color 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li:before {
    transition: none;
  }
}

.carousel-indicators li:hover {
  opacity: .75;
}

.carousel-indicators .active {
  opacity: 1;
  width: 60px;
}

.carousel-indicators .active:before {
  width: 60px;
}

.carousel-indicators .active:hover {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3dad02 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2b7b01 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #3dad02 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2b7b01 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray-100 {
  background-color: #f8f9fa !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dae0e5 !important;
}

.bg-gray-200 {
  background-color: #e9ecef !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbd3da !important;
}

.bg-gray-300 {
  background-color: #dee2e6 !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c1c9d0 !important;
}

.bg-gray-400 {
  background-color: #ced4da !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b1bbc4 !important;
}

.bg-gray-500 {
  background-color: #adb5bd !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #919ca6 !important;
}

.bg-gray-600 {
  background-color: #6c757d !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #545b62 !important;
}

.bg-gray-700 {
  background-color: #495057 !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #32373b !important;
}

.bg-gray-800 {
  background-color: #343a40 !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #1d2124 !important;
}

.bg-gray-900 {
  background-color: #212529 !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0a0c0d !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3dad02 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #3dad02 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-gray-100 {
  border-color: #f8f9fa !important;
}

.border-gray-200 {
  border-color: #e9ecef !important;
}

.border-gray-300 {
  border-color: #dee2e6 !important;
}

.border-gray-400 {
  border-color: #ced4da !important;
}

.border-gray-500 {
  border-color: #adb5bd !important;
}

.border-gray-600 {
  border-color: #6c757d !important;
}

.border-gray-700 {
  border-color: #495057 !important;
}

.border-gray-800 {
  border-color: #343a40 !important;
}

.border-gray-900 {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

@media (min-width: 576px) {
  .rounded-sm-sm {
    border-radius: 0.2rem !important;
  }
  .rounded-sm {
    border-radius: 0.25rem !important;
  }
  .rounded-sm-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-sm-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-sm-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-sm-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-sm-lg {
    border-radius: 0.3rem !important;
  }
  .rounded-sm-circle {
    border-radius: 50% !important;
  }
  .rounded-sm-pill {
    border-radius: 50rem !important;
  }
  .rounded-sm-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 768px) {
  .rounded-md-sm {
    border-radius: 0.2rem !important;
  }
  .rounded-md {
    border-radius: 0.25rem !important;
  }
  .rounded-md-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-md-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-md-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-md-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-md-lg {
    border-radius: 0.3rem !important;
  }
  .rounded-md-circle {
    border-radius: 50% !important;
  }
  .rounded-md-pill {
    border-radius: 50rem !important;
  }
  .rounded-md-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .rounded-lg-sm {
    border-radius: 0.2rem !important;
  }
  .rounded-lg {
    border-radius: 0.25rem !important;
  }
  .rounded-lg-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-lg-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-lg-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-lg-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-lg-lg {
    border-radius: 0.3rem !important;
  }
  .rounded-lg-circle {
    border-radius: 50% !important;
  }
  .rounded-lg-pill {
    border-radius: 50rem !important;
  }
  .rounded-lg-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1200px) {
  .rounded-xl-sm {
    border-radius: 0.2rem !important;
  }
  .rounded-xl {
    border-radius: 0.25rem !important;
  }
  .rounded-xl-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-xl-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-xl-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xl-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xl-lg {
    border-radius: 0.3rem !important;
  }
  .rounded-xl-circle {
    border-radius: 50% !important;
  }
  .rounded-xl-pill {
    border-radius: 50rem !important;
  }
  .rounded-xl-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1400px) {
  .rounded-xxl-sm {
    border-radius: 0.2rem !important;
  }
  .rounded-xxl {
    border-radius: 0.25rem !important;
  }
  .rounded-xxl-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-xxl-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-xxl-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xxl-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xxl-lg {
    border-radius: 0.3rem !important;
  }
  .rounded-xxl-circle {
    border-radius: 50% !important;
  }
  .rounded-xxl-pill {
    border-radius: 50rem !important;
  }
  .rounded-xxl-0 {
    border-radius: 0 !important;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .fixed-sm-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-sm-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports (position: sticky) {
    .sticky-sm-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .fixed-md-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-md-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports (position: sticky) {
    .sticky-md-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .fixed-lg-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-lg-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports (position: sticky) {
    .sticky-lg-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .fixed-xl-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-xl-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports (position: sticky) {
    .sticky-xl-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
}

@media (min-width: 1400px) {
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .fixed-xxl-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-xxl-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports (position: sticky) {
    .sticky-xxl-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-5 {
  width: 5% !important;
}

.mw-5 {
  max-width: 5% !important;
}

.min-w-5 {
  min-width: 5% !important;
}

.max-vw-5 {
  max-width: 5vw !important;
}

.min-vw-5 {
  min-width: 5vw !important;
}

.vw-5 {
  width: 5vw !important;
}

.w-10 {
  width: 10% !important;
}

.mw-10 {
  max-width: 10% !important;
}

.min-w-10 {
  min-width: 10% !important;
}

.max-vw-10 {
  max-width: 10vw !important;
}

.min-vw-10 {
  min-width: 10vw !important;
}

.vw-10 {
  width: 10vw !important;
}

.w-15 {
  width: 15% !important;
}

.mw-15 {
  max-width: 15% !important;
}

.min-w-15 {
  min-width: 15% !important;
}

.max-vw-15 {
  max-width: 15vw !important;
}

.min-vw-15 {
  min-width: 15vw !important;
}

.vw-15 {
  width: 15vw !important;
}

.w-20 {
  width: 20% !important;
}

.mw-20 {
  max-width: 20% !important;
}

.min-w-20 {
  min-width: 20% !important;
}

.max-vw-20 {
  max-width: 20vw !important;
}

.min-vw-20 {
  min-width: 20vw !important;
}

.vw-20 {
  width: 20vw !important;
}

.w-25 {
  width: 25% !important;
}

.mw-25 {
  max-width: 25% !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.max-vw-25 {
  max-width: 25vw !important;
}

.min-vw-25 {
  min-width: 25vw !important;
}

.vw-25 {
  width: 25vw !important;
}

.w-30 {
  width: 30% !important;
}

.mw-30 {
  max-width: 30% !important;
}

.min-w-30 {
  min-width: 30% !important;
}

.max-vw-30 {
  max-width: 30vw !important;
}

.min-vw-30 {
  min-width: 30vw !important;
}

.vw-30 {
  width: 30vw !important;
}

.w-35 {
  width: 35% !important;
}

.mw-35 {
  max-width: 35% !important;
}

.min-w-35 {
  min-width: 35% !important;
}

.max-vw-35 {
  max-width: 35vw !important;
}

.min-vw-35 {
  min-width: 35vw !important;
}

.vw-35 {
  width: 35vw !important;
}

.w-40 {
  width: 40% !important;
}

.mw-40 {
  max-width: 40% !important;
}

.min-w-40 {
  min-width: 40% !important;
}

.max-vw-40 {
  max-width: 40vw !important;
}

.min-vw-40 {
  min-width: 40vw !important;
}

.vw-40 {
  width: 40vw !important;
}

.w-45 {
  width: 45% !important;
}

.mw-45 {
  max-width: 45% !important;
}

.min-w-45 {
  min-width: 45% !important;
}

.max-vw-45 {
  max-width: 45vw !important;
}

.min-vw-45 {
  min-width: 45vw !important;
}

.vw-45 {
  width: 45vw !important;
}

.w-50 {
  width: 50% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.max-vw-50 {
  max-width: 50vw !important;
}

.min-vw-50 {
  min-width: 50vw !important;
}

.vw-50 {
  width: 50vw !important;
}

.w-55 {
  width: 55% !important;
}

.mw-55 {
  max-width: 55% !important;
}

.min-w-55 {
  min-width: 55% !important;
}

.max-vw-55 {
  max-width: 55vw !important;
}

.min-vw-55 {
  min-width: 55vw !important;
}

.vw-55 {
  width: 55vw !important;
}

.w-60 {
  width: 60% !important;
}

.mw-60 {
  max-width: 60% !important;
}

.min-w-60 {
  min-width: 60% !important;
}

.max-vw-60 {
  max-width: 60vw !important;
}

.min-vw-60 {
  min-width: 60vw !important;
}

.vw-60 {
  width: 60vw !important;
}

.w-65 {
  width: 65% !important;
}

.mw-65 {
  max-width: 65% !important;
}

.min-w-65 {
  min-width: 65% !important;
}

.max-vw-65 {
  max-width: 65vw !important;
}

.min-vw-65 {
  min-width: 65vw !important;
}

.vw-65 {
  width: 65vw !important;
}

.w-70 {
  width: 70% !important;
}

.mw-70 {
  max-width: 70% !important;
}

.min-w-70 {
  min-width: 70% !important;
}

.max-vw-70 {
  max-width: 70vw !important;
}

.min-vw-70 {
  min-width: 70vw !important;
}

.vw-70 {
  width: 70vw !important;
}

.w-75 {
  width: 75% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.max-vw-75 {
  max-width: 75vw !important;
}

.min-vw-75 {
  min-width: 75vw !important;
}

.vw-75 {
  width: 75vw !important;
}

.w-80 {
  width: 80% !important;
}

.mw-80 {
  max-width: 80% !important;
}

.min-w-80 {
  min-width: 80% !important;
}

.max-vw-80 {
  max-width: 80vw !important;
}

.min-vw-80 {
  min-width: 80vw !important;
}

.vw-80 {
  width: 80vw !important;
}

.w-85 {
  width: 85% !important;
}

.mw-85 {
  max-width: 85% !important;
}

.min-w-85 {
  min-width: 85% !important;
}

.max-vw-85 {
  max-width: 85vw !important;
}

.min-vw-85 {
  min-width: 85vw !important;
}

.vw-85 {
  width: 85vw !important;
}

.w-90 {
  width: 90% !important;
}

.mw-90 {
  max-width: 90% !important;
}

.min-w-90 {
  min-width: 90% !important;
}

.max-vw-90 {
  max-width: 90vw !important;
}

.min-vw-90 {
  min-width: 90vw !important;
}

.vw-90 {
  width: 90vw !important;
}

.w-95 {
  width: 95% !important;
}

.mw-95 {
  max-width: 95% !important;
}

.min-w-95 {
  min-width: 95% !important;
}

.max-vw-95 {
  max-width: 95vw !important;
}

.min-vw-95 {
  min-width: 95vw !important;
}

.vw-95 {
  width: 95vw !important;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.max-vw-100 {
  max-width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.vw-100 {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.mw-auto {
  max-width: none  !important;
}

.min-w-auto {
  min-width: auto !important;
}

.max-vw-auto {
  max-width: none !important;
}

.min-vw-auto {
  min-width: auto !important;
}

.vw-auto {
  width: auto !important;
}

.h-5 {
  height: 5% !important;
}

.mh-5 {
  max-height: 5% !important;
}

.min-h-5 {
  min-height: 5% !important;
}

.max-vh-5 {
  max-height: 5vw !important;
}

.min-vh-5 {
  min-height: 5vw !important;
}

.vh-5 {
  height: 5vw !important;
}

.h-10 {
  height: 10% !important;
}

.mh-10 {
  max-height: 10% !important;
}

.min-h-10 {
  min-height: 10% !important;
}

.max-vh-10 {
  max-height: 10vw !important;
}

.min-vh-10 {
  min-height: 10vw !important;
}

.vh-10 {
  height: 10vw !important;
}

.h-15 {
  height: 15% !important;
}

.mh-15 {
  max-height: 15% !important;
}

.min-h-15 {
  min-height: 15% !important;
}

.max-vh-15 {
  max-height: 15vw !important;
}

.min-vh-15 {
  min-height: 15vw !important;
}

.vh-15 {
  height: 15vw !important;
}

.h-20 {
  height: 20% !important;
}

.mh-20 {
  max-height: 20% !important;
}

.min-h-20 {
  min-height: 20% !important;
}

.max-vh-20 {
  max-height: 20vw !important;
}

.min-vh-20 {
  min-height: 20vw !important;
}

.vh-20 {
  height: 20vw !important;
}

.h-25 {
  height: 25% !important;
}

.mh-25 {
  max-height: 25% !important;
}

.min-h-25 {
  min-height: 25% !important;
}

.max-vh-25 {
  max-height: 25vw !important;
}

.min-vh-25 {
  min-height: 25vw !important;
}

.vh-25 {
  height: 25vw !important;
}

.h-30 {
  height: 30% !important;
}

.mh-30 {
  max-height: 30% !important;
}

.min-h-30 {
  min-height: 30% !important;
}

.max-vh-30 {
  max-height: 30vw !important;
}

.min-vh-30 {
  min-height: 30vw !important;
}

.vh-30 {
  height: 30vw !important;
}

.h-35 {
  height: 35% !important;
}

.mh-35 {
  max-height: 35% !important;
}

.min-h-35 {
  min-height: 35% !important;
}

.max-vh-35 {
  max-height: 35vw !important;
}

.min-vh-35 {
  min-height: 35vw !important;
}

.vh-35 {
  height: 35vw !important;
}

.h-40 {
  height: 40% !important;
}

.mh-40 {
  max-height: 40% !important;
}

.min-h-40 {
  min-height: 40% !important;
}

.max-vh-40 {
  max-height: 40vw !important;
}

.min-vh-40 {
  min-height: 40vw !important;
}

.vh-40 {
  height: 40vw !important;
}

.h-45 {
  height: 45% !important;
}

.mh-45 {
  max-height: 45% !important;
}

.min-h-45 {
  min-height: 45% !important;
}

.max-vh-45 {
  max-height: 45vw !important;
}

.min-vh-45 {
  min-height: 45vw !important;
}

.vh-45 {
  height: 45vw !important;
}

.h-50 {
  height: 50% !important;
}

.mh-50 {
  max-height: 50% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.max-vh-50 {
  max-height: 50vw !important;
}

.min-vh-50 {
  min-height: 50vw !important;
}

.vh-50 {
  height: 50vw !important;
}

.h-55 {
  height: 55% !important;
}

.mh-55 {
  max-height: 55% !important;
}

.min-h-55 {
  min-height: 55% !important;
}

.max-vh-55 {
  max-height: 55vw !important;
}

.min-vh-55 {
  min-height: 55vw !important;
}

.vh-55 {
  height: 55vw !important;
}

.h-60 {
  height: 60% !important;
}

.mh-60 {
  max-height: 60% !important;
}

.min-h-60 {
  min-height: 60% !important;
}

.max-vh-60 {
  max-height: 60vw !important;
}

.min-vh-60 {
  min-height: 60vw !important;
}

.vh-60 {
  height: 60vw !important;
}

.h-65 {
  height: 65% !important;
}

.mh-65 {
  max-height: 65% !important;
}

.min-h-65 {
  min-height: 65% !important;
}

.max-vh-65 {
  max-height: 65vw !important;
}

.min-vh-65 {
  min-height: 65vw !important;
}

.vh-65 {
  height: 65vw !important;
}

.h-70 {
  height: 70% !important;
}

.mh-70 {
  max-height: 70% !important;
}

.min-h-70 {
  min-height: 70% !important;
}

.max-vh-70 {
  max-height: 70vw !important;
}

.min-vh-70 {
  min-height: 70vw !important;
}

.vh-70 {
  height: 70vw !important;
}

.h-75 {
  height: 75% !important;
}

.mh-75 {
  max-height: 75% !important;
}

.min-h-75 {
  min-height: 75% !important;
}

.max-vh-75 {
  max-height: 75vw !important;
}

.min-vh-75 {
  min-height: 75vw !important;
}

.vh-75 {
  height: 75vw !important;
}

.h-80 {
  height: 80% !important;
}

.mh-80 {
  max-height: 80% !important;
}

.min-h-80 {
  min-height: 80% !important;
}

.max-vh-80 {
  max-height: 80vw !important;
}

.min-vh-80 {
  min-height: 80vw !important;
}

.vh-80 {
  height: 80vw !important;
}

.h-85 {
  height: 85% !important;
}

.mh-85 {
  max-height: 85% !important;
}

.min-h-85 {
  min-height: 85% !important;
}

.max-vh-85 {
  max-height: 85vw !important;
}

.min-vh-85 {
  min-height: 85vw !important;
}

.vh-85 {
  height: 85vw !important;
}

.h-90 {
  height: 90% !important;
}

.mh-90 {
  max-height: 90% !important;
}

.min-h-90 {
  min-height: 90% !important;
}

.max-vh-90 {
  max-height: 90vw !important;
}

.min-vh-90 {
  min-height: 90vw !important;
}

.vh-90 {
  height: 90vw !important;
}

.h-95 {
  height: 95% !important;
}

.mh-95 {
  max-height: 95% !important;
}

.min-h-95 {
  min-height: 95% !important;
}

.max-vh-95 {
  max-height: 95vw !important;
}

.min-vh-95 {
  min-height: 95vw !important;
}

.vh-95 {
  height: 95vw !important;
}

.h-100 {
  height: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.max-vh-100 {
  max-height: 100vw !important;
}

.min-vh-100 {
  min-height: 100vw !important;
}

.vh-100 {
  height: 100vw !important;
}

.h-auto {
  height: auto !important;
}

.mh-auto {
  max-height: none  !important;
}

.min-h-auto {
  min-height: auto !important;
}

.max-vh-auto {
  max-height: none !important;
}

.min-vh-auto {
  min-height: auto !important;
}

.vh-auto {
  height: auto !important;
}

.img-of-fill {
  object-fit: fill !important;
}

.img-of-contain {
  object-fit: contain !important;
}

.img-of-cover {
  object-fit: cover !important;
}

.img-of-none {
  object-fit: none !important;
}

.img-of-scale-down {
  object-fit: scale-down !important;
}

.img-op-tr {
  object-position: top right !important;
}

.img-op-tl {
  object-position: top left !important;
}

.img-op-tc {
  object-position: top center !important;
}

.img-op-rt {
  object-position: right top !important;
}

.img-op-rb {
  object-position: right bottom !important;
}

.img-op-rc {
  object-position: right center !important;
}

.img-op-br {
  object-position: bottom right !important;
}

.img-op-bl {
  object-position: bottom left !important;
}

.img-op-bc {
  object-position: bottom center !important;
}

.img-op-lt {
  object-position: left top !important;
}

.img-op-lb {
  object-position: left bottom !important;
}

.img-op-lc {
  object-position: left center !important;
}

.img-op-unset {
  object-position: unset !important;
}

.cus-sku-rt {
  top: 0.8rem !important;
  right: 0.8rem !important;
  bottom: unset !important;
  left: unset !important;
}

.cus-sku-lb {
  top: unset !important;
  right: unset !important;
  bottom: 0.8rem !important;
  left: 0.8rem !important;
}

@media (min-width: 576px) {
  .w-sm-5 {
    width: 5% !important;
  }
  .mw-sm-5 {
    max-width: 5% !important;
  }
  .min-w-sm-5 {
    min-width: 5% !important;
  }
  .max-vw-sm-5 {
    max-width: 5vw !important;
  }
  .min-vw-sm-5 {
    min-width: 5vw !important;
  }
  .vw-sm-5 {
    width: 5vw !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .mw-sm-10 {
    max-width: 10% !important;
  }
  .min-w-sm-10 {
    min-width: 10% !important;
  }
  .max-vw-sm-10 {
    max-width: 10vw !important;
  }
  .min-vw-sm-10 {
    min-width: 10vw !important;
  }
  .vw-sm-10 {
    width: 10vw !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .mw-sm-15 {
    max-width: 15% !important;
  }
  .min-w-sm-15 {
    min-width: 15% !important;
  }
  .max-vw-sm-15 {
    max-width: 15vw !important;
  }
  .min-vw-sm-15 {
    min-width: 15vw !important;
  }
  .vw-sm-15 {
    width: 15vw !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .mw-sm-20 {
    max-width: 20% !important;
  }
  .min-w-sm-20 {
    min-width: 20% !important;
  }
  .max-vw-sm-20 {
    max-width: 20vw !important;
  }
  .min-vw-sm-20 {
    min-width: 20vw !important;
  }
  .vw-sm-20 {
    width: 20vw !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .mw-sm-25 {
    max-width: 25% !important;
  }
  .min-w-sm-25 {
    min-width: 25% !important;
  }
  .max-vw-sm-25 {
    max-width: 25vw !important;
  }
  .min-vw-sm-25 {
    min-width: 25vw !important;
  }
  .vw-sm-25 {
    width: 25vw !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .mw-sm-30 {
    max-width: 30% !important;
  }
  .min-w-sm-30 {
    min-width: 30% !important;
  }
  .max-vw-sm-30 {
    max-width: 30vw !important;
  }
  .min-vw-sm-30 {
    min-width: 30vw !important;
  }
  .vw-sm-30 {
    width: 30vw !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .mw-sm-35 {
    max-width: 35% !important;
  }
  .min-w-sm-35 {
    min-width: 35% !important;
  }
  .max-vw-sm-35 {
    max-width: 35vw !important;
  }
  .min-vw-sm-35 {
    min-width: 35vw !important;
  }
  .vw-sm-35 {
    width: 35vw !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .mw-sm-40 {
    max-width: 40% !important;
  }
  .min-w-sm-40 {
    min-width: 40% !important;
  }
  .max-vw-sm-40 {
    max-width: 40vw !important;
  }
  .min-vw-sm-40 {
    min-width: 40vw !important;
  }
  .vw-sm-40 {
    width: 40vw !important;
  }
  .w-sm-45 {
    width: 45% !important;
  }
  .mw-sm-45 {
    max-width: 45% !important;
  }
  .min-w-sm-45 {
    min-width: 45% !important;
  }
  .max-vw-sm-45 {
    max-width: 45vw !important;
  }
  .min-vw-sm-45 {
    min-width: 45vw !important;
  }
  .vw-sm-45 {
    width: 45vw !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .mw-sm-50 {
    max-width: 50% !important;
  }
  .min-w-sm-50 {
    min-width: 50% !important;
  }
  .max-vw-sm-50 {
    max-width: 50vw !important;
  }
  .min-vw-sm-50 {
    min-width: 50vw !important;
  }
  .vw-sm-50 {
    width: 50vw !important;
  }
  .w-sm-55 {
    width: 55% !important;
  }
  .mw-sm-55 {
    max-width: 55% !important;
  }
  .min-w-sm-55 {
    min-width: 55% !important;
  }
  .max-vw-sm-55 {
    max-width: 55vw !important;
  }
  .min-vw-sm-55 {
    min-width: 55vw !important;
  }
  .vw-sm-55 {
    width: 55vw !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .mw-sm-60 {
    max-width: 60% !important;
  }
  .min-w-sm-60 {
    min-width: 60% !important;
  }
  .max-vw-sm-60 {
    max-width: 60vw !important;
  }
  .min-vw-sm-60 {
    min-width: 60vw !important;
  }
  .vw-sm-60 {
    width: 60vw !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .mw-sm-65 {
    max-width: 65% !important;
  }
  .min-w-sm-65 {
    min-width: 65% !important;
  }
  .max-vw-sm-65 {
    max-width: 65vw !important;
  }
  .min-vw-sm-65 {
    min-width: 65vw !important;
  }
  .vw-sm-65 {
    width: 65vw !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .mw-sm-70 {
    max-width: 70% !important;
  }
  .min-w-sm-70 {
    min-width: 70% !important;
  }
  .max-vw-sm-70 {
    max-width: 70vw !important;
  }
  .min-vw-sm-70 {
    min-width: 70vw !important;
  }
  .vw-sm-70 {
    width: 70vw !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .mw-sm-75 {
    max-width: 75% !important;
  }
  .min-w-sm-75 {
    min-width: 75% !important;
  }
  .max-vw-sm-75 {
    max-width: 75vw !important;
  }
  .min-vw-sm-75 {
    min-width: 75vw !important;
  }
  .vw-sm-75 {
    width: 75vw !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .mw-sm-80 {
    max-width: 80% !important;
  }
  .min-w-sm-80 {
    min-width: 80% !important;
  }
  .max-vw-sm-80 {
    max-width: 80vw !important;
  }
  .min-vw-sm-80 {
    min-width: 80vw !important;
  }
  .vw-sm-80 {
    width: 80vw !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
  .mw-sm-85 {
    max-width: 85% !important;
  }
  .min-w-sm-85 {
    min-width: 85% !important;
  }
  .max-vw-sm-85 {
    max-width: 85vw !important;
  }
  .min-vw-sm-85 {
    min-width: 85vw !important;
  }
  .vw-sm-85 {
    width: 85vw !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .mw-sm-90 {
    max-width: 90% !important;
  }
  .min-w-sm-90 {
    min-width: 90% !important;
  }
  .max-vw-sm-90 {
    max-width: 90vw !important;
  }
  .min-vw-sm-90 {
    min-width: 90vw !important;
  }
  .vw-sm-90 {
    width: 90vw !important;
  }
  .w-sm-95 {
    width: 95% !important;
  }
  .mw-sm-95 {
    max-width: 95% !important;
  }
  .min-w-sm-95 {
    min-width: 95% !important;
  }
  .max-vw-sm-95 {
    max-width: 95vw !important;
  }
  .min-vw-sm-95 {
    min-width: 95vw !important;
  }
  .vw-sm-95 {
    width: 95vw !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .mw-sm-100 {
    max-width: 100% !important;
  }
  .min-w-sm-100 {
    min-width: 100% !important;
  }
  .max-vw-sm-100 {
    max-width: 100vw !important;
  }
  .min-vw-sm-100 {
    min-width: 100vw !important;
  }
  .vw-sm-100 {
    width: 100vw !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .mw-sm-auto {
    max-width: none  !important;
  }
  .min-w-sm-auto {
    min-width: auto !important;
  }
  .max-vw-sm-auto {
    max-width: none !important;
  }
  .min-vw-sm-auto {
    min-width: auto !important;
  }
  .vw-sm-auto {
    width: auto !important;
  }
  .h-sm-5 {
    height: 5% !important;
  }
  .mh-sm-5 {
    max-height: 5% !important;
  }
  .min-h-sm-5 {
    min-height: 5% !important;
  }
  .max-vh-sm-5 {
    max-height: 5vw !important;
  }
  .min-vh-sm-5 {
    min-height: 5vw !important;
  }
  .vh-sm-5 {
    height: 5vw !important;
  }
  .h-sm-10 {
    height: 10% !important;
  }
  .mh-sm-10 {
    max-height: 10% !important;
  }
  .min-h-sm-10 {
    min-height: 10% !important;
  }
  .max-vh-sm-10 {
    max-height: 10vw !important;
  }
  .min-vh-sm-10 {
    min-height: 10vw !important;
  }
  .vh-sm-10 {
    height: 10vw !important;
  }
  .h-sm-15 {
    height: 15% !important;
  }
  .mh-sm-15 {
    max-height: 15% !important;
  }
  .min-h-sm-15 {
    min-height: 15% !important;
  }
  .max-vh-sm-15 {
    max-height: 15vw !important;
  }
  .min-vh-sm-15 {
    min-height: 15vw !important;
  }
  .vh-sm-15 {
    height: 15vw !important;
  }
  .h-sm-20 {
    height: 20% !important;
  }
  .mh-sm-20 {
    max-height: 20% !important;
  }
  .min-h-sm-20 {
    min-height: 20% !important;
  }
  .max-vh-sm-20 {
    max-height: 20vw !important;
  }
  .min-vh-sm-20 {
    min-height: 20vw !important;
  }
  .vh-sm-20 {
    height: 20vw !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .mh-sm-25 {
    max-height: 25% !important;
  }
  .min-h-sm-25 {
    min-height: 25% !important;
  }
  .max-vh-sm-25 {
    max-height: 25vw !important;
  }
  .min-vh-sm-25 {
    min-height: 25vw !important;
  }
  .vh-sm-25 {
    height: 25vw !important;
  }
  .h-sm-30 {
    height: 30% !important;
  }
  .mh-sm-30 {
    max-height: 30% !important;
  }
  .min-h-sm-30 {
    min-height: 30% !important;
  }
  .max-vh-sm-30 {
    max-height: 30vw !important;
  }
  .min-vh-sm-30 {
    min-height: 30vw !important;
  }
  .vh-sm-30 {
    height: 30vw !important;
  }
  .h-sm-35 {
    height: 35% !important;
  }
  .mh-sm-35 {
    max-height: 35% !important;
  }
  .min-h-sm-35 {
    min-height: 35% !important;
  }
  .max-vh-sm-35 {
    max-height: 35vw !important;
  }
  .min-vh-sm-35 {
    min-height: 35vw !important;
  }
  .vh-sm-35 {
    height: 35vw !important;
  }
  .h-sm-40 {
    height: 40% !important;
  }
  .mh-sm-40 {
    max-height: 40% !important;
  }
  .min-h-sm-40 {
    min-height: 40% !important;
  }
  .max-vh-sm-40 {
    max-height: 40vw !important;
  }
  .min-vh-sm-40 {
    min-height: 40vw !important;
  }
  .vh-sm-40 {
    height: 40vw !important;
  }
  .h-sm-45 {
    height: 45% !important;
  }
  .mh-sm-45 {
    max-height: 45% !important;
  }
  .min-h-sm-45 {
    min-height: 45% !important;
  }
  .max-vh-sm-45 {
    max-height: 45vw !important;
  }
  .min-vh-sm-45 {
    min-height: 45vw !important;
  }
  .vh-sm-45 {
    height: 45vw !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .mh-sm-50 {
    max-height: 50% !important;
  }
  .min-h-sm-50 {
    min-height: 50% !important;
  }
  .max-vh-sm-50 {
    max-height: 50vw !important;
  }
  .min-vh-sm-50 {
    min-height: 50vw !important;
  }
  .vh-sm-50 {
    height: 50vw !important;
  }
  .h-sm-55 {
    height: 55% !important;
  }
  .mh-sm-55 {
    max-height: 55% !important;
  }
  .min-h-sm-55 {
    min-height: 55% !important;
  }
  .max-vh-sm-55 {
    max-height: 55vw !important;
  }
  .min-vh-sm-55 {
    min-height: 55vw !important;
  }
  .vh-sm-55 {
    height: 55vw !important;
  }
  .h-sm-60 {
    height: 60% !important;
  }
  .mh-sm-60 {
    max-height: 60% !important;
  }
  .min-h-sm-60 {
    min-height: 60% !important;
  }
  .max-vh-sm-60 {
    max-height: 60vw !important;
  }
  .min-vh-sm-60 {
    min-height: 60vw !important;
  }
  .vh-sm-60 {
    height: 60vw !important;
  }
  .h-sm-65 {
    height: 65% !important;
  }
  .mh-sm-65 {
    max-height: 65% !important;
  }
  .min-h-sm-65 {
    min-height: 65% !important;
  }
  .max-vh-sm-65 {
    max-height: 65vw !important;
  }
  .min-vh-sm-65 {
    min-height: 65vw !important;
  }
  .vh-sm-65 {
    height: 65vw !important;
  }
  .h-sm-70 {
    height: 70% !important;
  }
  .mh-sm-70 {
    max-height: 70% !important;
  }
  .min-h-sm-70 {
    min-height: 70% !important;
  }
  .max-vh-sm-70 {
    max-height: 70vw !important;
  }
  .min-vh-sm-70 {
    min-height: 70vw !important;
  }
  .vh-sm-70 {
    height: 70vw !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .mh-sm-75 {
    max-height: 75% !important;
  }
  .min-h-sm-75 {
    min-height: 75% !important;
  }
  .max-vh-sm-75 {
    max-height: 75vw !important;
  }
  .min-vh-sm-75 {
    min-height: 75vw !important;
  }
  .vh-sm-75 {
    height: 75vw !important;
  }
  .h-sm-80 {
    height: 80% !important;
  }
  .mh-sm-80 {
    max-height: 80% !important;
  }
  .min-h-sm-80 {
    min-height: 80% !important;
  }
  .max-vh-sm-80 {
    max-height: 80vw !important;
  }
  .min-vh-sm-80 {
    min-height: 80vw !important;
  }
  .vh-sm-80 {
    height: 80vw !important;
  }
  .h-sm-85 {
    height: 85% !important;
  }
  .mh-sm-85 {
    max-height: 85% !important;
  }
  .min-h-sm-85 {
    min-height: 85% !important;
  }
  .max-vh-sm-85 {
    max-height: 85vw !important;
  }
  .min-vh-sm-85 {
    min-height: 85vw !important;
  }
  .vh-sm-85 {
    height: 85vw !important;
  }
  .h-sm-90 {
    height: 90% !important;
  }
  .mh-sm-90 {
    max-height: 90% !important;
  }
  .min-h-sm-90 {
    min-height: 90% !important;
  }
  .max-vh-sm-90 {
    max-height: 90vw !important;
  }
  .min-vh-sm-90 {
    min-height: 90vw !important;
  }
  .vh-sm-90 {
    height: 90vw !important;
  }
  .h-sm-95 {
    height: 95% !important;
  }
  .mh-sm-95 {
    max-height: 95% !important;
  }
  .min-h-sm-95 {
    min-height: 95% !important;
  }
  .max-vh-sm-95 {
    max-height: 95vw !important;
  }
  .min-vh-sm-95 {
    min-height: 95vw !important;
  }
  .vh-sm-95 {
    height: 95vw !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .mh-sm-100 {
    max-height: 100% !important;
  }
  .min-h-sm-100 {
    min-height: 100% !important;
  }
  .max-vh-sm-100 {
    max-height: 100vw !important;
  }
  .min-vh-sm-100 {
    min-height: 100vw !important;
  }
  .vh-sm-100 {
    height: 100vw !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .mh-sm-auto {
    max-height: none  !important;
  }
  .min-h-sm-auto {
    min-height: auto !important;
  }
  .max-vh-sm-auto {
    max-height: none !important;
  }
  .min-vh-sm-auto {
    min-height: auto !important;
  }
  .vh-sm-auto {
    height: auto !important;
  }
  .img-of-sm-fill {
    object-fit: fill !important;
  }
  .img-of-sm-contain {
    object-fit: contain !important;
  }
  .img-of-sm-cover {
    object-fit: cover !important;
  }
  .img-of-sm-none {
    object-fit: none !important;
  }
  .img-of-sm-scale-down {
    object-fit: scale-down !important;
  }
  .img-op-sm-tr {
    object-position: top right !important;
  }
  .img-op-sm-tl {
    object-position: top left !important;
  }
  .img-op-sm-tc {
    object-position: top center !important;
  }
  .img-op-sm-rt {
    object-position: right top !important;
  }
  .img-op-sm-rb {
    object-position: right bottom !important;
  }
  .img-op-sm-rc {
    object-position: right center !important;
  }
  .img-op-sm-br {
    object-position: bottom right !important;
  }
  .img-op-sm-bl {
    object-position: bottom left !important;
  }
  .img-op-sm-bc {
    object-position: bottom center !important;
  }
  .img-op-sm-lt {
    object-position: left top !important;
  }
  .img-op-sm-lb {
    object-position: left bottom !important;
  }
  .img-op-sm-lc {
    object-position: left center !important;
  }
  .img-op-sm-unset {
    object-position: unset !important;
  }
  .cus-sku-sm-rt {
    top: 0.8rem !important;
    right: 0.8rem !important;
    bottom: unset !important;
    left: unset !important;
  }
  .cus-sku-sm-lb {
    top: unset !important;
    right: unset !important;
    bottom: 0.8rem !important;
    left: 0.8rem !important;
  }
}

@media (min-width: 768px) {
  .w-md-5 {
    width: 5% !important;
  }
  .mw-md-5 {
    max-width: 5% !important;
  }
  .min-w-md-5 {
    min-width: 5% !important;
  }
  .max-vw-md-5 {
    max-width: 5vw !important;
  }
  .min-vw-md-5 {
    min-width: 5vw !important;
  }
  .vw-md-5 {
    width: 5vw !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .mw-md-10 {
    max-width: 10% !important;
  }
  .min-w-md-10 {
    min-width: 10% !important;
  }
  .max-vw-md-10 {
    max-width: 10vw !important;
  }
  .min-vw-md-10 {
    min-width: 10vw !important;
  }
  .vw-md-10 {
    width: 10vw !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .mw-md-15 {
    max-width: 15% !important;
  }
  .min-w-md-15 {
    min-width: 15% !important;
  }
  .max-vw-md-15 {
    max-width: 15vw !important;
  }
  .min-vw-md-15 {
    min-width: 15vw !important;
  }
  .vw-md-15 {
    width: 15vw !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .mw-md-20 {
    max-width: 20% !important;
  }
  .min-w-md-20 {
    min-width: 20% !important;
  }
  .max-vw-md-20 {
    max-width: 20vw !important;
  }
  .min-vw-md-20 {
    min-width: 20vw !important;
  }
  .vw-md-20 {
    width: 20vw !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .mw-md-25 {
    max-width: 25% !important;
  }
  .min-w-md-25 {
    min-width: 25% !important;
  }
  .max-vw-md-25 {
    max-width: 25vw !important;
  }
  .min-vw-md-25 {
    min-width: 25vw !important;
  }
  .vw-md-25 {
    width: 25vw !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .mw-md-30 {
    max-width: 30% !important;
  }
  .min-w-md-30 {
    min-width: 30% !important;
  }
  .max-vw-md-30 {
    max-width: 30vw !important;
  }
  .min-vw-md-30 {
    min-width: 30vw !important;
  }
  .vw-md-30 {
    width: 30vw !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .mw-md-35 {
    max-width: 35% !important;
  }
  .min-w-md-35 {
    min-width: 35% !important;
  }
  .max-vw-md-35 {
    max-width: 35vw !important;
  }
  .min-vw-md-35 {
    min-width: 35vw !important;
  }
  .vw-md-35 {
    width: 35vw !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .mw-md-40 {
    max-width: 40% !important;
  }
  .min-w-md-40 {
    min-width: 40% !important;
  }
  .max-vw-md-40 {
    max-width: 40vw !important;
  }
  .min-vw-md-40 {
    min-width: 40vw !important;
  }
  .vw-md-40 {
    width: 40vw !important;
  }
  .w-md-45 {
    width: 45% !important;
  }
  .mw-md-45 {
    max-width: 45% !important;
  }
  .min-w-md-45 {
    min-width: 45% !important;
  }
  .max-vw-md-45 {
    max-width: 45vw !important;
  }
  .min-vw-md-45 {
    min-width: 45vw !important;
  }
  .vw-md-45 {
    width: 45vw !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .mw-md-50 {
    max-width: 50% !important;
  }
  .min-w-md-50 {
    min-width: 50% !important;
  }
  .max-vw-md-50 {
    max-width: 50vw !important;
  }
  .min-vw-md-50 {
    min-width: 50vw !important;
  }
  .vw-md-50 {
    width: 50vw !important;
  }
  .w-md-55 {
    width: 55% !important;
  }
  .mw-md-55 {
    max-width: 55% !important;
  }
  .min-w-md-55 {
    min-width: 55% !important;
  }
  .max-vw-md-55 {
    max-width: 55vw !important;
  }
  .min-vw-md-55 {
    min-width: 55vw !important;
  }
  .vw-md-55 {
    width: 55vw !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .mw-md-60 {
    max-width: 60% !important;
  }
  .min-w-md-60 {
    min-width: 60% !important;
  }
  .max-vw-md-60 {
    max-width: 60vw !important;
  }
  .min-vw-md-60 {
    min-width: 60vw !important;
  }
  .vw-md-60 {
    width: 60vw !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .mw-md-65 {
    max-width: 65% !important;
  }
  .min-w-md-65 {
    min-width: 65% !important;
  }
  .max-vw-md-65 {
    max-width: 65vw !important;
  }
  .min-vw-md-65 {
    min-width: 65vw !important;
  }
  .vw-md-65 {
    width: 65vw !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .mw-md-70 {
    max-width: 70% !important;
  }
  .min-w-md-70 {
    min-width: 70% !important;
  }
  .max-vw-md-70 {
    max-width: 70vw !important;
  }
  .min-vw-md-70 {
    min-width: 70vw !important;
  }
  .vw-md-70 {
    width: 70vw !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .mw-md-75 {
    max-width: 75% !important;
  }
  .min-w-md-75 {
    min-width: 75% !important;
  }
  .max-vw-md-75 {
    max-width: 75vw !important;
  }
  .min-vw-md-75 {
    min-width: 75vw !important;
  }
  .vw-md-75 {
    width: 75vw !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .mw-md-80 {
    max-width: 80% !important;
  }
  .min-w-md-80 {
    min-width: 80% !important;
  }
  .max-vw-md-80 {
    max-width: 80vw !important;
  }
  .min-vw-md-80 {
    min-width: 80vw !important;
  }
  .vw-md-80 {
    width: 80vw !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .mw-md-85 {
    max-width: 85% !important;
  }
  .min-w-md-85 {
    min-width: 85% !important;
  }
  .max-vw-md-85 {
    max-width: 85vw !important;
  }
  .min-vw-md-85 {
    min-width: 85vw !important;
  }
  .vw-md-85 {
    width: 85vw !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .mw-md-90 {
    max-width: 90% !important;
  }
  .min-w-md-90 {
    min-width: 90% !important;
  }
  .max-vw-md-90 {
    max-width: 90vw !important;
  }
  .min-vw-md-90 {
    min-width: 90vw !important;
  }
  .vw-md-90 {
    width: 90vw !important;
  }
  .w-md-95 {
    width: 95% !important;
  }
  .mw-md-95 {
    max-width: 95% !important;
  }
  .min-w-md-95 {
    min-width: 95% !important;
  }
  .max-vw-md-95 {
    max-width: 95vw !important;
  }
  .min-vw-md-95 {
    min-width: 95vw !important;
  }
  .vw-md-95 {
    width: 95vw !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .mw-md-100 {
    max-width: 100% !important;
  }
  .min-w-md-100 {
    min-width: 100% !important;
  }
  .max-vw-md-100 {
    max-width: 100vw !important;
  }
  .min-vw-md-100 {
    min-width: 100vw !important;
  }
  .vw-md-100 {
    width: 100vw !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .mw-md-auto {
    max-width: none  !important;
  }
  .min-w-md-auto {
    min-width: auto !important;
  }
  .max-vw-md-auto {
    max-width: none !important;
  }
  .min-vw-md-auto {
    min-width: auto !important;
  }
  .vw-md-auto {
    width: auto !important;
  }
  .h-md-5 {
    height: 5% !important;
  }
  .mh-md-5 {
    max-height: 5% !important;
  }
  .min-h-md-5 {
    min-height: 5% !important;
  }
  .max-vh-md-5 {
    max-height: 5vw !important;
  }
  .min-vh-md-5 {
    min-height: 5vw !important;
  }
  .vh-md-5 {
    height: 5vw !important;
  }
  .h-md-10 {
    height: 10% !important;
  }
  .mh-md-10 {
    max-height: 10% !important;
  }
  .min-h-md-10 {
    min-height: 10% !important;
  }
  .max-vh-md-10 {
    max-height: 10vw !important;
  }
  .min-vh-md-10 {
    min-height: 10vw !important;
  }
  .vh-md-10 {
    height: 10vw !important;
  }
  .h-md-15 {
    height: 15% !important;
  }
  .mh-md-15 {
    max-height: 15% !important;
  }
  .min-h-md-15 {
    min-height: 15% !important;
  }
  .max-vh-md-15 {
    max-height: 15vw !important;
  }
  .min-vh-md-15 {
    min-height: 15vw !important;
  }
  .vh-md-15 {
    height: 15vw !important;
  }
  .h-md-20 {
    height: 20% !important;
  }
  .mh-md-20 {
    max-height: 20% !important;
  }
  .min-h-md-20 {
    min-height: 20% !important;
  }
  .max-vh-md-20 {
    max-height: 20vw !important;
  }
  .min-vh-md-20 {
    min-height: 20vw !important;
  }
  .vh-md-20 {
    height: 20vw !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .mh-md-25 {
    max-height: 25% !important;
  }
  .min-h-md-25 {
    min-height: 25% !important;
  }
  .max-vh-md-25 {
    max-height: 25vw !important;
  }
  .min-vh-md-25 {
    min-height: 25vw !important;
  }
  .vh-md-25 {
    height: 25vw !important;
  }
  .h-md-30 {
    height: 30% !important;
  }
  .mh-md-30 {
    max-height: 30% !important;
  }
  .min-h-md-30 {
    min-height: 30% !important;
  }
  .max-vh-md-30 {
    max-height: 30vw !important;
  }
  .min-vh-md-30 {
    min-height: 30vw !important;
  }
  .vh-md-30 {
    height: 30vw !important;
  }
  .h-md-35 {
    height: 35% !important;
  }
  .mh-md-35 {
    max-height: 35% !important;
  }
  .min-h-md-35 {
    min-height: 35% !important;
  }
  .max-vh-md-35 {
    max-height: 35vw !important;
  }
  .min-vh-md-35 {
    min-height: 35vw !important;
  }
  .vh-md-35 {
    height: 35vw !important;
  }
  .h-md-40 {
    height: 40% !important;
  }
  .mh-md-40 {
    max-height: 40% !important;
  }
  .min-h-md-40 {
    min-height: 40% !important;
  }
  .max-vh-md-40 {
    max-height: 40vw !important;
  }
  .min-vh-md-40 {
    min-height: 40vw !important;
  }
  .vh-md-40 {
    height: 40vw !important;
  }
  .h-md-45 {
    height: 45% !important;
  }
  .mh-md-45 {
    max-height: 45% !important;
  }
  .min-h-md-45 {
    min-height: 45% !important;
  }
  .max-vh-md-45 {
    max-height: 45vw !important;
  }
  .min-vh-md-45 {
    min-height: 45vw !important;
  }
  .vh-md-45 {
    height: 45vw !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .mh-md-50 {
    max-height: 50% !important;
  }
  .min-h-md-50 {
    min-height: 50% !important;
  }
  .max-vh-md-50 {
    max-height: 50vw !important;
  }
  .min-vh-md-50 {
    min-height: 50vw !important;
  }
  .vh-md-50 {
    height: 50vw !important;
  }
  .h-md-55 {
    height: 55% !important;
  }
  .mh-md-55 {
    max-height: 55% !important;
  }
  .min-h-md-55 {
    min-height: 55% !important;
  }
  .max-vh-md-55 {
    max-height: 55vw !important;
  }
  .min-vh-md-55 {
    min-height: 55vw !important;
  }
  .vh-md-55 {
    height: 55vw !important;
  }
  .h-md-60 {
    height: 60% !important;
  }
  .mh-md-60 {
    max-height: 60% !important;
  }
  .min-h-md-60 {
    min-height: 60% !important;
  }
  .max-vh-md-60 {
    max-height: 60vw !important;
  }
  .min-vh-md-60 {
    min-height: 60vw !important;
  }
  .vh-md-60 {
    height: 60vw !important;
  }
  .h-md-65 {
    height: 65% !important;
  }
  .mh-md-65 {
    max-height: 65% !important;
  }
  .min-h-md-65 {
    min-height: 65% !important;
  }
  .max-vh-md-65 {
    max-height: 65vw !important;
  }
  .min-vh-md-65 {
    min-height: 65vw !important;
  }
  .vh-md-65 {
    height: 65vw !important;
  }
  .h-md-70 {
    height: 70% !important;
  }
  .mh-md-70 {
    max-height: 70% !important;
  }
  .min-h-md-70 {
    min-height: 70% !important;
  }
  .max-vh-md-70 {
    max-height: 70vw !important;
  }
  .min-vh-md-70 {
    min-height: 70vw !important;
  }
  .vh-md-70 {
    height: 70vw !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .mh-md-75 {
    max-height: 75% !important;
  }
  .min-h-md-75 {
    min-height: 75% !important;
  }
  .max-vh-md-75 {
    max-height: 75vw !important;
  }
  .min-vh-md-75 {
    min-height: 75vw !important;
  }
  .vh-md-75 {
    height: 75vw !important;
  }
  .h-md-80 {
    height: 80% !important;
  }
  .mh-md-80 {
    max-height: 80% !important;
  }
  .min-h-md-80 {
    min-height: 80% !important;
  }
  .max-vh-md-80 {
    max-height: 80vw !important;
  }
  .min-vh-md-80 {
    min-height: 80vw !important;
  }
  .vh-md-80 {
    height: 80vw !important;
  }
  .h-md-85 {
    height: 85% !important;
  }
  .mh-md-85 {
    max-height: 85% !important;
  }
  .min-h-md-85 {
    min-height: 85% !important;
  }
  .max-vh-md-85 {
    max-height: 85vw !important;
  }
  .min-vh-md-85 {
    min-height: 85vw !important;
  }
  .vh-md-85 {
    height: 85vw !important;
  }
  .h-md-90 {
    height: 90% !important;
  }
  .mh-md-90 {
    max-height: 90% !important;
  }
  .min-h-md-90 {
    min-height: 90% !important;
  }
  .max-vh-md-90 {
    max-height: 90vw !important;
  }
  .min-vh-md-90 {
    min-height: 90vw !important;
  }
  .vh-md-90 {
    height: 90vw !important;
  }
  .h-md-95 {
    height: 95% !important;
  }
  .mh-md-95 {
    max-height: 95% !important;
  }
  .min-h-md-95 {
    min-height: 95% !important;
  }
  .max-vh-md-95 {
    max-height: 95vw !important;
  }
  .min-vh-md-95 {
    min-height: 95vw !important;
  }
  .vh-md-95 {
    height: 95vw !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .mh-md-100 {
    max-height: 100% !important;
  }
  .min-h-md-100 {
    min-height: 100% !important;
  }
  .max-vh-md-100 {
    max-height: 100vw !important;
  }
  .min-vh-md-100 {
    min-height: 100vw !important;
  }
  .vh-md-100 {
    height: 100vw !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .mh-md-auto {
    max-height: none  !important;
  }
  .min-h-md-auto {
    min-height: auto !important;
  }
  .max-vh-md-auto {
    max-height: none !important;
  }
  .min-vh-md-auto {
    min-height: auto !important;
  }
  .vh-md-auto {
    height: auto !important;
  }
  .img-of-md-fill {
    object-fit: fill !important;
  }
  .img-of-md-contain {
    object-fit: contain !important;
  }
  .img-of-md-cover {
    object-fit: cover !important;
  }
  .img-of-md-none {
    object-fit: none !important;
  }
  .img-of-md-scale-down {
    object-fit: scale-down !important;
  }
  .img-op-md-tr {
    object-position: top right !important;
  }
  .img-op-md-tl {
    object-position: top left !important;
  }
  .img-op-md-tc {
    object-position: top center !important;
  }
  .img-op-md-rt {
    object-position: right top !important;
  }
  .img-op-md-rb {
    object-position: right bottom !important;
  }
  .img-op-md-rc {
    object-position: right center !important;
  }
  .img-op-md-br {
    object-position: bottom right !important;
  }
  .img-op-md-bl {
    object-position: bottom left !important;
  }
  .img-op-md-bc {
    object-position: bottom center !important;
  }
  .img-op-md-lt {
    object-position: left top !important;
  }
  .img-op-md-lb {
    object-position: left bottom !important;
  }
  .img-op-md-lc {
    object-position: left center !important;
  }
  .img-op-md-unset {
    object-position: unset !important;
  }
  .cus-sku-md-rt {
    top: 0.8rem !important;
    right: 0.8rem !important;
    bottom: unset !important;
    left: unset !important;
  }
  .cus-sku-md-lb {
    top: unset !important;
    right: unset !important;
    bottom: 0.8rem !important;
    left: 0.8rem !important;
  }
}

@media (min-width: 992px) {
  .w-lg-5 {
    width: 5% !important;
  }
  .mw-lg-5 {
    max-width: 5% !important;
  }
  .min-w-lg-5 {
    min-width: 5% !important;
  }
  .max-vw-lg-5 {
    max-width: 5vw !important;
  }
  .min-vw-lg-5 {
    min-width: 5vw !important;
  }
  .vw-lg-5 {
    width: 5vw !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .mw-lg-10 {
    max-width: 10% !important;
  }
  .min-w-lg-10 {
    min-width: 10% !important;
  }
  .max-vw-lg-10 {
    max-width: 10vw !important;
  }
  .min-vw-lg-10 {
    min-width: 10vw !important;
  }
  .vw-lg-10 {
    width: 10vw !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .mw-lg-15 {
    max-width: 15% !important;
  }
  .min-w-lg-15 {
    min-width: 15% !important;
  }
  .max-vw-lg-15 {
    max-width: 15vw !important;
  }
  .min-vw-lg-15 {
    min-width: 15vw !important;
  }
  .vw-lg-15 {
    width: 15vw !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .mw-lg-20 {
    max-width: 20% !important;
  }
  .min-w-lg-20 {
    min-width: 20% !important;
  }
  .max-vw-lg-20 {
    max-width: 20vw !important;
  }
  .min-vw-lg-20 {
    min-width: 20vw !important;
  }
  .vw-lg-20 {
    width: 20vw !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .mw-lg-25 {
    max-width: 25% !important;
  }
  .min-w-lg-25 {
    min-width: 25% !important;
  }
  .max-vw-lg-25 {
    max-width: 25vw !important;
  }
  .min-vw-lg-25 {
    min-width: 25vw !important;
  }
  .vw-lg-25 {
    width: 25vw !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .mw-lg-30 {
    max-width: 30% !important;
  }
  .min-w-lg-30 {
    min-width: 30% !important;
  }
  .max-vw-lg-30 {
    max-width: 30vw !important;
  }
  .min-vw-lg-30 {
    min-width: 30vw !important;
  }
  .vw-lg-30 {
    width: 30vw !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .mw-lg-35 {
    max-width: 35% !important;
  }
  .min-w-lg-35 {
    min-width: 35% !important;
  }
  .max-vw-lg-35 {
    max-width: 35vw !important;
  }
  .min-vw-lg-35 {
    min-width: 35vw !important;
  }
  .vw-lg-35 {
    width: 35vw !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .mw-lg-40 {
    max-width: 40% !important;
  }
  .min-w-lg-40 {
    min-width: 40% !important;
  }
  .max-vw-lg-40 {
    max-width: 40vw !important;
  }
  .min-vw-lg-40 {
    min-width: 40vw !important;
  }
  .vw-lg-40 {
    width: 40vw !important;
  }
  .w-lg-45 {
    width: 45% !important;
  }
  .mw-lg-45 {
    max-width: 45% !important;
  }
  .min-w-lg-45 {
    min-width: 45% !important;
  }
  .max-vw-lg-45 {
    max-width: 45vw !important;
  }
  .min-vw-lg-45 {
    min-width: 45vw !important;
  }
  .vw-lg-45 {
    width: 45vw !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .mw-lg-50 {
    max-width: 50% !important;
  }
  .min-w-lg-50 {
    min-width: 50% !important;
  }
  .max-vw-lg-50 {
    max-width: 50vw !important;
  }
  .min-vw-lg-50 {
    min-width: 50vw !important;
  }
  .vw-lg-50 {
    width: 50vw !important;
  }
  .w-lg-55 {
    width: 55% !important;
  }
  .mw-lg-55 {
    max-width: 55% !important;
  }
  .min-w-lg-55 {
    min-width: 55% !important;
  }
  .max-vw-lg-55 {
    max-width: 55vw !important;
  }
  .min-vw-lg-55 {
    min-width: 55vw !important;
  }
  .vw-lg-55 {
    width: 55vw !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .mw-lg-60 {
    max-width: 60% !important;
  }
  .min-w-lg-60 {
    min-width: 60% !important;
  }
  .max-vw-lg-60 {
    max-width: 60vw !important;
  }
  .min-vw-lg-60 {
    min-width: 60vw !important;
  }
  .vw-lg-60 {
    width: 60vw !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .mw-lg-65 {
    max-width: 65% !important;
  }
  .min-w-lg-65 {
    min-width: 65% !important;
  }
  .max-vw-lg-65 {
    max-width: 65vw !important;
  }
  .min-vw-lg-65 {
    min-width: 65vw !important;
  }
  .vw-lg-65 {
    width: 65vw !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .mw-lg-70 {
    max-width: 70% !important;
  }
  .min-w-lg-70 {
    min-width: 70% !important;
  }
  .max-vw-lg-70 {
    max-width: 70vw !important;
  }
  .min-vw-lg-70 {
    min-width: 70vw !important;
  }
  .vw-lg-70 {
    width: 70vw !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .mw-lg-75 {
    max-width: 75% !important;
  }
  .min-w-lg-75 {
    min-width: 75% !important;
  }
  .max-vw-lg-75 {
    max-width: 75vw !important;
  }
  .min-vw-lg-75 {
    min-width: 75vw !important;
  }
  .vw-lg-75 {
    width: 75vw !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .mw-lg-80 {
    max-width: 80% !important;
  }
  .min-w-lg-80 {
    min-width: 80% !important;
  }
  .max-vw-lg-80 {
    max-width: 80vw !important;
  }
  .min-vw-lg-80 {
    min-width: 80vw !important;
  }
  .vw-lg-80 {
    width: 80vw !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .mw-lg-85 {
    max-width: 85% !important;
  }
  .min-w-lg-85 {
    min-width: 85% !important;
  }
  .max-vw-lg-85 {
    max-width: 85vw !important;
  }
  .min-vw-lg-85 {
    min-width: 85vw !important;
  }
  .vw-lg-85 {
    width: 85vw !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .mw-lg-90 {
    max-width: 90% !important;
  }
  .min-w-lg-90 {
    min-width: 90% !important;
  }
  .max-vw-lg-90 {
    max-width: 90vw !important;
  }
  .min-vw-lg-90 {
    min-width: 90vw !important;
  }
  .vw-lg-90 {
    width: 90vw !important;
  }
  .w-lg-95 {
    width: 95% !important;
  }
  .mw-lg-95 {
    max-width: 95% !important;
  }
  .min-w-lg-95 {
    min-width: 95% !important;
  }
  .max-vw-lg-95 {
    max-width: 95vw !important;
  }
  .min-vw-lg-95 {
    min-width: 95vw !important;
  }
  .vw-lg-95 {
    width: 95vw !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .mw-lg-100 {
    max-width: 100% !important;
  }
  .min-w-lg-100 {
    min-width: 100% !important;
  }
  .max-vw-lg-100 {
    max-width: 100vw !important;
  }
  .min-vw-lg-100 {
    min-width: 100vw !important;
  }
  .vw-lg-100 {
    width: 100vw !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .mw-lg-auto {
    max-width: none  !important;
  }
  .min-w-lg-auto {
    min-width: auto !important;
  }
  .max-vw-lg-auto {
    max-width: none !important;
  }
  .min-vw-lg-auto {
    min-width: auto !important;
  }
  .vw-lg-auto {
    width: auto !important;
  }
  .h-lg-5 {
    height: 5% !important;
  }
  .mh-lg-5 {
    max-height: 5% !important;
  }
  .min-h-lg-5 {
    min-height: 5% !important;
  }
  .max-vh-lg-5 {
    max-height: 5vw !important;
  }
  .min-vh-lg-5 {
    min-height: 5vw !important;
  }
  .vh-lg-5 {
    height: 5vw !important;
  }
  .h-lg-10 {
    height: 10% !important;
  }
  .mh-lg-10 {
    max-height: 10% !important;
  }
  .min-h-lg-10 {
    min-height: 10% !important;
  }
  .max-vh-lg-10 {
    max-height: 10vw !important;
  }
  .min-vh-lg-10 {
    min-height: 10vw !important;
  }
  .vh-lg-10 {
    height: 10vw !important;
  }
  .h-lg-15 {
    height: 15% !important;
  }
  .mh-lg-15 {
    max-height: 15% !important;
  }
  .min-h-lg-15 {
    min-height: 15% !important;
  }
  .max-vh-lg-15 {
    max-height: 15vw !important;
  }
  .min-vh-lg-15 {
    min-height: 15vw !important;
  }
  .vh-lg-15 {
    height: 15vw !important;
  }
  .h-lg-20 {
    height: 20% !important;
  }
  .mh-lg-20 {
    max-height: 20% !important;
  }
  .min-h-lg-20 {
    min-height: 20% !important;
  }
  .max-vh-lg-20 {
    max-height: 20vw !important;
  }
  .min-vh-lg-20 {
    min-height: 20vw !important;
  }
  .vh-lg-20 {
    height: 20vw !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .mh-lg-25 {
    max-height: 25% !important;
  }
  .min-h-lg-25 {
    min-height: 25% !important;
  }
  .max-vh-lg-25 {
    max-height: 25vw !important;
  }
  .min-vh-lg-25 {
    min-height: 25vw !important;
  }
  .vh-lg-25 {
    height: 25vw !important;
  }
  .h-lg-30 {
    height: 30% !important;
  }
  .mh-lg-30 {
    max-height: 30% !important;
  }
  .min-h-lg-30 {
    min-height: 30% !important;
  }
  .max-vh-lg-30 {
    max-height: 30vw !important;
  }
  .min-vh-lg-30 {
    min-height: 30vw !important;
  }
  .vh-lg-30 {
    height: 30vw !important;
  }
  .h-lg-35 {
    height: 35% !important;
  }
  .mh-lg-35 {
    max-height: 35% !important;
  }
  .min-h-lg-35 {
    min-height: 35% !important;
  }
  .max-vh-lg-35 {
    max-height: 35vw !important;
  }
  .min-vh-lg-35 {
    min-height: 35vw !important;
  }
  .vh-lg-35 {
    height: 35vw !important;
  }
  .h-lg-40 {
    height: 40% !important;
  }
  .mh-lg-40 {
    max-height: 40% !important;
  }
  .min-h-lg-40 {
    min-height: 40% !important;
  }
  .max-vh-lg-40 {
    max-height: 40vw !important;
  }
  .min-vh-lg-40 {
    min-height: 40vw !important;
  }
  .vh-lg-40 {
    height: 40vw !important;
  }
  .h-lg-45 {
    height: 45% !important;
  }
  .mh-lg-45 {
    max-height: 45% !important;
  }
  .min-h-lg-45 {
    min-height: 45% !important;
  }
  .max-vh-lg-45 {
    max-height: 45vw !important;
  }
  .min-vh-lg-45 {
    min-height: 45vw !important;
  }
  .vh-lg-45 {
    height: 45vw !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .mh-lg-50 {
    max-height: 50% !important;
  }
  .min-h-lg-50 {
    min-height: 50% !important;
  }
  .max-vh-lg-50 {
    max-height: 50vw !important;
  }
  .min-vh-lg-50 {
    min-height: 50vw !important;
  }
  .vh-lg-50 {
    height: 50vw !important;
  }
  .h-lg-55 {
    height: 55% !important;
  }
  .mh-lg-55 {
    max-height: 55% !important;
  }
  .min-h-lg-55 {
    min-height: 55% !important;
  }
  .max-vh-lg-55 {
    max-height: 55vw !important;
  }
  .min-vh-lg-55 {
    min-height: 55vw !important;
  }
  .vh-lg-55 {
    height: 55vw !important;
  }
  .h-lg-60 {
    height: 60% !important;
  }
  .mh-lg-60 {
    max-height: 60% !important;
  }
  .min-h-lg-60 {
    min-height: 60% !important;
  }
  .max-vh-lg-60 {
    max-height: 60vw !important;
  }
  .min-vh-lg-60 {
    min-height: 60vw !important;
  }
  .vh-lg-60 {
    height: 60vw !important;
  }
  .h-lg-65 {
    height: 65% !important;
  }
  .mh-lg-65 {
    max-height: 65% !important;
  }
  .min-h-lg-65 {
    min-height: 65% !important;
  }
  .max-vh-lg-65 {
    max-height: 65vw !important;
  }
  .min-vh-lg-65 {
    min-height: 65vw !important;
  }
  .vh-lg-65 {
    height: 65vw !important;
  }
  .h-lg-70 {
    height: 70% !important;
  }
  .mh-lg-70 {
    max-height: 70% !important;
  }
  .min-h-lg-70 {
    min-height: 70% !important;
  }
  .max-vh-lg-70 {
    max-height: 70vw !important;
  }
  .min-vh-lg-70 {
    min-height: 70vw !important;
  }
  .vh-lg-70 {
    height: 70vw !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .mh-lg-75 {
    max-height: 75% !important;
  }
  .min-h-lg-75 {
    min-height: 75% !important;
  }
  .max-vh-lg-75 {
    max-height: 75vw !important;
  }
  .min-vh-lg-75 {
    min-height: 75vw !important;
  }
  .vh-lg-75 {
    height: 75vw !important;
  }
  .h-lg-80 {
    height: 80% !important;
  }
  .mh-lg-80 {
    max-height: 80% !important;
  }
  .min-h-lg-80 {
    min-height: 80% !important;
  }
  .max-vh-lg-80 {
    max-height: 80vw !important;
  }
  .min-vh-lg-80 {
    min-height: 80vw !important;
  }
  .vh-lg-80 {
    height: 80vw !important;
  }
  .h-lg-85 {
    height: 85% !important;
  }
  .mh-lg-85 {
    max-height: 85% !important;
  }
  .min-h-lg-85 {
    min-height: 85% !important;
  }
  .max-vh-lg-85 {
    max-height: 85vw !important;
  }
  .min-vh-lg-85 {
    min-height: 85vw !important;
  }
  .vh-lg-85 {
    height: 85vw !important;
  }
  .h-lg-90 {
    height: 90% !important;
  }
  .mh-lg-90 {
    max-height: 90% !important;
  }
  .min-h-lg-90 {
    min-height: 90% !important;
  }
  .max-vh-lg-90 {
    max-height: 90vw !important;
  }
  .min-vh-lg-90 {
    min-height: 90vw !important;
  }
  .vh-lg-90 {
    height: 90vw !important;
  }
  .h-lg-95 {
    height: 95% !important;
  }
  .mh-lg-95 {
    max-height: 95% !important;
  }
  .min-h-lg-95 {
    min-height: 95% !important;
  }
  .max-vh-lg-95 {
    max-height: 95vw !important;
  }
  .min-vh-lg-95 {
    min-height: 95vw !important;
  }
  .vh-lg-95 {
    height: 95vw !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .mh-lg-100 {
    max-height: 100% !important;
  }
  .min-h-lg-100 {
    min-height: 100% !important;
  }
  .max-vh-lg-100 {
    max-height: 100vw !important;
  }
  .min-vh-lg-100 {
    min-height: 100vw !important;
  }
  .vh-lg-100 {
    height: 100vw !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .mh-lg-auto {
    max-height: none  !important;
  }
  .min-h-lg-auto {
    min-height: auto !important;
  }
  .max-vh-lg-auto {
    max-height: none !important;
  }
  .min-vh-lg-auto {
    min-height: auto !important;
  }
  .vh-lg-auto {
    height: auto !important;
  }
  .img-of-lg-fill {
    object-fit: fill !important;
  }
  .img-of-lg-contain {
    object-fit: contain !important;
  }
  .img-of-lg-cover {
    object-fit: cover !important;
  }
  .img-of-lg-none {
    object-fit: none !important;
  }
  .img-of-lg-scale-down {
    object-fit: scale-down !important;
  }
  .img-op-lg-tr {
    object-position: top right !important;
  }
  .img-op-lg-tl {
    object-position: top left !important;
  }
  .img-op-lg-tc {
    object-position: top center !important;
  }
  .img-op-lg-rt {
    object-position: right top !important;
  }
  .img-op-lg-rb {
    object-position: right bottom !important;
  }
  .img-op-lg-rc {
    object-position: right center !important;
  }
  .img-op-lg-br {
    object-position: bottom right !important;
  }
  .img-op-lg-bl {
    object-position: bottom left !important;
  }
  .img-op-lg-bc {
    object-position: bottom center !important;
  }
  .img-op-lg-lt {
    object-position: left top !important;
  }
  .img-op-lg-lb {
    object-position: left bottom !important;
  }
  .img-op-lg-lc {
    object-position: left center !important;
  }
  .img-op-lg-unset {
    object-position: unset !important;
  }
  .cus-sku-lg-rt {
    top: 0.8rem !important;
    right: 0.8rem !important;
    bottom: unset !important;
    left: unset !important;
  }
  .cus-sku-lg-lb {
    top: unset !important;
    right: unset !important;
    bottom: 0.8rem !important;
    left: 0.8rem !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-5 {
    width: 5% !important;
  }
  .mw-xl-5 {
    max-width: 5% !important;
  }
  .min-w-xl-5 {
    min-width: 5% !important;
  }
  .max-vw-xl-5 {
    max-width: 5vw !important;
  }
  .min-vw-xl-5 {
    min-width: 5vw !important;
  }
  .vw-xl-5 {
    width: 5vw !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .mw-xl-10 {
    max-width: 10% !important;
  }
  .min-w-xl-10 {
    min-width: 10% !important;
  }
  .max-vw-xl-10 {
    max-width: 10vw !important;
  }
  .min-vw-xl-10 {
    min-width: 10vw !important;
  }
  .vw-xl-10 {
    width: 10vw !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .mw-xl-15 {
    max-width: 15% !important;
  }
  .min-w-xl-15 {
    min-width: 15% !important;
  }
  .max-vw-xl-15 {
    max-width: 15vw !important;
  }
  .min-vw-xl-15 {
    min-width: 15vw !important;
  }
  .vw-xl-15 {
    width: 15vw !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .mw-xl-20 {
    max-width: 20% !important;
  }
  .min-w-xl-20 {
    min-width: 20% !important;
  }
  .max-vw-xl-20 {
    max-width: 20vw !important;
  }
  .min-vw-xl-20 {
    min-width: 20vw !important;
  }
  .vw-xl-20 {
    width: 20vw !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .mw-xl-25 {
    max-width: 25% !important;
  }
  .min-w-xl-25 {
    min-width: 25% !important;
  }
  .max-vw-xl-25 {
    max-width: 25vw !important;
  }
  .min-vw-xl-25 {
    min-width: 25vw !important;
  }
  .vw-xl-25 {
    width: 25vw !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .mw-xl-30 {
    max-width: 30% !important;
  }
  .min-w-xl-30 {
    min-width: 30% !important;
  }
  .max-vw-xl-30 {
    max-width: 30vw !important;
  }
  .min-vw-xl-30 {
    min-width: 30vw !important;
  }
  .vw-xl-30 {
    width: 30vw !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .mw-xl-35 {
    max-width: 35% !important;
  }
  .min-w-xl-35 {
    min-width: 35% !important;
  }
  .max-vw-xl-35 {
    max-width: 35vw !important;
  }
  .min-vw-xl-35 {
    min-width: 35vw !important;
  }
  .vw-xl-35 {
    width: 35vw !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .mw-xl-40 {
    max-width: 40% !important;
  }
  .min-w-xl-40 {
    min-width: 40% !important;
  }
  .max-vw-xl-40 {
    max-width: 40vw !important;
  }
  .min-vw-xl-40 {
    min-width: 40vw !important;
  }
  .vw-xl-40 {
    width: 40vw !important;
  }
  .w-xl-45 {
    width: 45% !important;
  }
  .mw-xl-45 {
    max-width: 45% !important;
  }
  .min-w-xl-45 {
    min-width: 45% !important;
  }
  .max-vw-xl-45 {
    max-width: 45vw !important;
  }
  .min-vw-xl-45 {
    min-width: 45vw !important;
  }
  .vw-xl-45 {
    width: 45vw !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .mw-xl-50 {
    max-width: 50% !important;
  }
  .min-w-xl-50 {
    min-width: 50% !important;
  }
  .max-vw-xl-50 {
    max-width: 50vw !important;
  }
  .min-vw-xl-50 {
    min-width: 50vw !important;
  }
  .vw-xl-50 {
    width: 50vw !important;
  }
  .w-xl-55 {
    width: 55% !important;
  }
  .mw-xl-55 {
    max-width: 55% !important;
  }
  .min-w-xl-55 {
    min-width: 55% !important;
  }
  .max-vw-xl-55 {
    max-width: 55vw !important;
  }
  .min-vw-xl-55 {
    min-width: 55vw !important;
  }
  .vw-xl-55 {
    width: 55vw !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .mw-xl-60 {
    max-width: 60% !important;
  }
  .min-w-xl-60 {
    min-width: 60% !important;
  }
  .max-vw-xl-60 {
    max-width: 60vw !important;
  }
  .min-vw-xl-60 {
    min-width: 60vw !important;
  }
  .vw-xl-60 {
    width: 60vw !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .mw-xl-65 {
    max-width: 65% !important;
  }
  .min-w-xl-65 {
    min-width: 65% !important;
  }
  .max-vw-xl-65 {
    max-width: 65vw !important;
  }
  .min-vw-xl-65 {
    min-width: 65vw !important;
  }
  .vw-xl-65 {
    width: 65vw !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .mw-xl-70 {
    max-width: 70% !important;
  }
  .min-w-xl-70 {
    min-width: 70% !important;
  }
  .max-vw-xl-70 {
    max-width: 70vw !important;
  }
  .min-vw-xl-70 {
    min-width: 70vw !important;
  }
  .vw-xl-70 {
    width: 70vw !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .mw-xl-75 {
    max-width: 75% !important;
  }
  .min-w-xl-75 {
    min-width: 75% !important;
  }
  .max-vw-xl-75 {
    max-width: 75vw !important;
  }
  .min-vw-xl-75 {
    min-width: 75vw !important;
  }
  .vw-xl-75 {
    width: 75vw !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .mw-xl-80 {
    max-width: 80% !important;
  }
  .min-w-xl-80 {
    min-width: 80% !important;
  }
  .max-vw-xl-80 {
    max-width: 80vw !important;
  }
  .min-vw-xl-80 {
    min-width: 80vw !important;
  }
  .vw-xl-80 {
    width: 80vw !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
  .mw-xl-85 {
    max-width: 85% !important;
  }
  .min-w-xl-85 {
    min-width: 85% !important;
  }
  .max-vw-xl-85 {
    max-width: 85vw !important;
  }
  .min-vw-xl-85 {
    min-width: 85vw !important;
  }
  .vw-xl-85 {
    width: 85vw !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .mw-xl-90 {
    max-width: 90% !important;
  }
  .min-w-xl-90 {
    min-width: 90% !important;
  }
  .max-vw-xl-90 {
    max-width: 90vw !important;
  }
  .min-vw-xl-90 {
    min-width: 90vw !important;
  }
  .vw-xl-90 {
    width: 90vw !important;
  }
  .w-xl-95 {
    width: 95% !important;
  }
  .mw-xl-95 {
    max-width: 95% !important;
  }
  .min-w-xl-95 {
    min-width: 95% !important;
  }
  .max-vw-xl-95 {
    max-width: 95vw !important;
  }
  .min-vw-xl-95 {
    min-width: 95vw !important;
  }
  .vw-xl-95 {
    width: 95vw !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .mw-xl-100 {
    max-width: 100% !important;
  }
  .min-w-xl-100 {
    min-width: 100% !important;
  }
  .max-vw-xl-100 {
    max-width: 100vw !important;
  }
  .min-vw-xl-100 {
    min-width: 100vw !important;
  }
  .vw-xl-100 {
    width: 100vw !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .mw-xl-auto {
    max-width: none  !important;
  }
  .min-w-xl-auto {
    min-width: auto !important;
  }
  .max-vw-xl-auto {
    max-width: none !important;
  }
  .min-vw-xl-auto {
    min-width: auto !important;
  }
  .vw-xl-auto {
    width: auto !important;
  }
  .h-xl-5 {
    height: 5% !important;
  }
  .mh-xl-5 {
    max-height: 5% !important;
  }
  .min-h-xl-5 {
    min-height: 5% !important;
  }
  .max-vh-xl-5 {
    max-height: 5vw !important;
  }
  .min-vh-xl-5 {
    min-height: 5vw !important;
  }
  .vh-xl-5 {
    height: 5vw !important;
  }
  .h-xl-10 {
    height: 10% !important;
  }
  .mh-xl-10 {
    max-height: 10% !important;
  }
  .min-h-xl-10 {
    min-height: 10% !important;
  }
  .max-vh-xl-10 {
    max-height: 10vw !important;
  }
  .min-vh-xl-10 {
    min-height: 10vw !important;
  }
  .vh-xl-10 {
    height: 10vw !important;
  }
  .h-xl-15 {
    height: 15% !important;
  }
  .mh-xl-15 {
    max-height: 15% !important;
  }
  .min-h-xl-15 {
    min-height: 15% !important;
  }
  .max-vh-xl-15 {
    max-height: 15vw !important;
  }
  .min-vh-xl-15 {
    min-height: 15vw !important;
  }
  .vh-xl-15 {
    height: 15vw !important;
  }
  .h-xl-20 {
    height: 20% !important;
  }
  .mh-xl-20 {
    max-height: 20% !important;
  }
  .min-h-xl-20 {
    min-height: 20% !important;
  }
  .max-vh-xl-20 {
    max-height: 20vw !important;
  }
  .min-vh-xl-20 {
    min-height: 20vw !important;
  }
  .vh-xl-20 {
    height: 20vw !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .mh-xl-25 {
    max-height: 25% !important;
  }
  .min-h-xl-25 {
    min-height: 25% !important;
  }
  .max-vh-xl-25 {
    max-height: 25vw !important;
  }
  .min-vh-xl-25 {
    min-height: 25vw !important;
  }
  .vh-xl-25 {
    height: 25vw !important;
  }
  .h-xl-30 {
    height: 30% !important;
  }
  .mh-xl-30 {
    max-height: 30% !important;
  }
  .min-h-xl-30 {
    min-height: 30% !important;
  }
  .max-vh-xl-30 {
    max-height: 30vw !important;
  }
  .min-vh-xl-30 {
    min-height: 30vw !important;
  }
  .vh-xl-30 {
    height: 30vw !important;
  }
  .h-xl-35 {
    height: 35% !important;
  }
  .mh-xl-35 {
    max-height: 35% !important;
  }
  .min-h-xl-35 {
    min-height: 35% !important;
  }
  .max-vh-xl-35 {
    max-height: 35vw !important;
  }
  .min-vh-xl-35 {
    min-height: 35vw !important;
  }
  .vh-xl-35 {
    height: 35vw !important;
  }
  .h-xl-40 {
    height: 40% !important;
  }
  .mh-xl-40 {
    max-height: 40% !important;
  }
  .min-h-xl-40 {
    min-height: 40% !important;
  }
  .max-vh-xl-40 {
    max-height: 40vw !important;
  }
  .min-vh-xl-40 {
    min-height: 40vw !important;
  }
  .vh-xl-40 {
    height: 40vw !important;
  }
  .h-xl-45 {
    height: 45% !important;
  }
  .mh-xl-45 {
    max-height: 45% !important;
  }
  .min-h-xl-45 {
    min-height: 45% !important;
  }
  .max-vh-xl-45 {
    max-height: 45vw !important;
  }
  .min-vh-xl-45 {
    min-height: 45vw !important;
  }
  .vh-xl-45 {
    height: 45vw !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .mh-xl-50 {
    max-height: 50% !important;
  }
  .min-h-xl-50 {
    min-height: 50% !important;
  }
  .max-vh-xl-50 {
    max-height: 50vw !important;
  }
  .min-vh-xl-50 {
    min-height: 50vw !important;
  }
  .vh-xl-50 {
    height: 50vw !important;
  }
  .h-xl-55 {
    height: 55% !important;
  }
  .mh-xl-55 {
    max-height: 55% !important;
  }
  .min-h-xl-55 {
    min-height: 55% !important;
  }
  .max-vh-xl-55 {
    max-height: 55vw !important;
  }
  .min-vh-xl-55 {
    min-height: 55vw !important;
  }
  .vh-xl-55 {
    height: 55vw !important;
  }
  .h-xl-60 {
    height: 60% !important;
  }
  .mh-xl-60 {
    max-height: 60% !important;
  }
  .min-h-xl-60 {
    min-height: 60% !important;
  }
  .max-vh-xl-60 {
    max-height: 60vw !important;
  }
  .min-vh-xl-60 {
    min-height: 60vw !important;
  }
  .vh-xl-60 {
    height: 60vw !important;
  }
  .h-xl-65 {
    height: 65% !important;
  }
  .mh-xl-65 {
    max-height: 65% !important;
  }
  .min-h-xl-65 {
    min-height: 65% !important;
  }
  .max-vh-xl-65 {
    max-height: 65vw !important;
  }
  .min-vh-xl-65 {
    min-height: 65vw !important;
  }
  .vh-xl-65 {
    height: 65vw !important;
  }
  .h-xl-70 {
    height: 70% !important;
  }
  .mh-xl-70 {
    max-height: 70% !important;
  }
  .min-h-xl-70 {
    min-height: 70% !important;
  }
  .max-vh-xl-70 {
    max-height: 70vw !important;
  }
  .min-vh-xl-70 {
    min-height: 70vw !important;
  }
  .vh-xl-70 {
    height: 70vw !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .mh-xl-75 {
    max-height: 75% !important;
  }
  .min-h-xl-75 {
    min-height: 75% !important;
  }
  .max-vh-xl-75 {
    max-height: 75vw !important;
  }
  .min-vh-xl-75 {
    min-height: 75vw !important;
  }
  .vh-xl-75 {
    height: 75vw !important;
  }
  .h-xl-80 {
    height: 80% !important;
  }
  .mh-xl-80 {
    max-height: 80% !important;
  }
  .min-h-xl-80 {
    min-height: 80% !important;
  }
  .max-vh-xl-80 {
    max-height: 80vw !important;
  }
  .min-vh-xl-80 {
    min-height: 80vw !important;
  }
  .vh-xl-80 {
    height: 80vw !important;
  }
  .h-xl-85 {
    height: 85% !important;
  }
  .mh-xl-85 {
    max-height: 85% !important;
  }
  .min-h-xl-85 {
    min-height: 85% !important;
  }
  .max-vh-xl-85 {
    max-height: 85vw !important;
  }
  .min-vh-xl-85 {
    min-height: 85vw !important;
  }
  .vh-xl-85 {
    height: 85vw !important;
  }
  .h-xl-90 {
    height: 90% !important;
  }
  .mh-xl-90 {
    max-height: 90% !important;
  }
  .min-h-xl-90 {
    min-height: 90% !important;
  }
  .max-vh-xl-90 {
    max-height: 90vw !important;
  }
  .min-vh-xl-90 {
    min-height: 90vw !important;
  }
  .vh-xl-90 {
    height: 90vw !important;
  }
  .h-xl-95 {
    height: 95% !important;
  }
  .mh-xl-95 {
    max-height: 95% !important;
  }
  .min-h-xl-95 {
    min-height: 95% !important;
  }
  .max-vh-xl-95 {
    max-height: 95vw !important;
  }
  .min-vh-xl-95 {
    min-height: 95vw !important;
  }
  .vh-xl-95 {
    height: 95vw !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .mh-xl-100 {
    max-height: 100% !important;
  }
  .min-h-xl-100 {
    min-height: 100% !important;
  }
  .max-vh-xl-100 {
    max-height: 100vw !important;
  }
  .min-vh-xl-100 {
    min-height: 100vw !important;
  }
  .vh-xl-100 {
    height: 100vw !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .mh-xl-auto {
    max-height: none  !important;
  }
  .min-h-xl-auto {
    min-height: auto !important;
  }
  .max-vh-xl-auto {
    max-height: none !important;
  }
  .min-vh-xl-auto {
    min-height: auto !important;
  }
  .vh-xl-auto {
    height: auto !important;
  }
  .img-of-xl-fill {
    object-fit: fill !important;
  }
  .img-of-xl-contain {
    object-fit: contain !important;
  }
  .img-of-xl-cover {
    object-fit: cover !important;
  }
  .img-of-xl-none {
    object-fit: none !important;
  }
  .img-of-xl-scale-down {
    object-fit: scale-down !important;
  }
  .img-op-xl-tr {
    object-position: top right !important;
  }
  .img-op-xl-tl {
    object-position: top left !important;
  }
  .img-op-xl-tc {
    object-position: top center !important;
  }
  .img-op-xl-rt {
    object-position: right top !important;
  }
  .img-op-xl-rb {
    object-position: right bottom !important;
  }
  .img-op-xl-rc {
    object-position: right center !important;
  }
  .img-op-xl-br {
    object-position: bottom right !important;
  }
  .img-op-xl-bl {
    object-position: bottom left !important;
  }
  .img-op-xl-bc {
    object-position: bottom center !important;
  }
  .img-op-xl-lt {
    object-position: left top !important;
  }
  .img-op-xl-lb {
    object-position: left bottom !important;
  }
  .img-op-xl-lc {
    object-position: left center !important;
  }
  .img-op-xl-unset {
    object-position: unset !important;
  }
  .cus-sku-xl-rt {
    top: 0.8rem !important;
    right: 0.8rem !important;
    bottom: unset !important;
    left: unset !important;
  }
  .cus-sku-xl-lb {
    top: unset !important;
    right: unset !important;
    bottom: 0.8rem !important;
    left: 0.8rem !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-5 {
    width: 5% !important;
  }
  .mw-xxl-5 {
    max-width: 5% !important;
  }
  .min-w-xxl-5 {
    min-width: 5% !important;
  }
  .max-vw-xxl-5 {
    max-width: 5vw !important;
  }
  .min-vw-xxl-5 {
    min-width: 5vw !important;
  }
  .vw-xxl-5 {
    width: 5vw !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .mw-xxl-10 {
    max-width: 10% !important;
  }
  .min-w-xxl-10 {
    min-width: 10% !important;
  }
  .max-vw-xxl-10 {
    max-width: 10vw !important;
  }
  .min-vw-xxl-10 {
    min-width: 10vw !important;
  }
  .vw-xxl-10 {
    width: 10vw !important;
  }
  .w-xxl-15 {
    width: 15% !important;
  }
  .mw-xxl-15 {
    max-width: 15% !important;
  }
  .min-w-xxl-15 {
    min-width: 15% !important;
  }
  .max-vw-xxl-15 {
    max-width: 15vw !important;
  }
  .min-vw-xxl-15 {
    min-width: 15vw !important;
  }
  .vw-xxl-15 {
    width: 15vw !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .mw-xxl-20 {
    max-width: 20% !important;
  }
  .min-w-xxl-20 {
    min-width: 20% !important;
  }
  .max-vw-xxl-20 {
    max-width: 20vw !important;
  }
  .min-vw-xxl-20 {
    min-width: 20vw !important;
  }
  .vw-xxl-20 {
    width: 20vw !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .mw-xxl-25 {
    max-width: 25% !important;
  }
  .min-w-xxl-25 {
    min-width: 25% !important;
  }
  .max-vw-xxl-25 {
    max-width: 25vw !important;
  }
  .min-vw-xxl-25 {
    min-width: 25vw !important;
  }
  .vw-xxl-25 {
    width: 25vw !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .mw-xxl-30 {
    max-width: 30% !important;
  }
  .min-w-xxl-30 {
    min-width: 30% !important;
  }
  .max-vw-xxl-30 {
    max-width: 30vw !important;
  }
  .min-vw-xxl-30 {
    min-width: 30vw !important;
  }
  .vw-xxl-30 {
    width: 30vw !important;
  }
  .w-xxl-35 {
    width: 35% !important;
  }
  .mw-xxl-35 {
    max-width: 35% !important;
  }
  .min-w-xxl-35 {
    min-width: 35% !important;
  }
  .max-vw-xxl-35 {
    max-width: 35vw !important;
  }
  .min-vw-xxl-35 {
    min-width: 35vw !important;
  }
  .vw-xxl-35 {
    width: 35vw !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .mw-xxl-40 {
    max-width: 40% !important;
  }
  .min-w-xxl-40 {
    min-width: 40% !important;
  }
  .max-vw-xxl-40 {
    max-width: 40vw !important;
  }
  .min-vw-xxl-40 {
    min-width: 40vw !important;
  }
  .vw-xxl-40 {
    width: 40vw !important;
  }
  .w-xxl-45 {
    width: 45% !important;
  }
  .mw-xxl-45 {
    max-width: 45% !important;
  }
  .min-w-xxl-45 {
    min-width: 45% !important;
  }
  .max-vw-xxl-45 {
    max-width: 45vw !important;
  }
  .min-vw-xxl-45 {
    min-width: 45vw !important;
  }
  .vw-xxl-45 {
    width: 45vw !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .mw-xxl-50 {
    max-width: 50% !important;
  }
  .min-w-xxl-50 {
    min-width: 50% !important;
  }
  .max-vw-xxl-50 {
    max-width: 50vw !important;
  }
  .min-vw-xxl-50 {
    min-width: 50vw !important;
  }
  .vw-xxl-50 {
    width: 50vw !important;
  }
  .w-xxl-55 {
    width: 55% !important;
  }
  .mw-xxl-55 {
    max-width: 55% !important;
  }
  .min-w-xxl-55 {
    min-width: 55% !important;
  }
  .max-vw-xxl-55 {
    max-width: 55vw !important;
  }
  .min-vw-xxl-55 {
    min-width: 55vw !important;
  }
  .vw-xxl-55 {
    width: 55vw !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .mw-xxl-60 {
    max-width: 60% !important;
  }
  .min-w-xxl-60 {
    min-width: 60% !important;
  }
  .max-vw-xxl-60 {
    max-width: 60vw !important;
  }
  .min-vw-xxl-60 {
    min-width: 60vw !important;
  }
  .vw-xxl-60 {
    width: 60vw !important;
  }
  .w-xxl-65 {
    width: 65% !important;
  }
  .mw-xxl-65 {
    max-width: 65% !important;
  }
  .min-w-xxl-65 {
    min-width: 65% !important;
  }
  .max-vw-xxl-65 {
    max-width: 65vw !important;
  }
  .min-vw-xxl-65 {
    min-width: 65vw !important;
  }
  .vw-xxl-65 {
    width: 65vw !important;
  }
  .w-xxl-70 {
    width: 70% !important;
  }
  .mw-xxl-70 {
    max-width: 70% !important;
  }
  .min-w-xxl-70 {
    min-width: 70% !important;
  }
  .max-vw-xxl-70 {
    max-width: 70vw !important;
  }
  .min-vw-xxl-70 {
    min-width: 70vw !important;
  }
  .vw-xxl-70 {
    width: 70vw !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .mw-xxl-75 {
    max-width: 75% !important;
  }
  .min-w-xxl-75 {
    min-width: 75% !important;
  }
  .max-vw-xxl-75 {
    max-width: 75vw !important;
  }
  .min-vw-xxl-75 {
    min-width: 75vw !important;
  }
  .vw-xxl-75 {
    width: 75vw !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .mw-xxl-80 {
    max-width: 80% !important;
  }
  .min-w-xxl-80 {
    min-width: 80% !important;
  }
  .max-vw-xxl-80 {
    max-width: 80vw !important;
  }
  .min-vw-xxl-80 {
    min-width: 80vw !important;
  }
  .vw-xxl-80 {
    width: 80vw !important;
  }
  .w-xxl-85 {
    width: 85% !important;
  }
  .mw-xxl-85 {
    max-width: 85% !important;
  }
  .min-w-xxl-85 {
    min-width: 85% !important;
  }
  .max-vw-xxl-85 {
    max-width: 85vw !important;
  }
  .min-vw-xxl-85 {
    min-width: 85vw !important;
  }
  .vw-xxl-85 {
    width: 85vw !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .mw-xxl-90 {
    max-width: 90% !important;
  }
  .min-w-xxl-90 {
    min-width: 90% !important;
  }
  .max-vw-xxl-90 {
    max-width: 90vw !important;
  }
  .min-vw-xxl-90 {
    min-width: 90vw !important;
  }
  .vw-xxl-90 {
    width: 90vw !important;
  }
  .w-xxl-95 {
    width: 95% !important;
  }
  .mw-xxl-95 {
    max-width: 95% !important;
  }
  .min-w-xxl-95 {
    min-width: 95% !important;
  }
  .max-vw-xxl-95 {
    max-width: 95vw !important;
  }
  .min-vw-xxl-95 {
    min-width: 95vw !important;
  }
  .vw-xxl-95 {
    width: 95vw !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .mw-xxl-100 {
    max-width: 100% !important;
  }
  .min-w-xxl-100 {
    min-width: 100% !important;
  }
  .max-vw-xxl-100 {
    max-width: 100vw !important;
  }
  .min-vw-xxl-100 {
    min-width: 100vw !important;
  }
  .vw-xxl-100 {
    width: 100vw !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .mw-xxl-auto {
    max-width: none  !important;
  }
  .min-w-xxl-auto {
    min-width: auto !important;
  }
  .max-vw-xxl-auto {
    max-width: none !important;
  }
  .min-vw-xxl-auto {
    min-width: auto !important;
  }
  .vw-xxl-auto {
    width: auto !important;
  }
  .h-xxl-5 {
    height: 5% !important;
  }
  .mh-xxl-5 {
    max-height: 5% !important;
  }
  .min-h-xxl-5 {
    min-height: 5% !important;
  }
  .max-vh-xxl-5 {
    max-height: 5vw !important;
  }
  .min-vh-xxl-5 {
    min-height: 5vw !important;
  }
  .vh-xxl-5 {
    height: 5vw !important;
  }
  .h-xxl-10 {
    height: 10% !important;
  }
  .mh-xxl-10 {
    max-height: 10% !important;
  }
  .min-h-xxl-10 {
    min-height: 10% !important;
  }
  .max-vh-xxl-10 {
    max-height: 10vw !important;
  }
  .min-vh-xxl-10 {
    min-height: 10vw !important;
  }
  .vh-xxl-10 {
    height: 10vw !important;
  }
  .h-xxl-15 {
    height: 15% !important;
  }
  .mh-xxl-15 {
    max-height: 15% !important;
  }
  .min-h-xxl-15 {
    min-height: 15% !important;
  }
  .max-vh-xxl-15 {
    max-height: 15vw !important;
  }
  .min-vh-xxl-15 {
    min-height: 15vw !important;
  }
  .vh-xxl-15 {
    height: 15vw !important;
  }
  .h-xxl-20 {
    height: 20% !important;
  }
  .mh-xxl-20 {
    max-height: 20% !important;
  }
  .min-h-xxl-20 {
    min-height: 20% !important;
  }
  .max-vh-xxl-20 {
    max-height: 20vw !important;
  }
  .min-vh-xxl-20 {
    min-height: 20vw !important;
  }
  .vh-xxl-20 {
    height: 20vw !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .mh-xxl-25 {
    max-height: 25% !important;
  }
  .min-h-xxl-25 {
    min-height: 25% !important;
  }
  .max-vh-xxl-25 {
    max-height: 25vw !important;
  }
  .min-vh-xxl-25 {
    min-height: 25vw !important;
  }
  .vh-xxl-25 {
    height: 25vw !important;
  }
  .h-xxl-30 {
    height: 30% !important;
  }
  .mh-xxl-30 {
    max-height: 30% !important;
  }
  .min-h-xxl-30 {
    min-height: 30% !important;
  }
  .max-vh-xxl-30 {
    max-height: 30vw !important;
  }
  .min-vh-xxl-30 {
    min-height: 30vw !important;
  }
  .vh-xxl-30 {
    height: 30vw !important;
  }
  .h-xxl-35 {
    height: 35% !important;
  }
  .mh-xxl-35 {
    max-height: 35% !important;
  }
  .min-h-xxl-35 {
    min-height: 35% !important;
  }
  .max-vh-xxl-35 {
    max-height: 35vw !important;
  }
  .min-vh-xxl-35 {
    min-height: 35vw !important;
  }
  .vh-xxl-35 {
    height: 35vw !important;
  }
  .h-xxl-40 {
    height: 40% !important;
  }
  .mh-xxl-40 {
    max-height: 40% !important;
  }
  .min-h-xxl-40 {
    min-height: 40% !important;
  }
  .max-vh-xxl-40 {
    max-height: 40vw !important;
  }
  .min-vh-xxl-40 {
    min-height: 40vw !important;
  }
  .vh-xxl-40 {
    height: 40vw !important;
  }
  .h-xxl-45 {
    height: 45% !important;
  }
  .mh-xxl-45 {
    max-height: 45% !important;
  }
  .min-h-xxl-45 {
    min-height: 45% !important;
  }
  .max-vh-xxl-45 {
    max-height: 45vw !important;
  }
  .min-vh-xxl-45 {
    min-height: 45vw !important;
  }
  .vh-xxl-45 {
    height: 45vw !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .mh-xxl-50 {
    max-height: 50% !important;
  }
  .min-h-xxl-50 {
    min-height: 50% !important;
  }
  .max-vh-xxl-50 {
    max-height: 50vw !important;
  }
  .min-vh-xxl-50 {
    min-height: 50vw !important;
  }
  .vh-xxl-50 {
    height: 50vw !important;
  }
  .h-xxl-55 {
    height: 55% !important;
  }
  .mh-xxl-55 {
    max-height: 55% !important;
  }
  .min-h-xxl-55 {
    min-height: 55% !important;
  }
  .max-vh-xxl-55 {
    max-height: 55vw !important;
  }
  .min-vh-xxl-55 {
    min-height: 55vw !important;
  }
  .vh-xxl-55 {
    height: 55vw !important;
  }
  .h-xxl-60 {
    height: 60% !important;
  }
  .mh-xxl-60 {
    max-height: 60% !important;
  }
  .min-h-xxl-60 {
    min-height: 60% !important;
  }
  .max-vh-xxl-60 {
    max-height: 60vw !important;
  }
  .min-vh-xxl-60 {
    min-height: 60vw !important;
  }
  .vh-xxl-60 {
    height: 60vw !important;
  }
  .h-xxl-65 {
    height: 65% !important;
  }
  .mh-xxl-65 {
    max-height: 65% !important;
  }
  .min-h-xxl-65 {
    min-height: 65% !important;
  }
  .max-vh-xxl-65 {
    max-height: 65vw !important;
  }
  .min-vh-xxl-65 {
    min-height: 65vw !important;
  }
  .vh-xxl-65 {
    height: 65vw !important;
  }
  .h-xxl-70 {
    height: 70% !important;
  }
  .mh-xxl-70 {
    max-height: 70% !important;
  }
  .min-h-xxl-70 {
    min-height: 70% !important;
  }
  .max-vh-xxl-70 {
    max-height: 70vw !important;
  }
  .min-vh-xxl-70 {
    min-height: 70vw !important;
  }
  .vh-xxl-70 {
    height: 70vw !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .mh-xxl-75 {
    max-height: 75% !important;
  }
  .min-h-xxl-75 {
    min-height: 75% !important;
  }
  .max-vh-xxl-75 {
    max-height: 75vw !important;
  }
  .min-vh-xxl-75 {
    min-height: 75vw !important;
  }
  .vh-xxl-75 {
    height: 75vw !important;
  }
  .h-xxl-80 {
    height: 80% !important;
  }
  .mh-xxl-80 {
    max-height: 80% !important;
  }
  .min-h-xxl-80 {
    min-height: 80% !important;
  }
  .max-vh-xxl-80 {
    max-height: 80vw !important;
  }
  .min-vh-xxl-80 {
    min-height: 80vw !important;
  }
  .vh-xxl-80 {
    height: 80vw !important;
  }
  .h-xxl-85 {
    height: 85% !important;
  }
  .mh-xxl-85 {
    max-height: 85% !important;
  }
  .min-h-xxl-85 {
    min-height: 85% !important;
  }
  .max-vh-xxl-85 {
    max-height: 85vw !important;
  }
  .min-vh-xxl-85 {
    min-height: 85vw !important;
  }
  .vh-xxl-85 {
    height: 85vw !important;
  }
  .h-xxl-90 {
    height: 90% !important;
  }
  .mh-xxl-90 {
    max-height: 90% !important;
  }
  .min-h-xxl-90 {
    min-height: 90% !important;
  }
  .max-vh-xxl-90 {
    max-height: 90vw !important;
  }
  .min-vh-xxl-90 {
    min-height: 90vw !important;
  }
  .vh-xxl-90 {
    height: 90vw !important;
  }
  .h-xxl-95 {
    height: 95% !important;
  }
  .mh-xxl-95 {
    max-height: 95% !important;
  }
  .min-h-xxl-95 {
    min-height: 95% !important;
  }
  .max-vh-xxl-95 {
    max-height: 95vw !important;
  }
  .min-vh-xxl-95 {
    min-height: 95vw !important;
  }
  .vh-xxl-95 {
    height: 95vw !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .mh-xxl-100 {
    max-height: 100% !important;
  }
  .min-h-xxl-100 {
    min-height: 100% !important;
  }
  .max-vh-xxl-100 {
    max-height: 100vw !important;
  }
  .min-vh-xxl-100 {
    min-height: 100vw !important;
  }
  .vh-xxl-100 {
    height: 100vw !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .mh-xxl-auto {
    max-height: none  !important;
  }
  .min-h-xxl-auto {
    min-height: auto !important;
  }
  .max-vh-xxl-auto {
    max-height: none !important;
  }
  .min-vh-xxl-auto {
    min-height: auto !important;
  }
  .vh-xxl-auto {
    height: auto !important;
  }
  .img-of-xxl-fill {
    object-fit: fill !important;
  }
  .img-of-xxl-contain {
    object-fit: contain !important;
  }
  .img-of-xxl-cover {
    object-fit: cover !important;
  }
  .img-of-xxl-none {
    object-fit: none !important;
  }
  .img-of-xxl-scale-down {
    object-fit: scale-down !important;
  }
  .img-op-xxl-tr {
    object-position: top right !important;
  }
  .img-op-xxl-tl {
    object-position: top left !important;
  }
  .img-op-xxl-tc {
    object-position: top center !important;
  }
  .img-op-xxl-rt {
    object-position: right top !important;
  }
  .img-op-xxl-rb {
    object-position: right bottom !important;
  }
  .img-op-xxl-rc {
    object-position: right center !important;
  }
  .img-op-xxl-br {
    object-position: bottom right !important;
  }
  .img-op-xxl-bl {
    object-position: bottom left !important;
  }
  .img-op-xxl-bc {
    object-position: bottom center !important;
  }
  .img-op-xxl-lt {
    object-position: left top !important;
  }
  .img-op-xxl-lb {
    object-position: left bottom !important;
  }
  .img-op-xxl-lc {
    object-position: left center !important;
  }
  .img-op-xxl-unset {
    object-position: unset !important;
  }
  .cus-sku-xxl-rt {
    top: 0.8rem !important;
    right: 0.8rem !important;
    bottom: unset !important;
    left: unset !important;
  }
  .cus-sku-xxl-lb {
    top: unset !important;
    right: unset !important;
    bottom: 0.8rem !important;
    left: 0.8rem !important;
  }
}

@media (max-width: 575.98px) {
  .cus-show-block-sm > .cus-block-item {
    display: block !important;
  }
  .cus-show-block-sm > a.cus-block-item {
    display: block !important;
  }
}

@media (max-width: 767.98px) {
  .cus-show-block-md > .cus-block-item {
    display: block !important;
  }
  .cus-show-block-md > a.cus-block-item {
    display: block !important;
  }
}

@media (max-width: 991.98px) {
  .cus-show-block-lg > .cus-block-item {
    display: block !important;
  }
  .cus-show-block-lg > a.cus-block-item {
    display: block !important;
  }
}

@media (max-width: 1199.98px) {
  .cus-show-block-xl > .cus-block-item {
    display: block !important;
  }
  .cus-show-block-xl > a.cus-block-item {
    display: block !important;
  }
}

@media (max-width: 1399.98px) {
  .cus-show-block-xxl > .cus-block-item {
    display: block !important;
  }
  .cus-show-block-xxl > a.cus-block-item {
    display: block !important;
  }
}

.cus-show-block > .cus-block-item {
  display: block !important;
}

@media (max-width: 575.98px) {
  .cus-none-block-sm > .cus-block-item {
    display: none !important;
  }
  .cus-none-block-sm > a.cus-block-item {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .cus-none-block-md > .cus-block-item {
    display: none !important;
  }
  .cus-none-block-md > a.cus-block-item {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .cus-none-block-lg > .cus-block-item {
    display: none !important;
  }
  .cus-none-block-lg > a.cus-block-item {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .cus-none-block-xl > .cus-block-item {
    display: none !important;
  }
  .cus-none-block-xl > a.cus-block-item {
    display: none !important;
  }
}

@media (max-width: 1399.98px) {
  .cus-none-block-xxl > .cus-block-item {
    display: none !important;
  }
  .cus-none-block-xxl > a.cus-block-item {
    display: none !important;
  }
}

.cus-none-block > .cus-block-item {
  display: none !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 7.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 7.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 7.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 7.5rem !important;
}

.m-9 {
  margin: 9rem !important;
}

.mt-9,
.my-9 {
  margin-top: 9rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 9rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 9rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 9rem !important;
}

.m-10 {
  margin: 10.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 10.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 10.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 10.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 7.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 7.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 7.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 7.5rem !important;
}

.p-9 {
  padding: 9rem !important;
}

.pt-9,
.py-9 {
  padding-top: 9rem !important;
}

.pr-9,
.px-9 {
  padding-right: 9rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 9rem !important;
}

.pl-9,
.px-9 {
  padding-left: 9rem !important;
}

.p-10 {
  padding: 10.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 10.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 10.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 10.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -6rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -6rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -6rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -6rem !important;
}

.m-n8 {
  margin: -7.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -7.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -7.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -7.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -7.5rem !important;
}

.m-n9 {
  margin: -9rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -9rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -9rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -9rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -9rem !important;
}

.m-n10 {
  margin: -10.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -10.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -10.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -10.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -10.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }
  .m-sm-8 {
    margin: 7.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 7.5rem !important;
  }
  .m-sm-9 {
    margin: 9rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important;
  }
  .m-sm-10 {
    margin: 10.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }
  .p-sm-8 {
    padding: 7.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 7.5rem !important;
  }
  .p-sm-9 {
    padding: 9rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important;
  }
  .p-sm-10 {
    padding: 10.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -6rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -6rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -6rem !important;
  }
  .m-sm-n8 {
    margin: -7.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n9 {
    margin: -9rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -9rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -9rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -9rem !important;
  }
  .m-sm-n10 {
    margin: -10.5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10.5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10.5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10.5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }
  .m-md-8 {
    margin: 7.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 7.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 7.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 7.5rem !important;
  }
  .m-md-9 {
    margin: 9rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important;
  }
  .m-md-10 {
    margin: 10.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }
  .p-md-8 {
    padding: 7.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 7.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 7.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 7.5rem !important;
  }
  .p-md-9 {
    padding: 9rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important;
  }
  .p-md-10 {
    padding: 10.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10.5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -6rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -6rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -6rem !important;
  }
  .m-md-n8 {
    margin: -7.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -7.5rem !important;
  }
  .m-md-n9 {
    margin: -9rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -9rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -9rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -9rem !important;
  }
  .m-md-n10 {
    margin: -10.5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10.5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10.5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10.5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }
  .m-lg-8 {
    margin: 7.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 7.5rem !important;
  }
  .m-lg-9 {
    margin: 9rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important;
  }
  .m-lg-10 {
    margin: 10.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }
  .p-lg-8 {
    padding: 7.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 7.5rem !important;
  }
  .p-lg-9 {
    padding: 9rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important;
  }
  .p-lg-10 {
    padding: 10.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -6rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -6rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -6rem !important;
  }
  .m-lg-n8 {
    margin: -7.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n9 {
    margin: -9rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -9rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -9rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -9rem !important;
  }
  .m-lg-n10 {
    margin: -10.5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10.5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10.5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10.5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }
  .m-xl-8 {
    margin: 7.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 7.5rem !important;
  }
  .m-xl-9 {
    margin: 9rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important;
  }
  .m-xl-10 {
    margin: 10.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }
  .p-xl-8 {
    padding: 7.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 7.5rem !important;
  }
  .p-xl-9 {
    padding: 9rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important;
  }
  .p-xl-10 {
    padding: 10.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -6rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -6rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -6rem !important;
  }
  .m-xl-n8 {
    margin: -7.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n9 {
    margin: -9rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -9rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -9rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -9rem !important;
  }
  .m-xl-n10 {
    margin: -10.5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10.5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10.5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10.5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4.5rem !important;
  }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xxl-7 {
    margin: 6rem !important;
  }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 6rem !important;
  }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 6rem !important;
  }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 6rem !important;
  }
  .m-xxl-8 {
    margin: 7.5rem !important;
  }
  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 7.5rem !important;
  }
  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 7.5rem !important;
  }
  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 7.5rem !important;
  }
  .m-xxl-9 {
    margin: 9rem !important;
  }
  .mt-xxl-9,
  .my-xxl-9 {
    margin-top: 9rem !important;
  }
  .mr-xxl-9,
  .mx-xxl-9 {
    margin-right: 9rem !important;
  }
  .mb-xxl-9,
  .my-xxl-9 {
    margin-bottom: 9rem !important;
  }
  .ml-xxl-9,
  .mx-xxl-9 {
    margin-left: 9rem !important;
  }
  .m-xxl-10 {
    margin: 10.5rem !important;
  }
  .mt-xxl-10,
  .my-xxl-10 {
    margin-top: 10.5rem !important;
  }
  .mr-xxl-10,
  .mx-xxl-10 {
    margin-right: 10.5rem !important;
  }
  .mb-xxl-10,
  .my-xxl-10 {
    margin-bottom: 10.5rem !important;
  }
  .ml-xxl-10,
  .mx-xxl-10 {
    margin-left: 10.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6rem !important;
  }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 6rem !important;
  }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 6rem !important;
  }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 6rem !important;
  }
  .p-xxl-8 {
    padding: 7.5rem !important;
  }
  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 7.5rem !important;
  }
  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 7.5rem !important;
  }
  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 7.5rem !important;
  }
  .p-xxl-9 {
    padding: 9rem !important;
  }
  .pt-xxl-9,
  .py-xxl-9 {
    padding-top: 9rem !important;
  }
  .pr-xxl-9,
  .px-xxl-9 {
    padding-right: 9rem !important;
  }
  .pb-xxl-9,
  .py-xxl-9 {
    padding-bottom: 9rem !important;
  }
  .pl-xxl-9,
  .px-xxl-9 {
    padding-left: 9rem !important;
  }
  .p-xxl-10 {
    padding: 10.5rem !important;
  }
  .pt-xxl-10,
  .py-xxl-10 {
    padding-top: 10.5rem !important;
  }
  .pr-xxl-10,
  .px-xxl-10 {
    padding-right: 10.5rem !important;
  }
  .pb-xxl-10,
  .py-xxl-10 {
    padding-bottom: 10.5rem !important;
  }
  .pl-xxl-10,
  .px-xxl-10 {
    padding-left: 10.5rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .m-xxl-n7 {
    margin: -6rem !important;
  }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -6rem !important;
  }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -6rem !important;
  }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -6rem !important;
  }
  .m-xxl-n8 {
    margin: -7.5rem !important;
  }
  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -7.5rem !important;
  }
  .m-xxl-n9 {
    margin: -9rem !important;
  }
  .mt-xxl-n9,
  .my-xxl-n9 {
    margin-top: -9rem !important;
  }
  .mr-xxl-n9,
  .mx-xxl-n9 {
    margin-right: -9rem !important;
  }
  .mb-xxl-n9,
  .my-xxl-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-xxl-n9,
  .mx-xxl-n9 {
    margin-left: -9rem !important;
  }
  .m-xxl-n10 {
    margin: -10.5rem !important;
  }
  .mt-xxl-n10,
  .my-xxl-n10 {
    margin-top: -10.5rem !important;
  }
  .mr-xxl-n10,
  .mx-xxl-n10 {
    margin-right: -10.5rem !important;
  }
  .mb-xxl-n10,
  .my-xxl-n10 {
    margin-bottom: -10.5rem !important;
  }
  .ml-xxl-n10,
  .mx-xxl-n10 {
    margin-left: -10.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #3dad02 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #226101 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #3dad02 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #226101 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-gray-100 {
  color: #f8f9fa !important;
}

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #cbd3da !important;
}

.text-gray-200 {
  color: #e9ecef !important;
}

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bdc6cf !important;
}

.text-gray-300 {
  color: #dee2e6 !important;
}

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #b2bcc5 !important;
}

.text-gray-400 {
  color: #ced4da !important;
}

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #a2aeb9 !important;
}

.text-gray-500 {
  color: #adb5bd !important;
}

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #838f9b !important;
}

.text-gray-600 {
  color: #6c757d !important;
}

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #494f54 !important;
}

.text-gray-700 {
  color: #495057 !important;
}

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #262a2d !important;
}

.text-gray-800 {
  color: #343a40 !important;
}

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #121416 !important;
}

.text-gray-900 {
  color: #212529 !important;
}

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.font-size-xs {
  font-size: 0.75rem !important;
}

.font-size-sm {
  font-size: 0.875rem !important;
}

.font-size-base {
  font-size: 1rem !important;
}

.font-size-lg {
  font-size: 1.125rem !important;
}

.font-size-xl {
  font-size: 1.25rem !important;
}

.font-size-xxl {
  font-size: 1.5rem !important;
}

.font-size-xxxl {
  font-size: 2rem !important;
}

.font-size-xxxxl {
  font-size: 3rem !important;
}

.font-size-xxxxxl {
  font-size: 5rem !important;
}

.font-size-xxxxxxl {
  font-size: 7.5rem !important;
}

@media (min-width: 576px) {
  .font-size-sm-xs {
    font-size: 0.75rem !important;
  }
  .font-size-sm-sm {
    font-size: 0.875rem !important;
  }
  .font-size-sm-base {
    font-size: 1rem !important;
  }
  .font-size-sm-lg {
    font-size: 1.125rem !important;
  }
  .font-size-sm-xl {
    font-size: 1.25rem !important;
  }
  .font-size-sm-xxl {
    font-size: 1.5rem !important;
  }
  .font-size-sm-xxxl {
    font-size: 2rem !important;
  }
  .font-size-sm-xxxxl {
    font-size: 3rem !important;
  }
  .font-size-sm-xxxxxl {
    font-size: 5rem !important;
  }
  .font-size-sm-xxxxxxl {
    font-size: 7.5rem !important;
  }
}

@media (min-width: 768px) {
  .font-size-md-xs {
    font-size: 0.75rem !important;
  }
  .font-size-md-sm {
    font-size: 0.875rem !important;
  }
  .font-size-md-base {
    font-size: 1rem !important;
  }
  .font-size-md-lg {
    font-size: 1.125rem !important;
  }
  .font-size-md-xl {
    font-size: 1.25rem !important;
  }
  .font-size-md-xxl {
    font-size: 1.5rem !important;
  }
  .font-size-md-xxxl {
    font-size: 2rem !important;
  }
  .font-size-md-xxxxl {
    font-size: 3rem !important;
  }
  .font-size-md-xxxxxl {
    font-size: 5rem !important;
  }
  .font-size-md-xxxxxxl {
    font-size: 7.5rem !important;
  }
}

@media (min-width: 992px) {
  .font-size-lg-xs {
    font-size: 0.75rem !important;
  }
  .font-size-lg-sm {
    font-size: 0.875rem !important;
  }
  .font-size-lg-base {
    font-size: 1rem !important;
  }
  .font-size-lg-lg {
    font-size: 1.125rem !important;
  }
  .font-size-lg-xl {
    font-size: 1.25rem !important;
  }
  .font-size-lg-xxl {
    font-size: 1.5rem !important;
  }
  .font-size-lg-xxxl {
    font-size: 2rem !important;
  }
  .font-size-lg-xxxxl {
    font-size: 3rem !important;
  }
  .font-size-lg-xxxxxl {
    font-size: 5rem !important;
  }
  .font-size-lg-xxxxxxl {
    font-size: 7.5rem !important;
  }
}

@media (min-width: 1200px) {
  .font-size-xl-xs {
    font-size: 0.75rem !important;
  }
  .font-size-xl-sm {
    font-size: 0.875rem !important;
  }
  .font-size-xl-base {
    font-size: 1rem !important;
  }
  .font-size-xl-lg {
    font-size: 1.125rem !important;
  }
  .font-size-xl-xl {
    font-size: 1.25rem !important;
  }
  .font-size-xl-xxl {
    font-size: 1.5rem !important;
  }
  .font-size-xl-xxxl {
    font-size: 2rem !important;
  }
  .font-size-xl-xxxxl {
    font-size: 3rem !important;
  }
  .font-size-xl-xxxxxl {
    font-size: 5rem !important;
  }
  .font-size-xl-xxxxxxl {
    font-size: 7.5rem !important;
  }
}

@media (min-width: 1400px) {
  .font-size-xxl-xs {
    font-size: 0.75rem !important;
  }
  .font-size-xxl-sm {
    font-size: 0.875rem !important;
  }
  .font-size-xxl-base {
    font-size: 1rem !important;
  }
  .font-size-xxl-lg {
    font-size: 1.125rem !important;
  }
  .font-size-xxl-xl {
    font-size: 1.25rem !important;
  }
  .font-size-xxl-xxl {
    font-size: 1.5rem !important;
  }
  .font-size-xxl-xxxl {
    font-size: 2rem !important;
  }
  .font-size-xxl-xxxxl {
    font-size: 3rem !important;
  }
  .font-size-xxl-xxxxxl {
    font-size: 5rem !important;
  }
  .font-size-xxl-xxxxxxl {
    font-size: 7.5rem !important;
  }
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*# sourceMappingURL=bootstrap.css.map */