// stylelint-disable declaration-no-important

// Common values
//@each $position in $positions {
//  .position-#{$position} { position: $position !important; }
//}


// Shorthand

//.fixed-top {
//  position: fixed;
//  top: 0;
//  right: 0;
//  left: 0;
//  z-index: $zindex-fixed;
//}
//
//.fixed-bottom {
//  position: fixed;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  z-index: $zindex-fixed;
//}
//
//.sticky-top {
//  @supports (position: sticky) {
//    position: sticky;
//    top: 0;
//    z-index: $zindex-sticky;
//  }
//}



@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Common values
    @each $position in $positions {
      .position#{$infix}-#{$position} { position: $position !important; }
    }
    // Shorthand

    .fixed#{$infix}-top {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: $zindex-fixed;
    }

    .fixed#{$infix}-bottom {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zindex-fixed;
    }

    .sticky#{$infix}-top {
      @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
      }
    }
  }
}
