// stylelint-disable declaration-no-important

// Width and height

//@each $prop, $abbrev in (width: w, height: h) {
//  @each $size, $length in $sizes {
//    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
//  }
//}
//
//.mw-100 { max-width: 100% !important; }
//.mh-100 { max-height: 100% !important; }
//
//// Viewport additional helpers
//
//.min-vw-100 { min-width: 100vw !important; }
//.min-vh-100 { min-height: 100vh !important; }
//
//.vw-100 { width: 100vw !important; }
//.vh-100 { height: 100vh !important; }




@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        @if "#{$size}" != "auto" {
          .#{$abbrev}#{$infix}-#{$size} { #{$prop}: #{$length}% !important; }
          .m#{$abbrev}#{$infix}-#{$size} { max-#{$prop}: #{$length}%  !important; }
          .min-#{$abbrev}#{$infix}-#{$size} { min-#{$prop}: #{$length}%  !important; }
          .max-v#{$abbrev}#{$infix}-#{$size} { max-#{$prop}: #{$length}vw !important; }
          .min-v#{$abbrev}#{$infix}-#{$size} { min-#{$prop}: #{$length}vw !important; }
          .v#{$abbrev}#{$infix}-#{$size} { #{$prop}: #{$length}vw !important; }
        } @else {
          .#{$abbrev}#{$infix}-#{$size} { #{$prop}: #{$length} !important; }
          .m#{$abbrev}#{$infix}-#{$size} { max-#{$prop}: none  !important; }
          .min-#{$abbrev}#{$infix}-#{$size} { min-#{$prop}: #{$length}  !important; }
          .max-v#{$abbrev}#{$infix}-#{$size} { max-#{$prop}: none !important; }
          .min-v#{$abbrev}#{$infix}-#{$size} { min-#{$prop}: #{$length} !important; }
          .v#{$abbrev}#{$infix}-#{$size} { #{$prop}: #{$length} !important; }
        }
      }
    }

    @each $fit in $img-object-fits {
      .img-of#{$infix}-#{$fit} { object-fit: $fit !important; }
    }

    @each $position, $val in $img-object-positions {
      .img-op#{$infix}-#{$position} { object-position: $val !important; }
    }

    .cus-sku#{$infix}-rt{
      top: 0.8rem !important;
      right: 0.8rem !important;
      bottom: unset !important;
      left: unset !important;
    }
    .cus-sku#{$infix}-lb{
      top: unset !important;
      right: unset !important;
      bottom: 0.8rem !important;
      left: 0.8rem !important;
    }

  }
}

.cus-show-block {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);
    @if "#{$infix}" == "" {
      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          & > .cus-block-item{
            display: block !important;
          }
        }
      }
    } @else {
      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          & > .cus-block-item{
            display: block !important;
          }
          & > a.cus-block-item{
            display: block !important;
          }
        }
      }
    }
  }
}
.cus-none-block {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    @if "#{$infix}" == "" {
      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          & > .cus-block-item{
            display: none !important;
          }
        }
      }
    } @else {
      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          & > .cus-block-item{
            display: none !important;
          }
          & > a.cus-block-item{
            display: none !important;
          }
        }
      }
    }
  }
}

